import React from "react";
import SettingsEdit from "../superAdminComponents/settings/SettingsEdit";
import SettingsTabbar from "../superAdminComponents/settings/SettingsTabbar";
import PageHeader from "../superAdminComponents/genericComponents/pageHeader";

const TitleComponent = () => (
  <div className="mt-2 mb-8 flex">
    <div>Settings</div>
  </div>
);

const SuperAdminSettings = () => {
  return (
    <>
      <PageHeader title={<TitleComponent />} /> 
      <div className="flex-none sm:flex gap-4">
        <SettingsEdit />
        <SettingsTabbar />
      </div>
    </>
  );
};

export default SuperAdminSettings;
