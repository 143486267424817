import React from "react";
import {Navigate, useLocation, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import {roles} from "../utils/constants/enums";

const ProtectedRoute = ({children, allowedRoles}) => {
  const {user, subscriptionStatus} = useSelector(store => store.user);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{from: location}} replace />;
  }

  if (allowedRoles && !allowedRoles.includes(user?.roles[0]?.name)) {
    return <Navigate to="/login" replace />;
  }

  if (user?.roles[0]?.name === roles.admin) {
    const hasActiveSubscription = subscriptionStatus.hasActiveSubscription;
    const currentPath = location.pathname;

    const subscriptionRoutes = [
      "/subscriptions",
      "/subscriptions/plans",
      "/subscriptions/upgradePlans",
      "/subscriptions/buySubscription",
    ];

    const isSubscriptionRoute = subscriptionRoutes.some(route =>
      currentPath.includes(route)
    );

    if (!hasActiveSubscription) {
      if (!isSubscriptionRoute) {
        return <Navigate to="/subscriptions/plans" replace />;
      }

      return children || <Outlet />;
    }
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
