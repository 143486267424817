import React, { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkFeature,
  createFeature,
  editFeature,
} from "../../superAdminFeatures/settings/featuresSlice";
import { toast } from "react-toastify";
import PageHeader from "../genericComponents/pageHeader";
import { FormField } from "../../superAdminComponents/genericComponents/FormField";
import Button from "../genericComponents/Button";
import { HELPER_MODE_LABELS, SUPERADMIN } from "../../utils/constants/keywords";

const TitleComponent = ({ onBack, mode }) => (
  <div className="mt-2 mb-8 flex">
    <ChevronLeftIcon className="size-5 m-0.5 cursor-pointer" onClick={onBack} />
    <div className="ml-1">
      {mode === HELPER_MODE_LABELS.CREATE_MODE ? "Create New" : "Edit"} Feature
    </div>
  </div>
);

const CreatenewFeature = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { mode, allFeatures } = location.state || {};
  const { isFeatureNamePresent, featureNameExistingMessage } = useSelector(
    (state) => state.features
  );

  const [selectedType, setSelectedType] = useState("");

  const schema = yup.object({
    name: yup.string().required("Feature name is required"),
    type: yup.string().required("Feature type is required"),
    ...(selectedType !== "ALL_TIME" && {
      featureLimit: yup
        .number()
        .typeError("Limit must be a number")
        .required("Limit is required")
        .positive("Allow only positive value"),
    }),
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { name: "", type: "", featureLimit: "" },
  });

  const typeValue = watch("type");

  useEffect(() => {
    setSelectedType(typeValue);
  }, [typeValue]);

  useEffect(() => {
    if (allFeatures) {
      reset({
        name: allFeatures.name || "",
        type: allFeatures.type || "",
        featureLimit: allFeatures.featureLimit || "",
      });
      setSelectedType(allFeatures.type || "");
    }
  }, [allFeatures, reset]);

  useEffect(() => {
    if (isFeatureNamePresent) {
      setError(SUPERADMIN.SET_ERROR.NAME, {
        message: featureNameExistingMessage,
      });
    } else {
      clearErrors(SUPERADMIN.SET_ERROR.NAME);
    }
  }, [isFeatureNamePresent, featureNameExistingMessage, setError, clearErrors]);

  const handleInputChange = async (e) => {
    await dispatch(checkFeature({ featureName: e.target.value }));
  };

  const onSubmit = async (data) => {
    if (isFeatureNamePresent) {
      setError(SUPERADMIN.SET_ERROR.NAME, {
        message: featureNameExistingMessage,
      });
      return;
    }
    const featureData = { ...data, isActive: true };
    try {
      if (mode === HELPER_MODE_LABELS.CREATE_MODE) {
        await dispatch(createFeature(featureData));
      }
      if (mode === HELPER_MODE_LABELS.EDIT_MODE) {
        await dispatch(editFeature({ ...featureData, id: allFeatures.id }));
        toast.success("Feature updated successfully!");
      }
      navigate(-1);
    } catch {
      toast.error("An unexpected error occurred.");
    }
  };

  const options = [
    { value: "ALL_TIME", label: "All Time" },
    { value: "LIMITED", label: "Limited" },
  ];

  return (
    <>
      <PageHeader
        title={<TitleComponent onBack={() => navigate(-1)} mode={mode} />}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-zinc-900 shadow-sm ring-1 ring-gray-900/5 sm:rounded-md"
      >
        <div className="py-6 px-6">
          <div className="text-white text-xl mb-5">
            {mode === HELPER_MODE_LABELS.CREATE_MODE
              ? SUPERADMIN.FEATURE_TYPE.NEW
              : SUPERADMIN.FEATURE_TYPE.EDIT}{" "}
            Feature
          </div>
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-4 lg:grid-cols-6">
            <FormField
              label="Feature Name"
              field={{
                name: "name",
                type: "text",
                required: true,
                autoComplete: "off",
              }}
              register={register}
              error={errors.name}
              onInput={handleInputChange}
              mode={mode}
            />
            <FormField
              label="Feature Type"
              field={{
                name: "type",
                required: true,
                type: "select",
                options,
              }}
              register={register}
              error={errors.type}
            />
            {selectedType !== "ALL_TIME" && (
              <FormField
                label="Limit"
                field={{
                  name: "featureLimit",
                  type: "text",
                  required: true,
                  autoComplete: "off",
                }}
                register={register}
                error={errors.featureLimit}
              />
            )}
          </div>
          <hr className="border-gray-800 my-5" />
          <div className="flex justify-end">
            <Button
              buttonText="Cancel"
              onClick={() => navigate(-1)}
              className="rounded-md w-full sm:w-40 h-10 text-sm text-white mr-3 hover:bg-blue-700"
            />
            <Button
              buttonText="Submit"
              type="submit"
              disabled={isSubmitting}
              className="rounded-md w-full sm:w-40 h-10 bg-blue-700 text-sm text-white"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default CreatenewFeature;
