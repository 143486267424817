/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { useEffect, useState } from "react";
import DashboardDurationDropdown from "./DashboardDurationDropdown";
import DashboardStatsChart from "./DashboardStatsChart";
import {
  getPaymentMethodDataByDays,
  getPaymentMethodDataByMonths,
  getPaymentMethodDataByYears,
} from "../../features/dashboard/dashboardChartSlice";
import { useDispatch, useSelector } from "react-redux";
import { PERIOD_FILTERS, PLAN_DETAILS } from "../../utils/constants/keywords";

export default function DashboardStatsChartContainer({
  dateRange,
}) {
  const [chartData, setChartData] = useState([]);
  const [valueSelected, setValueSelected] = useState("seven_days");
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);

  const dateFormatApi = (value) => {
    const data = new Date(value);
    const date = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = data.getFullYear();
    return `${year}-${month}-${date}`;
  };

  const TotalCollectedAmount =  (chartData && chartData.length > 0) ? chartData.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.total_collection;
  }, 0):0;
  const TotalSalesAmount = (chartData && chartData.length > 0) ? chartData.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.total_sales;
  }, 0):0;
  const TotalDuesAmount = (chartData && chartData.length > 0) ? chartData.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.total_dues;
  }, 0):0;

  const adminId = user?.userId;
  const plans = [
    {
      id: PLAN_DETAILS.TOTAL_SALES.id,
      name: PLAN_DETAILS.TOTAL_SALES.name,
      description: TotalSalesAmount?.toFixed(2) || 0,
      color: PLAN_DETAILS.TOTAL_SALES.color,
    },
    {
      id: PLAN_DETAILS.TOTAL_COLLECTION.id,
      name: PLAN_DETAILS.TOTAL_COLLECTION.name,
      description: TotalCollectedAmount?.toFixed(2) || 0,
      color: PLAN_DETAILS.TOTAL_COLLECTION.color,
    },
    {
      id: PLAN_DETAILS.TOTAL_DUES.id,
      name: PLAN_DETAILS.TOTAL_DUES.name,
      description: TotalDuesAmount?.toFixed(2) || 0,
      color: PLAN_DETAILS.TOTAL_DUES.color,
    }
  ];
  const [selectedPlans, setSelectedPlans] = useState(
    plans.map((plan) => plan.id)
  );

  // Ensure at least one checkbox is checked by default
  useEffect(() => {
    if (selectedPlans.length === 0) {
      setSelectedPlans(plans.map((plan) => plan.id));
    }
    handleChange({ target: { value: PERIOD_FILTERS.SEVEN_DAYS } });
  }, []);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedPlans((prevSelectedPlans) => [...prevSelectedPlans, value]);
    } else {
      if (selectedPlans.length > 1) {
        setSelectedPlans((prevSelectedPlans) =>
          prevSelectedPlans.filter((plan) => plan !== value)
        );
      }
    }
  };

  // Filter data based on selected plans
  const filteredData =
    chartData &&
    chartData?.map((item) => {
      const filteredItem = {};
      Object.keys(item).forEach((key) => {
        if (selectedPlans.includes(key)) {
          filteredItem[key] = item[key];
        }
      });
      return filteredItem;
    });

  const handleChange = async (e) => {
    setValueSelected(e.target.value);
    const actionCreators = {
      seven_days: getPaymentMethodDataByDays,
      monthly: getPaymentMethodDataByMonths,
      yearly: getPaymentMethodDataByYears,
    };

    const selectedValue = e.target.value;
    const actionCreator = actionCreators[selectedValue];

    // Calculate fromDate and toDate based on the selected value
    let fromDate, toDate;
    const currentDate = new Date();

    if (selectedValue === PERIOD_FILTERS.SEVEN_DAYS) {
      fromDate = new Date(currentDate.setDate(currentDate.getDate() - 6));
      toDate = new Date();
    } else if (selectedValue === PERIOD_FILTERS.MONTHLY) {
      // Set to the 1st day of the month 12 months before the current date
      fromDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 11,
        1
      );
      toDate = currentDate;
    } else if (selectedValue === PERIOD_FILTERS.YEARLY) {
      // Set to the 1st month of the year 10 years before the current date
      fromDate = new Date(currentDate.getFullYear() - 9, 0, 1);
      toDate = currentDate;
    }

    if (actionCreator) {
      const { payload } = await dispatch(
        actionCreator({
          adminId,
          fromDate: dateFormatApi(fromDate),
          toDate: dateFormatApi(toDate),
        })
      );
      setChartData(payload?.data);
    }
  };

  return (
    <div className="flex flex-col p-6 m-5 lg:mb-0 rounded-lg shadow space-y-4  ">
      <div className="flex mr-4 justify-between items-center">
        <h4 className="text-xl font-base">Total Collection</h4>
        <div className="sm:my-0">
          <DashboardDurationDropdown
            handleChange={handleChange}
            periodRange={dateRange}
          />
        </div>
      </div>
      <div className="sm:flex-row sm:justify-between pl-2">
        {/* <legend className="sr-only">Plan</legend> */}
        <div className="flex items-center justify-between space-x-4">
          <div className="flex flex-col sm:flex-row sm:space-x-2">
            {plans.map((plan) => (
              <div key={plan.id} className="relative flex items-start">
                <div className="h-6 items-center">
                  <input
                    id={plan.id}
                    aria-describedby={`${plan.id}-description`}
                    name="plan"
                    type="checkbox"
                    value={plan.id}
                    checked={selectedPlans.includes(plan.id)}
                    onChange={handleCheckboxChange}
                    className={`h-4 w-4 mt-1 rounded-full appearance-none border-indigo-600 text-indigo-600 focus:ring-transparent`}
                  />
                </div>
                <div className="ml-2 mt-1 text-sm leading-6">
                  <label
                    htmlFor={plan.id}
                    className="font-medium text-gray-900"
                  >
                    {plan.name}
                  </label>
                  <span
                    id={`${plan.id}-description`}
                    className="ml-1 text-gray-500"
                  >
                    ₹{plan.description || 0}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        <DashboardStatsChart
          plans={plans}
          selectedPlans={selectedPlans}
          renderData={chartData}
          valueSelected={valueSelected}
        />
      </div>
    </div>
  );
}

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
