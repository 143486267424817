import * as yup from "yup";

const MAX_FILE_SIZE = 1024 * 1024 * 5;
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg",
  // "image/webp",
];

export const registrationFormSchema = yup.object({
  username: yup
    .string()
    .required("Required field")
    .matches(/^[a-zA-Z _,.0-9]+$/, "Only alphabetic and numbers are allowed"),
  email: yup
    .string()
    .email('Please enter valid email')
    .required("Required field")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter valid email"),
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter valid email"
    ),
  mobileNo: yup
    .string()
    .required("Mobile No. is required")
    .max(10, "Max 10 digits")
    .matches(/^\d{10}$/, "Must be 10 digits only"),
  password: yup
    .string()
    .required("Password required")
    .matches(
      /^(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*\d)[A-Za-z\d@$!%*?&]{8,12}$/,
      "Must be of 8 to 12 characters with one special character,one capital letter & a number"
    ),
  clubAddress: yup
    .string()
    .required("Club Address is required")
    .min(15, `Min 15 characters`)
    .max(255, "Club address must be at most 255 characters"),
  clubName: yup
    .string()
    .required("Required field")
    .matches(
      /^[a-zA-Z0-9\s\W]+$/,
      "letters, numbers, spaces, and special characters allowed"
    )
    .max(30, "Club Name must be at most 30 characters"),
  clubMobileNo: yup
    .string()
    .required("Required field")
    .max(10, "Max 10 digits")
    .matches(/^\d{10}$/, "Must be 10 digits only"),
  clubPincode: yup
    .string()
    .required("Required field")
    .max(6, "Max 6 digits")
    .matches(/^\d{6}$/, "Must be 6 digits"),
  clubMailId: yup
    .string()
    .email('Please enter valid email')
    .required("Required field")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter valid email"),
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter valid email"
    ),
  clubWhatsAppNo: yup
    .string()
    .required("Required field")
    .max(10, "Max 10 digits")
    .matches(/^\d{10}$/, "Must be 10 digits only"),
  clubWhatsAppNoAdd: yup
    .string()
    .nullable()
    .test(
      'is-number',
      'Must be 10 digits only',
      (value) => value === '' || value === null || /^\d{10}$/.test(value)
    ),
  terms: yup.bool().oneOf([true], "Please accept terms and conditions"),
  file: yup
    .mixed()
    .required("Club logo required")
    .test({
      message: "Please provide supported file type (jpg, png, svg)",
      test: (file, context) => {
        const isValid = ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type);
        if (!isValid) context?.createError();
        return isValid;
      },
    })
    .test({
      message: `File too big, can't exceed ${MAX_FILE_SIZE}`,
      test: (file) => {
        const isValid = file?.[0]?.size <= MAX_FILE_SIZE;
        return isValid;
      },
    }),
});
