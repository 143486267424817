import React from 'react';
import { useSelector } from 'react-redux';

import Logo from '../../../assets/images/logo.png';
import AttendanceReportCalender from './AttendanceReportCalender';
import { getFormattedDate } from '../../../utils/formatter/dateFormatter';
import { dateFormat } from '../../../utils/constants/enums';

const AttendanceReport = ({ daysInMonth, currMonth, stats }) => {
    const { memberDetails } = useSelector((store) => store.memberDetails);

    return (
        <div className="bg-transparent sm:rounded-md sm:border sm:border-gray-300">
            <div className="flex items-center p-6 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-3">
                <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                    <div className='flex items-center'>
                        <img src={Logo} className='h-10 w-20' alt="Logo" />
                    </div>
                </dl>
            </div>
            <div className="relative isolate">
                <div className="-mx-2 px-10 sm:mx-0 sm:px-8 sm:pb-1 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-3 xl:pb-4 xl:pt-1">
                    <div className="flex items-center justify-center text-lg font-semibold underline">
                        Monthly Attendance Report - {currMonth.format(dateFormat.monthYearFormat)}
                    </div>
                    <dl className="mt-6 grid grid-cols-1 text-sm leading-1 sm:grid-cols-1">
                        <dd className="">
                            <span className="font-sm text-sm text-gray-900">Member Name: {memberDetails?.mobileNo}</span>
                        </dd>
                        <dd className="">
                            <span className="font-sm text-sm text-gray-900">Date Of Joining: {getFormattedDate(memberDetails?.createdAt)}</span>
                        </dd>
                        <dd className="">
                            <span className="font-sm text-sm text-gray-900">Mobile Number: +91 {memberDetails?.username}</span>
                        </dd>
                    </dl>
                    <div>
                        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                            {stats.map((item) => (
                                <div key={item.name} className="overflow-hidden flex bg-white px-0 py-5 sm:px-10 sm:py-4 items-center sm:justify-center">
                                    <dt className="truncate text-sm font-sm text-gray-500">{item.name}</dt>
                                    <dd className=" ml-4 text-lg font-semibold tracking-tight text-gray-900">{item.noOfDays}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                    <AttendanceReportCalender daysInMonth={daysInMonth} currMonth={currMonth} />
                </div>
            </div>
        </div>
    );
};

export default AttendanceReport;