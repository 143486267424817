import React from 'react';

export default function FieldSet({ register, error, allFeatures, selectedFeatures = [] }) {

    return (
        <fieldset>
            <legend className=" text-white font-normal text-xl">Features</legend>
            <div className="mt-6 space-y-3">
                {allFeatures?.map((feature) => (
                    <div key={feature.id} className="flex ml-3 items-center">
                        <input
                            {...register('features')}
                            id={feature.id}
                            value={feature.id}
                            type="checkbox"
                            className="h-4 w-4 border-zinc-500 rounded-md bg-zinc-900 text-indigo-600 focus:ring-indigo-600"
                            defaultChecked={selectedFeatures?.includes(feature.id)}
                        />
                        <label htmlFor={feature.id} className="ml-2 block text-sm font-normal text-white">
                            {feature.name}
                        </label>
                    </div>
                ))}
            </div>
            {error && (
                <p className="mt-2 text-sm text-red-500">
                    {error.message}
                </p>
            )}
        </fieldset>
    )
}
