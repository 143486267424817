import { imageConstants } from "../../utils/constants/enums"
import { TEMP_PASSWORD } from "../../utils/constants/keywords"

export const defaultStaffFormValues = (adminId) => {
  const values = {
    adminId: adminId,
    mobileNo: '',
    username: '',
    password: TEMP_PASSWORD,
    email: '',
    enabled: true,
    accountNonExpired: true,
    credentialsNonExpired: true,
    accountNonLocked: true,
    userStatus: true,
    gender: '',
    approvalStatus: "PENDING_APPROVAL",
    isClub: false,
    roles: [
      {
        name: '',
      },
    ],
    address: '',
    fromDate: null,
    toDate: null,
    salary: '',
    employeeStatus: true,
    dateOfJoining: null,
    ifsc: '',
    pincode: '',
    designation: '',
    ctc: '',
    branchName: '',
    accountNo: '',
    accountType: '',
    privileges: '',
    bankName: '',
    dateOfBirth: null,
    club: '',
    imageFile: null,
    uploadDocumentFile: null,
    imageFileInBlob: null,
  }

  return values
}

export const resetStaffFormValues = (staffData, imgSrc) => {
  const values = {
    id: staffData?.id,
    adminId: staffData?.adminId,
    mobileNo: staffData?.mobileNo,
    username: staffData?.username,
    password: staffData?.password,
    email: staffData?.email,
    enabled: staffData?.enabled,
    accountNonExpired: staffData?.accountNonExpired,
    credentialsNonExpired: staffData?.credentialsNonExpired,
    accountNonLocked: staffData?.accountNonLocked,
    userStatus: staffData?.userStatus,
    gender: staffData?.gender,
    approvalStatus: staffData?.approvalStatus,
    isClub: staffData?.isClub,
    roles: [
      {
        name: '',
      },
    ],
    address: staffData?.employees?.address,
    fromDate: staffData?.employees?.fromDate,
    toDate: staffData?.employees?.toDate,
    salary: staffData?.employees?.salary,
    employeeStatus: staffData?.employees?.employeeStatus,
    dateOfJoining: staffData?.employees?.dateOfJoining,
    ifsc: staffData?.employees?.ifsc,
    pincode: staffData?.employees?.pincode,
    designation: staffData?.employees?.designation,
    ctc: staffData?.employees?.ctc,
    branchName: staffData?.employees?.branchName,
    accountNo: staffData?.employees?.accountNo,
    accountType: staffData?.employees?.accountType,
    privileges: staffData?.employees?.privileges,
    bankName: staffData?.employees?.bankName,
    dateOfBirth: staffData?.employees?.dateOfBirth,
    club: staffData?.employees?.club,
    imageFile: imgSrc ? base64ToFile(imgSrc) : null,
    uploadDocumentFile: null,
    imageFileInBlob: imgSrc || null,
  }

  return values
}

export const handleWebcamCapture = (
  webcamRef,
  setCapturedImage,
  setWebcamOpen,
  setValue,
  trigger,
  setIsImageUploaded
) => {
  const imageSrc = webcamRef.current.getScreenshot()
  setCapturedImage(imageSrc)
  setWebcamOpen(false)

  const base64String = imageSrc.split(',')[1]
  const binaryString = atob(base64String)
  const arrayBuffer = new ArrayBuffer(binaryString.length)
  const uint8Array = new Uint8Array(arrayBuffer)

  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i)
  }

  const blob = new Blob([arrayBuffer], { type: imageConstants.fileType.jpeg })
  const file = new File([blob], imageConstants.fileNames.webcamCapture, { type: imageConstants.fileType.jpeg })
  setValue(imageConstants.formFields.imageFile, file, { shouldDirty: true })
  setIsImageUploaded(true)
  trigger(imageConstants.formFields.imageFile)
}

export const handleImageFileChange = (event, setCapturedImage, setValue, trigger, setIsImageUploaded) => {
  const selectedFile1 = event.target.files[0]
  setValue(imageConstants.formFields.imageFile, selectedFile1, { shouldDirty: true })
  trigger(imageConstants.formFields.imageFile)

  const file = event.target.files[0]
  if (file) {
    const reader = new FileReader()

    reader.onload = (e) => {
      const base64String = e.target.result
      setCapturedImage(base64String)
      setIsImageUploaded(true)
    }

    reader.readAsDataURL(file)
    event.target.value = null;
  }
}

export const handleDocumentUpload = (e, setValue, setSelectedDocument) => {
  const selectedFile = e.target.files[0]
  setValue('uploadDocumentFile', selectedFile)
  setSelectedDocument(selectedFile)
}

export function getFormattedStaffData(data) {
  function formatData(data) {
    const formattedData = {}
    const formattedImageFile = {}
    const formattedUploadDocumentFile = {}

    const employees = {
      address: data.address,
      fromDate: data.fromDate,
      toDate: data.toDate,
      salary: data.salary,
      employeeStatus: data.employeeStatus,
      dateOfJoining: data.dateOfJoining,
      ifsc: data.ifsc,
      pincode: data.pincode,
      designation: data.designation,
      ctc: data.ctc,
      branchName: data.branchName,
      accountNo: data.accountNo,
      accountType: data.accountType,
      privileges: data.privileges,
      bankName: data.bankName,
      dateOfBirth: data.dateOfBirth,
      club: data.club,
    }

    switch (data.designation) {
      case 'Trainer':
        data.roles = [{ name: 'ROLE_TRAINER' }]
        break
      case 'Manager':
      case 'Front Desk':
      case 'Dietician':
      case 'Physician':
      case 'House Keeper':
      case 'Security Guard':
      case 'Other':
        data.roles = [{ name: 'ROLE_STAFF' }]
        break
      case 'Admin':
        data.roles = [{ name: 'ROLE_ADMIN' }]
        break
      default:
        break
    }

    for (const key in data) {
      if (
        !employees.hasOwnProperty(key) &&
        key !== 'imageFile' &&
        key !== 'uploadDocumentFile'
      ) {
        formattedData[key] = data[key]
      }
    }
    formattedData.employees = employees

    if (data.imageFile !== null) {
      const formData = new FormData()
      formData.append('file', data.imageFile)
      formattedImageFile.imageFile = formData
    } else {
      formattedImageFile.imageFile = data.imageFile
    }

    if (data.uploadDocumentFile !== null) {
      const formData = new FormData()
      formData.append('file', data.uploadDocumentFile)
      formattedUploadDocumentFile.uploadDocumentFile = formData
    } else {
      formattedUploadDocumentFile.uploadDocumentFile = data.uploadDocumentFile
    }

    return { formattedData, formattedImageFile, formattedUploadDocumentFile }
  }

  return formatData(data)
}

function base64ToFile(
  base64String,
  fileName = 'image.jpg',
  type = 'image/jpeg'
) {
  const isValidBase64 = /^data:image\/([a-zA-Z]*);base64,([^"]*)$/.test(
    base64String
  )
  if (!isValidBase64) {
    return null
  }
  const base64Parts = base64String.split(',')
  const base64Payload = base64Parts[1]

  try {
    const binaryString = atob(base64Payload)
    const arrayBuffer = new ArrayBuffer(binaryString.length)
    const uint8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([arrayBuffer], { type })
    const file = new File([blob], fileName, { type })

    return file
  } catch (error) {
    return null
  }
}
