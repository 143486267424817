import React, { useEffect, useState, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Menu, Transition } from '@headlessui/react'
import {
    ArrowPathIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ArrowDownTrayIcon,
    EllipsisVerticalIcon
} from "@heroicons/react/20/solid";

import { setFollowupDate } from "../../../features/dashboard/dashboardSlice";
import AttendanceReport from "./AttendanceReport";
import { getMemberAttendanceData, sendAttendanceReportViaWhatsapp } from "../../../features/people/attendanceSlice";
import { abbreviatedWeekdays, dateFormat } from "../../../utils/constants/enums";
import { DURATION_FILTER } from "../../../utils/constants/keywords";
import PDFContent, { generateAttendancePDF } from "./GenerateAttendancePdf";
import { ERROR_MESSAGES } from "../../../utils/constants/Prompts";
import { toast } from "react-toastify";
import GenericMenu from "../shared/GenericMenu";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const COLORS = {
    WHITE: "bg-white",
    GREEN: "bg-green-200",
    RED: "bg-red-200",
    GRAY: "bg-gray-200"
};

const DAYS_PRESENT = "Days Present";
const DAYS_ABSENT = "Days Absent";
const DAYS_NOT_AVAILABLE = "Not Available";
const DOWNLOAD = "Download";
const VIEW_REPORT = "View Report";
const SEND_REPORT = "Sent Report";
const SEND_VIA_WHATSAPP = "Send via Whatsapp";
const SEND_VIA_MAIL = "Send via Email"
const FILE_TYPE = "application/pdf"
const ATTENDANCE_REPORT_PDF = "Attendance_Report.pdf"


const getDateRange = (currMonth) => {
    const startDate = currMonth.clone().startOf(DURATION_FILTER.MONTH).format(dateFormat.ISO);
    const endDate = currMonth.isSame(moment(), DURATION_FILTER.MONTH)
        ? moment().format(dateFormat.ISO)
        : currMonth.clone().endOf(DURATION_FILTER.MONTH).format(dateFormat.ISO);

    return { startDate, endDate };
};

const CalendarHeader = ({ currMonth, setCurrMonth }) => {
    const isCurrentMonthOrFuture = currMonth.isSameOrAfter(moment(), DURATION_FILTER.MONTH);

    return (
        <div className="col-start-1 flex items-center">
            <h2 className="flex-auto text-sm font-semibold text-gray-900">
                {currMonth.format(dateFormat.monthYearFormat)}
            </h2>
            <button
                type="button"
                className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={() => setCurrMonth(moment())}
            >
                <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
                type="button"
                className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={() => setCurrMonth(prevMonth => prevMonth.clone().subtract(1, DURATION_FILTER.MONTH))}
            >
                <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button
                type="button"
                className={`-my-1.5 ml-2 flex flex-none items-center justify-center p-1.5 ${isCurrentMonthOrFuture
                    ? 'text-gray-300 cursor-not-allowed'
                    : 'text-gray-400 hover:text-gray-500'
                    }`}
                onClick={() => {
                    if (!isCurrentMonthOrFuture) {
                        setCurrMonth(prevMonth => prevMonth.clone().add(1, DURATION_FILTER.MONTH))
                    }
                }}
                disabled={isCurrentMonthOrFuture}
            >
                <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
            </button>
        </div>
    );
};

const CalendarGrid = ({ daysInMonth, currMonth, selectedDate, handleDateSelection, getAttendanceStatus, lowerBoundDate }) => {
    const today = moment()
    return (
        <div className="grid grid-cols-7 gap-2 mt-8 text-sm">
            {daysInMonth.map((day, idx) => {
                const isCurrentMonth = day.isSame(currMonth, DURATION_FILTER.MONTH);
                const isSelected = day.isSame(selectedDate, DURATION_FILTER.DAY);
                const attendanceStatus = getAttendanceStatus(day);
                const isMembershipStartDate = day.isSame(moment(lowerBoundDate), DURATION_FILTER.DAY);
                const isPast = day.isBefore(today, DURATION_FILTER.DAY);
                const isTodayOrPast = day.isSameOrBefore(today, DURATION_FILTER.DAY);

                // Separate functions for each color determination logic
                const isPreviousMonth = (day, currMonth) => day.isBefore(currMonth, DURATION_FILTER.MONTH);

                const getAttendanceColor = (attendanceStatus) =>
                    attendanceStatus === undefined ? null : (attendanceStatus ? COLORS.GREEN : COLORS.RED);

                const getPastDateColor = (isPast) => isPast ? COLORS.GRAY : COLORS.WHITE;

                // Main function to determine background color
                const getDateBackgroundColor = (day, currMonth, attendanceStatus, isPast) => {
                    if (isPreviousMonth(day, currMonth)) {
                        return COLORS.WHITE;
                    }

                    const attendanceColor = getAttendanceColor(attendanceStatus);
                    if (attendanceColor) {
                        return attendanceColor;
                    }

                    return getPastDateColor(isPast);
                };

                const bgColor = getDateBackgroundColor(day, currMonth, attendanceStatus, isPast);

                return (
                    <div key={idx} className={`${idx === 0 ? `col-start-${day.day() + 1}` : ''}`}>
                        <button
                            className={`flex items-center justify-center h-8 w-8 rounded-full ${bgColor} ${isCurrentMonth ? "text-gray-900" : "text-gray-400"} ${isSelected ? "ring-4 ring-indigo-600" : ""}`}
                            onClick={() => handleDateSelection(day)}
                        >
                            {day.format(dateFormat.dayOfMonth)}
                        </button>
                    </div>
                );
            })}
        </div>
    )
}

export default function Attendance() {
    const { memberId } = useParams();
    const { user } = useSelector((store) => store.user);
    const { memberDetails } = useSelector((store) => store.memberDetails);
    const { attendanceData } = useSelector(store => store.attendance);
    const clubDetails = useSelector((store) => store.clubProfile);
    const dispatch = useDispatch();
    const adminId = user.userId;
    const pdfContentRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(moment());
    const [currMonth, setCurrMonth] = useState(moment());
    const [showReport, setShowReport] = useState(false);
    const [dateRange, setDateRange] = useState([
        moment().subtract(1, DURATION_FILTER.MONTH).format(dateFormat.Slashed),
        moment().format(dateFormat.Slashed),
    ]);

    const lowerBoundDate = attendanceData?.lowerBoundDate;

    useEffect(() => {
        const { startDate, endDate } = getDateRange(currMonth);
        dispatch(getMemberAttendanceData({ adminId, memberId, startDate, endDate }));
    }, [currMonth, adminId, memberId, dispatch]);

    const handleDateSelection = (date) => {
        setSelectedDate(date);
        dispatch(setFollowupDate(date.format(dateFormat.ISO)));
    };

    const getAttendanceStatus = (day) => {
        const formattedDay = day.format(dateFormat.ISO);
        return attendanceData?.logs?.find(item => item.logdate === formattedDay)?.memberPresent;
    };

    const stats = [
        { name: DAYS_PRESENT, noOfDays: attendanceData?.presentDays || 0, bgColor: COLORS.GREEN },
        { name: DAYS_ABSENT, noOfDays: attendanceData?.absentDays || 0, bgColor: COLORS.RED },
        { name: DAYS_NOT_AVAILABLE, noOfDays: attendanceData?.notVaildDays || 0, bgColor: COLORS.GRAY },
    ];

    const daysInMonth = [];
    let currentDay = currMonth.clone().startOf(DURATION_FILTER.MONTH).startOf(DURATION_FILTER.WEEK);
    const monthEnd = currMonth.clone().endOf(DURATION_FILTER.MONTH);
    while (currentDay.isSameOrBefore(monthEnd)) {
        daysInMonth.push(currentDay.clone());
        currentDay.add(1, DURATION_FILTER.DAY);
    }

    const handleShowReport = () => setShowReport(true);
    const handleBack = () => setShowReport(false);

    const handleWhatsAppSendReport = async () => {
        try {
            const pdfBlob = await generateAttendancePDF(pdfContentRef);
            if (pdfBlob) {
                const pdfFile = new File([pdfBlob], ATTENDANCE_REPORT_PDF, { type: FILE_TYPE });
                const formData = new FormData()
                formData.append('file', pdfFile)
                const attendanceDetails = JSON.stringify({
                    adminId: user.userId,
                    memberId: memberId,
                    logMonth: currMonth.format(dateFormat.monthFormat),
                    logYear: currMonth.format(dateFormat.yearFormat),
                    clubName: clubDetails?.clubName,
                    memberName: memberDetails?.mobileNo,
                    memberWhatsAppNumber: memberDetails?.username,
                    memberEmail: memberDetails?.email,
                    clubWhatsappNumber: clubDetails?.clubWhatsAppNo
                })
                formData.append('summaryDetails', attendanceDetails)

                dispatch(sendAttendanceReportViaWhatsapp(formData))
            }
        }
        catch (error) {
            toast.error(ERROR_MESSAGES.FAILED_TO_SEND_ATTENDANCE_REPORT)
        }
    }

    const handleEmailSendReport = () => {
        toast.error(ERROR_MESSAGES.FAILED_TO_SEND_ATTENDANCE_REPORT)
    }

    if (showReport) {
        return (
            <>
                <button
                    onClick={handleBack}
                    className="mb-4 flex items-center text-indigo-600 hover:text-indigo-900"
                >
                    <ChevronLeftIcon className="h-5 w-5 mr-1" />
                    Back to Attendance
                </button>
                <AttendanceReport daysInMonth={daysInMonth} currMonth={currMonth} stats={stats} />
            </>
        );
    }

    const handleDownload = async () => {
        try {
            const pdfBlob = await generateAttendancePDF(pdfContentRef);
            if (pdfBlob) {
                const pdfUrl = URL.createObjectURL(pdfBlob);
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `Attendance_Report_${currMonth.format('MMMM_YYYY')}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(pdfUrl);
            }
        } catch (error) {
            toast.error(ERROR_MESSAGES.ERROR_GENERATING_ATTENDANCE_REPORT)
        }
    };


    const smallScreenMenuItems = [
        { label: DOWNLOAD, onClick: handleDownload },
        { label: VIEW_REPORT, onClick: handleShowReport },
        { label: SEND_VIA_WHATSAPP, onClick: handleWhatsAppSendReport },
        // { label: SEND_VIA_MAIL, onClick: handleEmailSendReport },
    ];

    const sendReportMenuItems = [
        { label: SEND_VIA_WHATSAPP, onClick: handleWhatsAppSendReport },
        // { label: SEND_VIA_MAIL, onClick: handleEmailSendReport },
    ]

    return (
        <>
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                    <CalendarHeader currMonth={currMonth} setCurrMonth={setCurrMonth} />
                    <div className="mt-12 ml-3 gap-x-5 grid grid-cols-7 text-sm text-gray-500">
                        {abbreviatedWeekdays.map((day, index) => (
                            <div key={index}>{day}</div>
                        ))}
                    </div>
                    <CalendarGrid
                        daysInMonth={daysInMonth}
                        currMonth={currMonth}
                        selectedDate={selectedDate}
                        handleDateSelection={handleDateSelection}
                        getAttendanceStatus={getAttendanceStatus}
                        lowerBoundDate={lowerBoundDate}
                    />
                </div>
                <div className="sm:col-span-3">
                    <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                        <div className="flex items-center gap-x-4">
                            <button
                                onClick={handleDownload}
                                className="flex items-center justify-center rounded-md gap-x-2 bg-white text-indigo-600 px-2 py-2 text-sm font-normal border border-indigo-600 hover:bg-indigo-600 hover:text-white shadow-sm"
                            >
                                <ArrowDownTrayIcon className="w-5 h-5 " />
                            </button>
                            <button
                                onClick={handleShowReport}
                                className="flex items-center justify-center rounded-md gap-x-2 bg-white text-indigo-600 px-2 py-2 text-sm font-normal border border-indigo-600 hover:bg-indigo-600 hover:text-white shadow-sm"
                            >
                                <div className="text-sm font-semibold">View</div>
                            </button>
                            <button
                                className="flex items-center justify-center rounded-md gap-x-2 px-2 py-2 text-sm font-normal shadow-sm"
                            >
                                <GenericMenu menuItems={sendReportMenuItems} hidden={false} menuText={SEND_REPORT} />
                            </button>
                        </div>
                    </div>
                    <GenericMenu menuItems={smallScreenMenuItems} hidden={true} menuText={null} />
                    {stats.map((stat, index) => (
                        <div key={index} className="border sm:ml-0 border-gray-300 px-4 py-3 mt-10 rounded-lg sm:col-span-2">
                            <div className="flex items-center justify-between">
                                <div className="">{stat.name}</div>
                                <div className={`${stat.bgColor} rounded-sm p-3`}></div>
                            </div>
                            <div className="mt-2 text-lg font-bold">{stat.noOfDays}</div>
                        </div>
                    ))}
                </div>
                {/* Hidden PDF content */}
                <div style={{ position: 'absolute', left: '-9999px' }}>
                    <PDFContent
                        ref={pdfContentRef}
                        memberDetails={memberDetails}
                        attendanceData={attendanceData}
                        clubDetails={clubDetails}
                        currMonth={currMonth}
                        stats={stats}
                    />
                </div>
            </div>
        </>
    );
}