import React from 'react';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';

export default function SubscriptionOrderSummary({
   amountDetails,
    onPaymentClick,
    isLoading,
    handleGST,
    error
}) {
    return (
        <section className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:px-6 lg:col-span-5 lg:mt-0 lg:px-8 lg:py-0">

            <dl className="space-y-4">
                {/* <form className="sm:max-w-md">
                    <div className="mb-2">
                        <label htmlFor="promo_code" className="text-gray-600 text-base font-normal">
                            Promotional Code
                        </label>
                    </div>
                    <div className="flex flex-row gap-4 items-center">
                        <input
                            id="promo_code"
                            name="promo_code"
                            type="select"
                            required
                            autoComplete="promo_code"
                            className="flex-1 min-w-0 appearance-none rounded-md border border-gray-300 bg-white px-3 py-1.5 text-base text-black shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        />
                        <button
                            type="submit"
                            className="shrink-0 rounded-md bg-indigo-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        >
                            Apply
                        </button>
                    </div>
                </form> */}
                <div className="flex items-center justify-between">
                    <dt className="text-sm text-gray-600">Subtotal</dt>
                    <dd className="text-sm font-medium text-gray-900">₹{amountDetails.subTotal}</dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                    <dt className="flex items-center text-sm text-gray-600">
                        <span>GST(18%)</span>
                        <input
                                id="gstCheck"
                                type="checkbox"
                                className="h-4 w-4 ml-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onChange={handleGST}
                              />
                    </dt>
                    <dd className="text-sm font-medium text-gray-900">₹{amountDetails.gst}</dd>
                </div>
                {/* <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                    <dt className="flex items-center text-sm text-gray-600">
                        <span>Tax estimate</span>
                        <HelpCircle className="ml-2 h-5 w-5 text-gray-400" />
                    </dt>
                    <dd className="text-sm font-medium text-gray-900">${tax}</dd>
                </div> */}
                <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                    <dt className="text-base font-medium text-gray-900">Order total</dt>
                    <dd className="text-base font-medium text-gray-900">₹{amountDetails.totalAmount}</dd>
                </div>
            </dl>

            {error && (
                <div className="mt-4 p-3 rounded bg-red-100 text-red-800">
                    {error}
                </div>
            )}

            <div className="mt-6">
                <button
                    type="submit"
                    className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={onPaymentClick}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <div className="flex items-center justify-center">
                            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                            Processing...
                        </div>
                    ) : (
                        <div className="flex items-center gap-1 justify-center">

                            <ShoppingCartIcon className='h-4 w-4' />
                            Make Payment
                        </div>
                    )}
                </button>
            </div>
        </section>
    );
}