import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const UpgradeTable = ({upgradeData}) => {
  const navigate = useNavigate();

  const handleRecordClick = (id) => {
    navigate(`/memberProfile/${id}`);
  };
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Sl No.
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Mobile No.
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Division
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Before Upgrade
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            After Upgrade
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Before Price
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            After Price
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Upgrade Date
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {upgradeData?.map((upgrade, index) => (
          <tr className="cursor-pointer" onClick={()=>handleRecordClick(upgrade?.member_id)} key={index}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              {index + 1}
            </td>
            <td className="whitespace-nowrap py-4 pl-3 text-sm text-gray-900">
              {upgrade.member_name}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {upgrade.mobile_no}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {upgrade.division}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {upgrade.item_before_upgrade}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {upgrade.item_after_upgrade}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {upgrade.before_bill_amount}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {upgrade.after_bill_amount}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {moment(upgrade?.upgrade_date).format("DD-MM-YY")}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UpgradeTable;
