// Toast.js
import React from 'react';
import { X, CheckCircle, AlertCircle } from 'lucide-react';

export default function Toast({ message, type = 'success', onClose }) {
    const bgColor = type === 'success' ? 'bg-green-100' : 'bg-red-100';
    const textColor = type === 'success' ? 'text-green-800' : 'text-red-800';
    const Icon = type === 'success' ? CheckCircle : AlertCircle;

    return (
        <div className={`fixed top-20 right-4 flex items-center p-4 rounded-lg ${bgColor} ${textColor}`}>
            <Icon className="w-5 h-5 mr-2" />
            <span className="mr-2">{message}</span>
            <button onClick={onClose} className="p-1 hover:opacity-75">
                <X className="w-4 h-4" />
            </button>
        </div>
    );
}