import { Fragment, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import navigation from '../components/Landing/SideBarRoutesAndNames'
import TransitionSideNavBar from '../superAdminComponents/superAdminLanding/TransitionSideNavBar'
import DesktopSideNavBar from '../superAdminComponents/superAdminLanding/DesktopSideNavBar'
import TopLayout from '../superAdminComponents/superAdminLanding/TopLayout'


const SuperAdminLayout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [navigationItems, setNavigationItems] = useState([]);

    useEffect(() => {
        const nav = navigation();
        setNavigationItems(nav);
    }, []);

    return (
        <>
            <div className='h-screen w-screen'>
                <TransitionSideNavBar navigationItems={navigationItems} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-48 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <DesktopSideNavBar navigationItems={navigationItems} />
                </div>

                <div className="lg:pl-48">
                    <TopLayout setSidebarOpen={setSidebarOpen} />

                    <main className="py-6 bg-black text-white min-h-screen">
                        <div className="px-2 sm:px-3 lg:px-5">
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}


export default SuperAdminLayout;