import {useState, useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {
  DesktopSideNavBar,
  TopLayout,
  TransitionSmallSideNavBar,
  navigation,
} from "../components/Landing/";
import {getClubDetails} from "../features/settings/clubProfileSlice";
import {getSubscriptionStatus} from "../features/user/userSlice";

const SharedLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {
    user: {userId: adminId},
    subscriptionStatus,
  } = useSelector(store => store.user);

  const [navigationItems, setNavigationItems] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getClubDetails(adminId));
    dispatch(getSubscriptionStatus(adminId));
  }, []);

  useEffect(() => {
    // Get navigation items when component mounts
    const nav = navigation(subscriptionStatus);
    setNavigationItems(nav);
  }, [subscriptionStatus]);

  const isEMarketPath = location.pathname.includes("/e-market");

  return (
    <div>
      {/* <LandingComponent /> */}
      {/* <div className="lg:pl-20"> */}
      <TransitionSmallSideNavBar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigation={navigationItems}
      />
      <DesktopSideNavBar
        setSidebarOpen={setSidebarOpen}
        navigation={navigationItems}
      />

      {/* </div> */}
      <div className="lg:pl-20">
        {!isEMarketPath && <TopLayout setSidebarOpen={setSidebarOpen} />}
        <main>
          <div
            className={`px-0 py-10 z-50 sm:px-0 lg:px-0 ${
              isEMarketPath ? "lg:py-0" : "lg:py-6"
            } bg-slate-50`}
          >
            {/* Main area */}
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default SharedLayout;
