import { toast } from "react-toastify";
import { default as customAuth } from "../../utils/axios/axiosAuth";
import { default as customAdmin } from "../../utils/axios/axiosAdmin";
import { getAllApprovals } from "./approvalsSlice";
import { current } from "@reduxjs/toolkit";
import { ERROR_MESSAGES } from "../../utils/constants/Prompts/APIError"

export const getAllApprovalsThunk = async (
    getApprovalsURL,
    thunkAPI
) => {
    try {
        const response = await customAuth.get(`${getApprovalsURL}`);
        // console.log(response.data)
        return response.data;

    } catch (error) {
        return thunkAPI.rejectWithValue(ERROR_MESSAGES.FAILED_TO_FETCH_APPROVALS)
    }
}

export const getAllApprovalsByDateRangeThunk = async (
    getApprovalsByDateRangeApi,
    thunkAPI
) => {
    try {
        const response = await customAuth.get(`${getApprovalsByDateRangeApi}`);
        return response.data;

    } catch (error) {
        return thunkAPI.rejectWithValue(ERROR_MESSAGES.FAILED_TO_FETCH_APPROVALS)
    }

}

export const getAllApprovalsThunkById = async (
    getApprovalsByIdUrl,
    thunkAPI
) => {
    try {

        const response = await customAuth.get(`${getApprovalsByIdUrl}`);


        if (response.data.status === true) {
            console.log(response.data)
            return response.data
        } else {
            return thunkAPI.rejectWithValue(ERROR_MESSAGES.FAILED_TO_FETCH_APPROVALS)
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(ERROR_MESSAGES.FAILED_TO_FETCH_APPROVALS)
    }
}

export const postAllApprovalsByStatusThunk = async (
    postAllApprovalStatusUrl,
    approvalStausPayload,
    isBoolean,
    freeTrailSubscriptionPostUrl,
    freeTrailSubscriptionData,
    thunkAPI
) => {
    try {

        const payload = {
            approvalStatus: approvalStausPayload,
            enabled: isBoolean
        }
        console.log(payload)
        const response = await customAuth.put(postAllApprovalStatusUrl, payload);
        if (response.status == 200 || response.status == 201 && response.data) {
            thunkAPI.dispatch(getAllApprovals({ currentPageNo: 0 }))
            if (approvalStausPayload === "APPROVED") {
                const freeTrailsubscriptionResponse = await customAdmin.post(freeTrailSubscriptionPostUrl, freeTrailSubscriptionData);

            }
            return response.data
        } return toast.error(ERROR_MESSAGES.FAILED_TO_APPROVE_THE_DATA)

    } catch (error) {
        return thunkAPI.rejectWithValue(ERROR_MESSAGES.FAILED_TO_APPROVE_THE_DATA)
    }
}

