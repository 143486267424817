import React from 'react'

import logo from '../../assets/images/newLogo.png'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const DesktopSideNavBar = ({ navigationItems }) => {
    return (
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-zinc-900 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center justify-center">
                <img
                    alt="Your Company"
                    src={logo}
                    className="h-8 w-auto"
                />
            </div>
            <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                        <ul role="list" className="-mx-2 space-y-1">
                            {navigationItems.map((item) => (
                                <li key={item.name}>
                                    <a
                                        href={item.href}
                                        className={classNames(
                                            item.current
                                                ? "bg-custon-gray-600 text-white"
                                                : "text-gray-400 hover:text-white hover:bg-zinc-900",
                                            'group flex gap-x-3 rounded-md p-2 font-inter text-base items-center leading-[1.5]',
                                        )}
                                    >
                                        <item.icon aria-hidden="true" className="h-5 w-5 shrink-0" />
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default DesktopSideNavBar