import { useForm } from "react-hook-form";
import { FormField } from "../genericComponents/FormField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { editSettingsDetails } from "../../superAdminFeatures/settings/featuresSlice";
import { HELPER_MODE_LABELS } from "../../utils/constants/keywords";

const EditModal = ({ isOpen, onClose, data, mode }) => {
  const dispatch = useDispatch();

  const schema = yup.object({
    clubName: yup.string().required("Name is required"),
    clubMobileNo: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile number must contain only numbers")
      .required("Mobile number is required"),
    clubMailId: yup
      .string()
      .email("Invalid email address")
      .required("Mail ID is required"),
    clubAddress: yup.string().required("Address is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data && isOpen) {
      Object.keys(data).forEach((key) => setValue(key, data[key]));
    } else {
      reset();
    }
  }, [data, isOpen, setValue, reset]);

  const onSubmit = async (data) => {
    const { clubLogo, ...modifiedData } = data;
    await dispatch(editSettingsDetails(modifiedData));
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-zinc-900 p-6 rounded-lg w-full max-w-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-white text-xl mb-4">Edit Details</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            label="Club Name"
            field={{
              name: "clubName",
              type: "text",
              required: true,
              autoComplete: "off",
            }}
            register={register}
            error={errors.clubName}
            mode={mode}
          />
          <FormField
            label="Phone Number"
            field={{
              name: "clubMobileNo",
              type: "text",
              autoComplete: "off",
              required: true,
            }}
            register={register}
            error={errors.clubMobileNo}
            mode={mode}
          />
          <FormField
            label="Email"
            field={{
              name: "clubMailId",
              type: "email",
              autoComplete: "off",
              required: true,
            }}
            register={register}
            error={errors.clubMailId}
            mode={mode}
          />
          <FormField
            label="Address"
            field={{
              name: "clubAddress",
              type: "text",
              autoComplete: "off",
              required: true,
            }}
            register={register}
            error={errors.clubAddress}
            mode={mode}
          />
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              className="bg-gray-600 px-4 py-2 rounded text-white"
              onClick={onClose}
            >
              Cancel
            </button>
            {mode === HELPER_MODE_LABELS.EDIT_MODE && (
              <button
                type="submit"
                className="bg-indigo-600 px-4 py-2 rounded text-white"
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditModal;
