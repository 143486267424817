import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Button from "../genericComponents/Button";
import { FormFieldPassword } from "../../superAdminComponents/genericComponents/FormField";
import {
  changePasswordSubmission,
  clearChangePasswordErrorResponse,
} from "../../features/user/userSlice";
import { changePasswordValidationSchema } from "../../components/Settings/ChangePasswordValidationSchema";
import { SUPERADMIN } from "../../utils/constants/keywords";

const Changepassword = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector((store) => store.user.user);
  const errorResponse = useSelector(
    (store) => store.user.changePassword.errorResponse
  );

  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(changePasswordValidationSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  useEffect(() => {
    dispatch(clearChangePasswordErrorResponse());
  }, [dispatch]);

  useEffect(() => {
    if (errorResponse?.message) {
      setError(SUPERADMIN.SET_ERROR.OLD_PASSWORD, {
        type: "manual",
        message: errorResponse.message,
      });
    }
  }, [errorResponse, setError]);

  const onSubmit = (data) => {
    const payload = {
      userId,
      currentPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    dispatch(clearChangePasswordErrorResponse());
    dispatch(changePasswordSubmission({ payload, reset }));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-zinc-900 shadow-sm ring-1 ring-gray-900/5 sm:rounded-md"
    >
      <div className="py-6 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-3 lg:grid-cols-6">
        <FormFieldPassword
          label="Old password"
          field={{
            name: "oldPassword",
            required: true,
            type: "password",
            placeholder: "Enter old password",
          }}
          register={register}
          error={errors.oldPassword}
        />
        <FormFieldPassword
          label="New password"
          field={{
            name: "newPassword",
            type: "password",
            required: true,
            placeholder: "Enter new password",
          }}
          register={register}
          error={errors.newPassword}
        />
        <FormFieldPassword
          label="Confirm new password"
          field={{
            name: "confirmPassword",
            type: "password",
            required: true,
            placeholder: "Enter confirm password",
          }}
          register={register}
          error={errors.confirmPassword}
        />
      </div>
      <div className="flex justify-end">
        <Button
          buttonText="Change password"
          type="submit"
          disabled={isSubmitting}
          className="rounded-md w-full sm:w-40 h-10 bg-blue-700 text-sm text-white hover:bg-indigo-500"
        />
      </div>
    </form>
  );
};

export default Changepassword;
