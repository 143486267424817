const classNames = {
    firstColumnOfTableHead: "px-3 py-2 text-gray-800 font-normal",
    lastColumnOfTableHead: "py-1 pl-8 pr-0 text-left text-gray-800 font-normal sm:table-cell md:table-cell",
    middleColumnOfTableHead: "py-1 pl-6 pr-0 text-left text-gray-800 font-normal sm:table-cell md:table-cell",
    firstColumnOfTableBody: "max-w-0 pl-3 py-1 pr-0  align-top  text-gray-800",
    lastColumnOfTableBody: "py-1 pl-8 pr-0 text-left align-top tabular-nums text-gray-800 sm:table-cell",
    middleColumnOfTableBody: "py-1 pl-6 pr-0 text-left align-top tabular-nums text-gray-800 sm:table-cell ",
};


const BillingTransactionDetails = ({ data, mapper, getFormattedBillingData }) => {

    const getColumnHeaderClassName = (index) => {
        const keys = Object.keys(data);
        if (index === 0) {
            return classNames.firstColumnOfTableHead;
        }
        if (index === keys.length - 1) {
            return classNames.lastColumnOfTableHead;
        }
        return classNames.middleColumnOfTableHead;
    };

    const getTableCellClassName = (index) => {
        const keys = Object.keys(data);
        if (index === 0) {
            return classNames.firstColumnOfTableBody;
        }
        if (index === keys.length - 1) {
            return classNames.lastColumnOfTableBody;
        }
        return classNames.middleColumnOfTableBody;
    };


    return (
        <table className={`w-full whitespace-nowrap text-left text-sm`}>
            <thead className={`p-2 text-gray-900`}>
                <tr>

                    {Object.keys(getFormattedBillingData(data[0])).map((key, index) => (
                        <th key={index} className={getColumnHeaderClassName(index)}>
                            {mapper[key]}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {data.map((details, index) => (
                    <tr key={index}>
                        {Object.values(getFormattedBillingData(details)).map((value, index) => (
                            <td key={index} className={getTableCellClassName(index)}>
                                {value}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default BillingTransactionDetails;

