import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { handleBankDetailsEditModal } from "../../features/profile/staffProfileSlice";
import { EditStaffBankDetailsModal } from "./";
import DetailSection from "../People/shared/DetailSection";
import { MISCELLANEOUS_LABELS } from "../../utils/constants/keywords";

const EMPTY_FIELD = "NO DATA";

const TITLE_LABELS_MAP = {
  bankDetails: "Bank Details",
}

const StaffBankDetails = () => {
  const { staffDetails, editBankDetails } = useSelector(
    (store) => store.staffProfile
  );
  const [{ staffId }] = useOutletContext();
  const dispatch = useDispatch();

  const bankDetails = {
    bankName: staffDetails?.employees?.bankName,
    branchName: staffDetails?.employees?.branchName,
    accountType: staffDetails?.employees?.accountType,
    ifsc: staffDetails?.employees?.ifsc,
    accountNo: staffDetails?.employees?.accountNo,
  };

  const labelMapper = {
    bankName: "Bank Name",
    branchName: "Branch Name",
    accountType: "Account Type",
    ifsc: "IFSC Code",
    accountNo: "Account Number",
  };

  const transformedData = Object.keys(bankDetails).map(key => ({
    label: labelMapper[key],
    value: bankDetails[key] || MISCELLANEOUS_LABELS.NOT_AVAILABLE
  }));

  return (
    <>
      <div className="flex items-center">
        <div className="flex-auto">
          {/* <h1 className="text-lg font-semibold leading-6 text-gray-900">
            Bank Details
          </h1> */}
        </div>
        <div className="sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={() => dispatch(handleBankDetailsEditModal(true))}
            className="flex flex-1 justify-end text-gray-800 hover:text-indigo-900"
            data-testid="edit-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </button>
        </div>
      </div>
      {!isEmpty(staffDetails) && (
        <DetailSection title={TITLE_LABELS_MAP.bankDetails} fields={transformedData} />
      )}
      {editBankDetails && (
        <EditStaffBankDetailsModal
          bankDetails={bankDetails}
          staffId={staffId}
        />
      )}
    </>
  );
};

export default StaffBankDetails;