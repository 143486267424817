export { default as Error } from "./Error";
export { default as SinglePageError } from "./SinglePageError";
export { default as Login } from "./Login";
export { default as Dashboard } from "./Dashboard";
export { default as Register } from "./Register";
export { default as SharedLayout } from "./SharedLayout";
export { default as ProtectedRoute } from "./ProtectedRoute";
export { default as Membership } from "./membership/Membership";
export { default as ManageOffers } from "./membership/ManageOffers";
export { default as ManagePackages } from "./membership/ManagePackages";
export { default as ManagePersonalTrainers } from "./membership/ManagePersonalTrainers";
export { default as ManageGroupClass } from "./membership/ManageGroupClass";
export { default as People } from "./people/People";
export { default as Members } from "./people/Members";
export { default as Leads } from "./people/Leads";
export { default as Staff } from "./people/Staff";
export { default as Orders } from "./people/Orders";
export { default as OrderBilling } from "./people/OrderBilling";
export { default as GymMembershipPurchasePage } from "./people/gymMembershipPurchasePage";
export { default as Settings } from "./Settings";
export { default as LeadProfile } from "./people/LeadProfile";
// export { default as MemberProfile } from "./people/MemberProfile";
export { default as StaffProfile } from "./people/StaffProfile";
export { default as ForgotPassword } from "./ForgotPassword";
export { default as Subscription } from "./adminSubscriptions/Subscriptions"
export { default as BuySubscription } from "./adminSubscriptions/BuySubscription"
export { default as SubscriptionPlans } from "./adminSubscriptions/SubscriptionPlans"
