import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const TransferTable = ({ transferData }) => {
  const navigate = useNavigate();

  const handleRecordClick = (id) => {
    navigate(`/memberProfile/${id}`);
  };
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Sl No.
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Division
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Transferred From
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Mobile No.
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Transferred To
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Billing Id
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Date
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Transfer Fee
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            GST %
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {transferData &&
          transferData?.map((transfer, index) => (
            <tr
              className="cursor-pointer"
              onClick={() => handleRecordClick(transfer?.member_id)}
              key={index}
            >
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {index + 1}
              </td>
              <td className="whitespace-nowrap py-4 pl-3 text-sm text-gray-900">
                {transfer.transferred_division}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {transfer.transferred_from_name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {transfer.transferred_from_mobileno}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {transfer.transferred_to_name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {transfer.billing_id}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {moment(transfer?.transfer_date).format("DD-MM-YY")}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {transfer.transferred_amount}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {transfer.gst_amount}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TransferTable;
