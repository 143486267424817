import { useState } from "react";
import { HELPER_MODE_LABELS } from "../../utils/constants/keywords";

const fields = (fieldName) => {
  return fieldName === "planName" || fieldName === "name";
};

export const FormField = ({
  label,
  field: fieldConfig,
  register,
  error,
  onInput = () => {},
  mode,
}) => {
  const inputClasses =
    "block w-full rounded-md border-0 py-3 shadow-sm ring-1 ring-inset ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-zinc-900 text-gray-300";

  return (
    <div className="col-span-2">
      <label
        htmlFor={fieldConfig.name}
        className="block text-sm font-normal leading-6 text-white"
      >
        {label}
        {fieldConfig.required && "*"}
      </label>
      <div className="mt-2">
        {fieldConfig.type === HELPER_MODE_LABELS.SELECT_MODE.toLowerCase() ? (
          <select
            id={fieldConfig.name}
            {...register(fieldConfig.name)}
            autoComplete={fieldConfig.autoComplete}
            className={`${inputClasses} sm:max-w-xs`}
            defaultValue=""
          >
            {fieldConfig.options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            id={fieldConfig.name}
            {...register(fieldConfig.name)}
            type={fieldConfig.type}
            autoComplete={fieldConfig.autoComplete}
            className={
              (fieldConfig.type === "date" &&
                `${inputClasses} custom-date-picker`) ||
              inputClasses
            }
            onInput={onInput}
            disabled={
              mode === HELPER_MODE_LABELS.EDIT_MODE && fields(fieldConfig.name)
            }
          />
        )}
      </div>
      {error && <p className="mt-2 text-sm text-red-500">{error.message}</p>}
    </div>
  );
};

export const FormFieldPassword = ({
  label,
  field: fieldConfig,
  register,
  error,
  mode,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputClasses =
    "block w-full rounded-md border-0 py-3 shadow-sm ring-1 ring-inset ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-zinc-900 text-gray-300 pr-10"; // Added pr-10 for padding right

  return (
    <div className="col-span-2 relative">
      <label
        htmlFor={fieldConfig.name}
        className="block text-sm font-normal leading-6 text-white"
      >
        {label}
        {fieldConfig.required && "*"}
      </label>
      <div className="relative mt-2">
        <input
          id={fieldConfig.name}
          {...register(fieldConfig.name)}
          type={
            fieldConfig.type === "password" && showPassword
              ? "text"
              : fieldConfig.type || "password"
          }
          autoComplete={fieldConfig.autoComplete || "current-password"}
          className={inputClasses}
          placeholder={fieldConfig.placeholder}
          disabled={mode === "edit" && fieldConfig.disabled}
        />
        {(fieldConfig.type === "password" ||
          fieldConfig.showPasswordToggle) && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3 z-10"
          >
            {!showPassword ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 text-gray-400"
              >
                <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                <path
                  fillRule="evenodd"
                  d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 text-gray-400"
              >
                <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
              </svg>
            )}
          </button>
        )}
      </div>
      {error && <p className="mt-2 text-sm text-red-500">{error.message}</p>}
    </div>
  );
};
