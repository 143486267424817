import React from "react";

const SubscriptionHeader = ({ onBack, showBackButton = true }) => {
  return (
    <div className=" flex items-center max-w-2xl px-4 lg:max-w-3xl lg:px-0">
      {showBackButton && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-7 h-7 text-gray-500 cursor-pointer"
          onClick={onBack}
        >
          <path
            fillRule="evenodd"
            d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10Z"
            clipRule="evenodd"
          />
        </svg>
      )}

      <h6 className="text-2xl pl-2 font-normal tracking-tight text-black sm:text-2xl">
        Subscriptions
      </h6>
    </div>
  );
};

export default SubscriptionHeader;
