import React from "react";
import { MEMBERSHIP_CATEGORIES } from "../../../utils/constants/keywords";

const tabItems = [
    { label: "Offers", value: "Offers" },
    { label: "Packages", value: "Packages" },
    { label: "Personal Trainings", value: "PersonalTrainings" },
    { label: "Group Classes", value: "GroupClasses" }
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const TopNavigationTabs = ({ activeTab, handleTabClick, handlePopUpOpen, offerCart, cart }) => {

    return (
        <div className="flex flex-col top-16 w-full fixed lg:w-[calc(100%-5rem)] bg-gray-200 z-10 border-gray-200 px-4 pb-5 sm:pb-0">
            <div className="md:flex md:items-center md:justify-between mb-5">
                <div className="mt-2 flex md:absolute md:right-0 md:top-3 md:mt-0">
                    <div className="relative inline-block text-left pr-4">
                        <div
                            onClick={handlePopUpOpen}
                            className="inline-flex cursor-pointer justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            View cart
                            <div className="text-indigo-600">{activeTab === MEMBERSHIP_CATEGORIES.OFFERS ? offerCart.length == 0 ? '' : offerCart.length : cart.length == 0 ? '' : cart.length}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-0">
                <div className="sm:hidden">
                    <label htmlFor="current-tab" className="sr-only">
                        Select a tab
                    </label>
                    <select
                        id="current-tab"
                        name="current-tab"
                        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                        value={activeTab}
                        onChange={(e) => {
                            const selectedTab = tabItems.find(
                                (tab) => tab.value === e.target.value
                            );
                            handleTabClick(selectedTab.value);
                        }}
                    >
                        {tabItems.map((tab, xi) => (
                            <option key={xi} value={tab.value}>
                                {tab.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav className="-mb-px flex space-x-8">
                        {tabItems.map((tab, i) => (
                            <div
                                key={i}
                                onClick={() => handleTabClick(tab.value)}
                                className={classNames(
                                    tab.value === activeTab
                                        ? "border-indigo-500 text-indigo-600"
                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer"
                                )}
                                role="tab"
                            >
                                {tab.label}
                            </div>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default TopNavigationTabs;
