import React from "react";

const Emarket = () => {
  return (
    <iframe
      src="https://cheerful-sawine-287edb.netlify.app/"
      width="100%"
      height="600px"
      title="Marketing Place"
    />
  );
};

export default Emarket;
