import { useEffect, useState } from "react";

const FormRow = ({ type, name, labelText, register, formState, maxLength }) => {
  const { errors } = formState;
  return (
    <div className="mb-2">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText || name}
      </label>
      <input
        {...register(name)}
        type={type}
        name={name}
        id={name}
        maxLength={maxLength ? maxLength : null}
        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-12"
      />
      {errors[name] && (
        <p className="mt-2 text-wrap w-44 text-xs text-red-600" id={name}>
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

const MultilineFormRow = ({ name, labelText, register, formState }) => {
  const { errors } = formState;

  return (
    <div className="mb-2">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText || name}
      </label>
      <textarea
        {...register(name)}
        name={name}
        id={name}
        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md resize-none h-16"
      ></textarea>
      {errors[name] && (
        <p className="mt-2 text-wrap w-44 text-xs text-red-600" id={name}>
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

const Logo = ({ name, register, formState, Logo, getFieldState }) => {
  const { errors } = formState;
  const { isDirty } = getFieldState("staffImage", formState);
  const [preview, setPreview] = useState(Logo);

  useEffect(() => {
    return () => {
      if (preview !== Logo) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview, Logo]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    }
  };

  return (
    <div className="border-b border-gray-900/10 pb-1">
      <div>
        <div className="flex">
          {/* Image Preview */}
          <img
            src={preview}
            alt="logo"
            className="w-36 h-36 rounded-full border border-gray-300 mb-4 object-cover"
          />
          <input
            {...register(name, {
              onChange: handleFileChange
            })}
            type="file"
            // accept="image/*"
            id={name}
            name={name}
            className="hidden"
          />
          <div className="my-12 mx-4 sm:mx-8 ">
            <div className="flex items-center gap-x-3">
              <label
                htmlFor={name}
                className="rounded-md bg-orange-600 px-2.5 py-1.5 text-sm font-normal text-white shadow-sm  hover:bg-orange-300 cursor-pointer"
              >
                Edit Profile
              </label>
            </div>
            {isDirty && !errors[name] && (
              <p className="mt-2 text-sm text-green-600">
                {"File upload success, ready to submit"}
              </p>
            )}
            {errors[name] && (
              <p className="mt-2 text-sm text-red-600">
                {errors[name].message}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectDropDown = ({
  name,
  options,
  labelName,
  register,
  formState,
  preselected,
  isDisabled,
}) => {
  const { errors } = formState;

  const selectToNone = {
    Select: "",
  };

  return (
    <div className="mb-8 relative">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelName}
      </label>
      <select
        {...register(name)}
        disabled={isDisabled ? true : false}
        id={name}
        name={name}
        className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-white rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      >
        {options.map((eachOption, i) => {
          return (
            <option
              key={i}
              selected={preselected === eachOption.name}
              value={
                (selectToNone[eachOption.name]) 
                // eachOption.name
              }
            >
              {eachOption.name}
            </option>
          );
        })}
      </select>
      {errors[name] && <p className="mt-2 text-sm text-red-600">{errors[name].message}</p>}
    </div>
  );
};

export { FormRow, MultilineFormRow, Logo, SelectDropDown };