import React from "react";
import { MISCELLANEOUS_LABELS } from "../../../utils/constants/keywords";

const DetailSection = ({ title, fields }) => (
    <>
        <div className="mx-auto max-w-7xl sm:px-0.5">
            <div className="flex items-center max-w-2xl px-4 lg:max-w-3xl lg:px-0">
                <h6 className="text-xl font-normal tracking-tight text-black sm:text-xl">
                    {title}
                </h6>
            </div>
        </div>
        <div className="sm:rounded-md sm:border sm:border-gray-300">
            <div>
                <div className="my-4">
                    <div className="grid px-6 py-1 grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3">
                        {fields.map((field, index) => (
                            <div key={index}>
                                <div className="block text-sm font-normal leading-6 text-gray-800">
                                    {field.label}
                                </div>
                                <div className="mt-0.5 break-all text-gray-500">
                                    {field.value || MISCELLANEOUS_LABELS.NOT_AVAILABLE}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>
);


export default DetailSection;
