export const ERROR_MESSAGES = {
    MEMBER_CREATION_ERROR: 'Unable to create member,Please try after some time.',
    LEAD_CREATION_ERROR: 'Unable to create lead,Please try after some time.',
    STAFF_CREATION_ERROR: 'Unable to create staff,Please try after some time.',
    MEMBER_UPDATION_ERROR: 'Unable to update member,Please try after some time.',
    LEAD_UPDATION_ERROR: 'Unable to update lead,Please try after some time.',
    STAFF_UPDATION_ERROR: 'Unable to update staff,Please try after some time.',
    MEMBERSHIP_PURCHASE_ERROR: 'Unable to purchase the membership,Please try after some time.',
    MEMBERSHIP_RENEW_ERROR: 'Unable to renew the membership,Please try after some time.',
    USER_IMAGE_UPLOAD_ERROR: 'Unable to upload image.',
    MEMBER_ID_UPLOAD_ERROR: 'Unable to upload member id proof.',
    STAFF_DOCUMENT_UPLOAD_ERROR: 'Unable to upload staff document.',
    ERROR_FETCHING_MEMBERS_DATA: 'Unable to fetch members data.',
    ERROR_FETCHING_LEADS_DATA: 'Unable to fetch leads data.',
    ERROR_FETCHING_STAFF_DATA: 'Unable to fetch staff data.',
    ERROR_FETCHING_USER_IMAGE: 'Unable to fetch user image.',
    ERROR_FETCHING_MEMBER_DETAILS: 'Failed to retrieve the member details.',
    ERROR_FETCHING_MEMBERS_MEMBERSHIP_DETAILS: 'Failed to retrieve the members membership details.',
    ERROR_FETCHING_MEMBERS_MEMBERSHIP_AND_BILLING_DETAILS: 'Failed to retrieve the members membership and billing details.',
    ERROR_FETCHING_MEMBERSHIP_ON_ID: 'Unable to fetch membership details',
    ERROR_FETCHING_ALL_ACTIVE_PACKAGES: 'Unable to fetch all active packages.',
    ERROR_FETCHING_ALL_ACTIVE_PT: 'Unable to fetch all active personal trainings.',
    ERROR_FETCHING_ALL_ACTIVE_GC: 'Unable to fetch all active group classes.',
    ERROR_FETCHING_ALL_ACTIVE_OFFERS: 'Unable to fetch all active offers.',
    ERROR_POSTING_BILLING_DATA: 'Unable to generate billing.Please try after sometime!',
    ERROR_POSTING_FREEZE_MEMBERSHIP: 'Unable to the freeze membership.',
    ERROR_POSTING_TRANSFER_MEMBERSHIP: 'Unable to the transfer membership.',
    ERROR_POSTING_UPGRADE_MEMBERSHIP: 'Unable to the upgrade membership.',
    ERROR_UPDATING_PURCHASED_MEMBERSHIP: 'Unable to update membership data',
    UNABLE_TO_SYNC: 'Unable to sync member to biometric',
    ERROR_PAYING_DUE_AMOUNT: 'Unable to pay the due amount.',
    ERROR_FETCHING_CHART_DATA: 'Unable to fetch chart data',
    ERROR_FETCHING_SALES_DATA: 'Unable to fetch sales data',
    ERROR_FETHING_TRANSACTION_DATA: 'Unable to fetch transactions data',
    ERROR_FETCHING_DUES_DATA: 'Unable to fetch dues data',
    ERROR_FETCHING_RENEWALS_DATA: 'Unable to fetch renewal data',
    ERROR_FETCHING_ATTENDANCE_DATA: 'Unable to fetch attendance data',
    ERROR_FETCHING_DUES_FOLLOWUP_DATA: 'Unable to fetch dues followup data',
    ERROR_FETCHING_LEADS_FOLLOWUP_DATA: 'Unable to fetch leads folloup data',
    ERROR_FETCHING_RENEWAL_FOLLOWUP_DATA: 'Unable to fetch renew followup data',
    ERROR_FETCHING_RECENT_MEMBERS_DATA: 'Unbale to fetch recent member joined data',
    ERROR_FETHCING_RECENT_PAID_AMOUNT_DATA: 'Unable to fetch recent paid amount data',
    ERROR_FETCHING_RECENT_LEDAS_JOIN_DATA: 'Unable to fetch recent leads data',
    FAIL_TO_SEND_INVOICE: 'Unable to send the invoice',
    INSUFFICIENT_MESSAGE_BALANCE: 'Insufficient WhatsApp credits. Please top up your balance to continue sending messages.',
    ERROR_GENERATING_INVOICE_PDF: 'Something went wrong. Unable to send the invoice. Please try again later.',
    DESIGNATION_ADD_ERROR: 'Failed to add new designation',
    ERROR_FETCHING_DESIGNATION: "Couldn't fetch designations",
    ERROR_GENERATING_ATTENDANCE_REPORT: "Unable to generate attendance report",
    FAILED_TO_SEND_ATTENDANCE_REPORT: "Unable to send the attendance report",
    FAILED_TO_FETCH_APPROVALS: "failed to fetch Approvals",
    FAILED_TO_APPROVE_THE_DATA: "failed to Approve the data",

}


export const SUPER_ADMIN_ERROR_MESSAGES = {
    SUBCRIPTION_CREATION_ERROR: "Failed to create Subscription. Please try after some time.",
    FAILED_TO_ADD_FEATURES_TO_SUBSCRIPTION: "Failed to add features to subscription.",
    SUBCRIPTION_UPDATION_ERROR: "Failed to update subscription. Please try after some time.",
    FAILED_TO_UPDATE_FEATURES_TO_SUBSCRIPTION: "Failed to update features to subscription.",
    FAILED_TO_CHECK_PLAN_NAME: "Failed to check plan name.",
    FAILED_TO_FETCH_SUBSCRIPTIONS: "Unable to fetch subscriptions. Please try again later."

}
