import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Logo from "../assets/images/logo.png"; //WellZy Background Image
import { ResetPassword } from "../components/Registry";
import { useDispatch } from "react-redux";
import { setInitialValues } from "../features/user/userSlice";

const ForgotPassword = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setInitialValues())
  }, [])

  return (
    <>
      <ResetPassword />
    </>
  );
};

export default ForgotPassword;
