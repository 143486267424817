import React, { useState, useEffect, Fragment, useRef } from "react";
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronRightIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'

import { getFormattedDate } from "../../../utils/formatter/dateFormatter";
import { getMemberOrderDetails } from "../../../features/people/memberOrderDetailsSlice";
import { BILLING_HELPERS, MISCELLANEOUS_LABELS, STATUS_TYPES } from "../../../utils/constants/keywords";
import GenericTable from "./GenericTable";
import Invoice from "./Invoice";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import CollectDue from "./CollectDue";
import { setIsCollectDueClicked } from "../../../features/people/collectDueSlice";
import { getClubBillSettingsData } from "../../../features/people/clubSettings/clubBillSettingsSlice";
import { generateInvoicePdf } from "../gymMembershipPurchaseComponents/GenerateInvoicePdf";


const billingDetailsNameMapper = {
    billingDate: 'Bill Date',
    baseTotalAmount: 'Amount',
    totalDiscount: 'Discount',
    amountReceived: 'Paid',
    balance: 'Balance',
    grandTotalAmount: 'Total amount',
    paymentType: "Mode"

}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const isFrozenBilling = (billingName) => billingName === BILLING_HELPERS.FREEZE_BILLING;
const isUpgradeBilling = (billingName) => billingName === BILLING_HELPERS.UPGRADE_BILLING;
const isTransferBilling = (billingName) => billingName === BILLING_HELPERS.TRANSFER_BILLING;
const isDefaultBilling = (billingName) => billingName === '';

const getBillingStatus = (item) => {
    if (isFrozenBilling(item?.billingName)) return STATUS_TYPES.FROZEN;
    if (isUpgradeBilling(item?.billingName)) return STATUS_TYPES.UPGRADED;
    if (isTransferBilling(item?.billingName)) return STATUS_TYPES.TRANSFERRED;
    if (isDefaultBilling(item?.billingName)) return BILLING_HELPERS.MEMBERSHIP_BILLING;

    return MISCELLANEOUS_LABELS.NOT_AVAILABLE;
};

const MembershipBills = () => {
    const dispatch = useDispatch()
    const { memberId } = useParams()
    const { user } = useSelector(store => store.user)
    const { orderBillings, orders } = useSelector((store) => store.memberOrderDetails)
    const { memberDetails } = useSelector((store) => store.memberDetails)
    const { isCollectDueClicked } = useSelector((store) => store.collectDue)
    const [viewInvoice, setViewInvoice] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [isDownloadClicked, setIsDownloadClicked] = useState(false);

    useEffect(() => {
        dispatch(getMemberOrderDetails({ adminId: user.userId, memberId: memberId }))
        dispatch(getClubBillSettingsData({ adminId: user.userId }))
    }, [dispatch, memberId])


    const cloneInvoiceForPDF = () => {
        const originalContent = document.getElementById('pdf-content');
        const clonedContent = originalContent.cloneNode(true);
        clonedContent.id = 'pdf-content-clone';
        clonedContent.style.position = 'absolute';
        clonedContent.style.left = '-9999px';

        const clubAddressElement = clonedContent.querySelector('.text-gray-500.w-full.sm\\:w-3\\/4');
        if (clubAddressElement) {
            clubAddressElement.classList.remove('w-full', 'sm:w-3/4');
            clubAddressElement.classList.add('w-96');
        }

        document.body.appendChild(clonedContent);
        return clonedContent;
    };

    const removeClonedInvoice = (clonedContent) => {
        document.body.removeChild(clonedContent);
    };

    const removeOverflowStyles = (element) => {
        const overflowElements = element.querySelectorAll('.overflow-x-auto');
        overflowElements.forEach(el => {
            el.style.overflow = 'visible';
            el.style.width = 'auto';
            el.style.maxWidth = 'none';
        });
    };

    const handleDownloadPDF = () => {
        const clonedInvoice = cloneInvoiceForPDF();
        removeOverflowStyles(clonedInvoice);

        const PDF_TOP_PADDING = 10;
        const PDF_BOTTOM_PADDING = 10;

        html2canvas(clonedInvoice, {
            scale: 2,
            useCORS: true,
            logging: true,
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const contentWidth = pdfWidth
            const contentHeight = pdfHeight - (PDF_TOP_PADDING + PDF_BOTTOM_PADDING);

            const scaleX = contentWidth / canvas.width;
            const scaleY = contentHeight / canvas.height;
            const scale = Math.min(scaleX, scaleY);

            const imgWidth = canvas.width * scale;
            const imgHeight = canvas.height * scale;

            const xOffset = (pdfWidth - imgWidth) / 2;
            const yOffset = (pdfHeight - imgHeight) / 2;

            let heightLeft = imgHeight;
            let position = yOffset;

            pdf.addImage(imgData, 'PNG', xOffset, position, imgWidth, imgHeight);
            heightLeft -= contentHeight;

            while (heightLeft > 0) {
                pdf.addPage();
                position = yOffset - (heightLeft % contentHeight);
                pdf.addImage(imgData, 'PNG', xOffset, position, imgWidth, imgHeight);
                heightLeft -= contentHeight;
            }

            removeClonedInvoice(clonedInvoice);
            pdf.save('invoice.pdf');
        });
    };

    const handleDownloadInvoice = (item) => {
        setSelectedItem(item);
        setViewInvoice(true);
        setIsDownloadClicked(true);
    };

    useEffect(() => {
        if (isDownloadClicked) {
            handleDownloadPDF();
            setIsDownloadClicked(false);
        }
    }, [isDownloadClicked]);

    const handleInvoiceDetails = (item) => {
        setSelectedItem(item);
        setViewInvoice(true);
    }

    const handleDueCollection = (item) => {
        setSelectedItem(item);
        dispatch(setIsCollectDueClicked(true))
    }

    const getFormattedBillingData = (item) => {
        return {
            billingDate: getFormattedDate(item.billingDate),
            baseTotalAmount: item.baseTotalAmount,
            totalDiscount: item.totalDiscount,
            amountReceived: item.amountReceived,
            balance: item.balance,
            grandTotalAmount: item.grandTotalAmount,
            paymentType: item.paymentType
        }
    }

    return (
        <>
            {!viewInvoice && !isCollectDueClicked && (
                <>
                    {orderBillings.map((item, index) => (
                        <div
                            key={index}
                            className="bg-transparent sm:rounded-md sm:border sm:border-gray-300"
                        >
                            <div className="flex bg-gray-100 items-center border-b border-gray-300 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-4">
                                <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                                    <div>
                                        <div className="font-normal text-lg text-gray-800 whitespace-nowrap">
                                            Invoice #{item.id}
                                        </div>
                                    </div>
                                </dl>
                                <div key={index} className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                                    <div className="flex items-center gap-x-4">
                                        {item.balance !== 0 && (
                                            <button key={index} className="flex items-center justify-center rounded-md gap-x-2 bg-indigo-600 text-white px-8 py-2 text-sm font-normal shadow-sm" onClick={() => handleDueCollection(item)}>
                                                <span>Collect due</span>
                                            </button>
                                        )}
                                        <button key={index} className="flex items-center justify-center rounded-md gap-x-2 bg-white text-indigo-600 px-2 py-2 text-sm font-normal border border-indigo-600 hover:bg-indigo-600 hover:text-white shadow-sm" onClick={() => handleDownloadInvoice(item)}>
                                            <ArrowDownTrayIcon className="w-5 h-5 " />
                                        </button>
                                        <button className="flex items-center justify-center rounded-md gap-x-2 bg-white text-indigo-600 px-2 py-2 text-sm font-normal border border-indigo-600 hover:bg-indigo-600 hover:text-white shadow-sm" onClick={() => generateInvoicePdf(memberId, user.userId, item, dispatch)}>
                                            <div className="text-sm font-semibold">Send</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <Menu as="div" key={index} className="relative flex justify-end lg:hidden">
                                    <div className="flex items-center">
                                        <Menu.Button className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">Options for order</span>
                                            <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                {item.balance !== 0 && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <div
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'block px-4 py-2 text-sm'
                                                                )}
                                                                onClick={() => handleDueCollection(item)}
                                                            >
                                                                Collect due
                                                            </div>
                                                        )}
                                                    </Menu.Item>
                                                )}
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <div
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-1 text-sm'
                                                            )}
                                                            onClick={() => handleDownloadInvoice(item)}
                                                        >
                                                            Download
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <div
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-1 text-sm'
                                                            )}
                                                            onClick={() => generateInvoicePdf(memberId, user.userId, item, dispatch)}
                                                        >
                                                            send
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>

                            </div>
                            <h4 className="sr-only">Items</h4>
                            <ul role="list" className="divide-y divide-gray-200">
                                <li key={index} className="p-4 sm:p-1">
                                    <div className=" flow-root divide-y divide-gray-200  border-gray-200">
                                        <div className="border-b mb-4 border-gray-300">

                                            <div className="-mx-4 -my-2 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8 px-2 sm:px-8">
                                                <GenericTable data={getFormattedBillingData(item)} mapper={billingDetailsNameMapper} />
                                            </div>
                                        </div>
                                        <div className="mt-6 sm:flex items-end justify-end sm:space-x-4 divide-gray-200 border-t border-gray-200 pt-4 text-sm font-medium sm:ml-3 sm:mt-0 sm:border-none sm:pt-0">
                                            <div className="whitespace-nowrap pb-2 font-normal text-medium items-center flex text-gray-800">
                                                Status : {getBillingStatus(item)}
                                            </div>
                                            <div className="flex flex-1 sm:justify-end pr-4 pb-2">
                                                <div className="whitespace-nowrap cursor-pointer items-center flex text-indigo-600 hover:text-indigo-500" onClick={() => handleInvoiceDetails(item)}>
                                                    View invoice details
                                                    <ChevronRightIcon className="h-4 w-4 pl-1" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    ))}
                </>
            )}
            {viewInvoice && (
                <Invoice billData={selectedItem} membershipPurchasedData={orders} memberDetails={memberDetails} download={handleDownloadPDF} setViewInvoice={setViewInvoice} />
            )}
            {isCollectDueClicked && (
                <CollectDue billData={selectedItem} memberDetails={memberDetails} />
            )}
        </>
    )
}

export default MembershipBills;
