import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DASHBOARD } from "../../utils/axios/pathUrls";
import { toast } from "react-toastify";
import customFetch from "../../utils/axios/axiosAdmin";

const initialState = {
  isSalesLoading: true,
  salesCollectionData: {},
  isPrevSalesLoading: true,
  prevSales: {},
  isPrevCollectionLoading: true,
  prevCollections: {},
  isRenewalLoading: true,
  renewals: [],
  isPrevRenewalLoading: true,
  prevRenewals: [],
  isDuesLoading: true,
  dues: {},
  isPrevDuesLoading: true,
  prevDues: {},
  isLeadsLoading: true,
  leads: [],
  isAttendanceLoading: true,
  attendance: [],
  isRecentMemberJoinLoading: true,
  recentMembersJoined: [],
  isRecentLeadJoinLoading: true,
  recentLeadsJoined: [],
  isRecentPaidAmountLoading: true,
  recentPaidAmount: [],
  isLoading: true,
  followupDate: "",
  duesFollowup: {},
  isDuesFollowupLoading: true,
  leadsFollowup: {},
  isLeadsFollowupLoading: true,
  renewalFollowup: {},
  isRenewalFollowupLoading: true,
};

export const getDashboardSalesData = createAsyncThunk(
  "dashboard/getSalesData",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_SALES_COUNT_AMOUNT}${adminId}&startDate=${fromDate}&endDate=${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDashboardRenewalData = createAsyncThunk(
  "dashboard/getRenewalData",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_RENEWAL_STATICTICS}${adminId}/with/date/range?startDate=${fromDate}&endDate=${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getDashboardDuesData = createAsyncThunk(
  "dashboard/getDuesData",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_DUE_STATICTICS}${adminId}&startDate=${fromDate}&endDate=${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getDashboardLeadsData = createAsyncThunk(
  "dashboard/getLeadsData",
  async ({ adminId, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_LEADS}${adminId}/fromDate/${fromDate}/toDate/${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getDashboardAttendanceData = createAsyncThunk(
  "dashboard/getAttendanceData",
  async ({ adminId, attendanceDate, fromDate, toDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_ATTENDENCE}${adminId}/attendanceDate/${attendanceDate}/startDate/${fromDate}/endDate/${toDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getDuesFollowupData = createAsyncThunk(
  "dashboard/getDuesFollowupData",
  async ({ adminId, followupDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${
          DASHBOARD.GET_DUE_LIST_ON_DATE_RANGE
        }${adminId}/startDate/${followupDate}/endDate/${followupDate}?pageNumber=${0}&pageSize=${10}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getLeadsFollowupData = createAsyncThunk(
  "dashboard/getLeadsFollowupData",
  async ({ adminId, followupDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.FOLLOW_UP_LEAD_BY_RANGE}${adminId}/startDate/${followupDate}/endDate/${followupDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getRenewalFollowupData = createAsyncThunk(
  "dashboard/getRenewalFollowupData",
  async ({ adminId, followupDate }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${
          DASHBOARD.GET_RENEWAL_LIST_ON_DATE_RANGE
        }${adminId}/with/date/range?startDate=${followupDate}&endDate=${followupDate}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getRecentMemberJoinedData = createAsyncThunk(
  "dashboard/getRecentMemberData",
  async ({ adminId }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_RECENT_MEMBER_JOINING}${adminId}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getRecentLeadsJoinedData = createAsyncThunk(
  "dashboard/getRecentLeadsData",
  async ({ adminId }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_RECENT_LEAD_JOINING}${adminId}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getRecentPaidAmountData = createAsyncThunk(
  "dashboard/getRecentPaidAmountData",
  async ({ adminId }, thunkAPI) => {
    try {
      const result = await customFetch.get(
        `${DASHBOARD.GET_RECENT_PAID_AMOUNT}${adminId}`
      );
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setFollowupDate: (state, { payload }) => {
      state.followupDate = payload;
    },
    setleadsFollowup: (state, { payload }) => {
      state.leadsFollowup = payload;
    },
    setDuesFollowup: (state, { payload }) => {
      state.duesFollowup = payload;
    },
    setRenewalFollowup: (state, { payload }) => {
      state.renewalFollowup = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardSalesData.pending, (state) => {
        state.isSalesLoading = true;
      })
      .addCase(getDashboardSalesData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isSalesLoading = false;
        state.salesCollectionData = response.data;
      })
      .addCase(getDashboardSalesData.rejected, (state, { payload }) => {
        state.isSalesLoading = false;
        toast.error(payload);
      })
      .addCase(getDashboardRenewalData.pending, (state) => {
        state.isRenewalLoading = true;
      })
      .addCase(getDashboardRenewalData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isRenewalLoading = false;
        state.renewals = response.data;
      })
      .addCase(getDashboardRenewalData.rejected, (state, { payload }) => {
        state.isRenewalLoading = false;
        toast.error(payload);
      })
      .addCase(getDashboardDuesData.pending, (state) => {
        state.isDuesLoading = true;
      })
      .addCase(getDashboardDuesData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isDuesLoading = false;
        state.dues = response.data;
      })
      .addCase(getDashboardDuesData.rejected, (state, { payload }) => {
        state.isDuesLoading = false;
        toast.error(payload);
      })
      .addCase(getDashboardLeadsData.pending, (state) => {
        state.isLeadsLoading = true;
      })
      .addCase(getDashboardLeadsData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isLeadsLoading = false;
        state.leads = response.data;
      })
      .addCase(getDashboardLeadsData.rejected, (state, { payload }) => {
        state.isLeadsLoading = false;
        toast.error(payload);
      })
      .addCase(getDashboardAttendanceData.pending, (state) => {
        state.isAttendanceLoading = true;
      })
      .addCase(getDashboardAttendanceData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isAttendanceLoading = false;
        state.attendance = response.data;
      })
      .addCase(getDashboardAttendanceData.rejected, (state, { payload }) => {
        state.isAttendanceLoading = false;
        toast.error(payload);
      })
      .addCase(getDuesFollowupData.pending, (state) => {
        state.isDuesFollowupLoading = true;
      })
      .addCase(getDuesFollowupData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isDuesFollowupLoading = false;
        state.duesFollowup = response.data;
      })
      .addCase(getDuesFollowupData.rejected, (state, { payload }) => {
        state.isDuesFollowupLoading = false;
        toast.error(payload);
      })
      .addCase(getLeadsFollowupData.pending, (state) => {
        state.isLeadsFollowupLoading = true;
      })
      .addCase(getLeadsFollowupData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isLeadsFollowupLoading = false;
        state.leadsFollowup = response.data;
      })
      .addCase(getLeadsFollowupData.rejected, (state, { payload }) => {
        state.isLeadsFollowupLoading = false;
        state.leadsFollowup = {};
        toast.error(payload);
      })
      .addCase(getRenewalFollowupData.pending, (state) => {
        state.isRenewalFollowupLoading = true;
      })
      .addCase(getRenewalFollowupData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isRenewalFollowupLoading = false;
        state.renewalFollowup = response.data;
      })
      .addCase(getRenewalFollowupData.rejected, (state, { payload }) => {
        state.isRenewalFollowupLoading = false;
        toast.error(payload);
      })
      .addCase(getRecentMemberJoinedData.pending, (state) => {
        state.isRecentMemberJoinLoading = true;
      })
      .addCase(getRecentMemberJoinedData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isRecentMemberJoinLoading = false;
        state.recentMembersJoined = response.data;
      })
      .addCase(getRecentMemberJoinedData.rejected, (state, { payload }) => {
        state.isRecentMemberJoinLoading = false;
        toast.error(payload);
      })
      .addCase(getRecentPaidAmountData.pending, (state) => {
        state.isRecentPaidAmountLoading = true;
      })
      .addCase(getRecentPaidAmountData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isRecentPaidAmountLoading = false;
        state.recentPaidAmount = response.data;
      })
      .addCase(getRecentPaidAmountData.rejected, (state, { payload }) => {
        state.isRecentPaidAmountLoading = false;
        toast.error(payload);
      })
      .addCase(getRecentLeadsJoinedData.pending, (state) => {
        state.isRecentLeadJoinLoading = true;
      })
      .addCase(getRecentLeadsJoinedData.fulfilled, (state, { payload }) => {
        const response = payload;
        state.isRecentLeadJoinLoading = false;
        state.recentLeadsJoined = response.data;
      })
      .addCase(getRecentLeadsJoinedData.rejected, (state, { payload }) => {
        state.isRecentLeadJoinLoading = false;
        toast.error(payload);
      });
  },
});

export const {
  setFollowupDate,
  setleadsFollowup,
  setDuesFollowup,
  setRenewalFollowup,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
