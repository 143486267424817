import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusIcon } from "@heroicons/react/24/outline";

import GenericTable from "../superAdminComponents/genericComponents/GenericTable";
import CreateSubscriptionPlans from "../superAdminComponents/subscriptions/CreateSubscriptionPlans";
import PageHeader from "../superAdminComponents/genericComponents/pageHeader";
import Button from "../superAdminComponents/genericComponents/Button";
import { getAllSubscriptions, setExistingPlanName, updateSubscriptionOnId } from "../superAdminFeatures/subscriptions/superAdminSubscriptionsSlice";
import { HELPER_MODE_LABELS, UI_TEXTS } from "../utils/constants/keywords";
import CustomLoader from "./CustomLoader";

// Define columns configuration
const columns = [
    { key: "planName", header: "PLAN NAME" },
    { key: "numberOfMonths", header: "DURATION" },
    { key: "basePrice", header: "BASE PRICE" },
    { key: "finalPrice", header: "FINAL PRICE" },
    { key: "isActive", header: "STATUS", type: "Toggle" }
];

export default function Subscriptions() {
    const [mode, setMode] = useState(HELPER_MODE_LABELS.LIST_MODE);
    const [selectedSubscription, setSelectedSubscription] = useState(null);

    const { subscriptions, getAllSubscriptionsLoading, isLoading } = useSelector(store => store.superAdminSubscription)
    const dispatch = useDispatch()

    const handleToggleChange = (data) => {
        const updatedData = {
            ...data,
            isActive: !data.isActive, // Toggle the value
        };
        const { features, ...subscriptionData } = updatedData
        dispatch(updateSubscriptionOnId({ id: subscriptionData?.id, subscriptionData, featuresData: null }))
    };

    const handleCreatePlan = () => {
        setSelectedSubscription(null);
        setMode(HELPER_MODE_LABELS.CREATE_MODE);
    };

    const handleSubscriptionClick = (subscription) => {
        setSelectedSubscription(subscription);
        setMode(HELPER_MODE_LABELS.EDIT_MODE);
    };

    const handleBackToList = () => {
        setMode(HELPER_MODE_LABELS.LIST_MODE);
        setSelectedSubscription(null);
        dispatch(setExistingPlanName())
    };

    useEffect(() => {
        dispatch(getAllSubscriptions())
    }, [dispatch])

    const createButton = (
        <Button buttonText={UI_TEXTS.CREATE_PLAN} onClick={handleCreatePlan} type='button' disabled={false} className={'flex items-center gap-2.5 rounded-md bg-blue-700 h-12 px-8 py-2.5 text-center font-inter text-sm leading-[16.94px] text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'} icon={<PlusIcon className="h-4 w-4" />} />
    );

    if (getAllSubscriptionsLoading) {
        return <CustomLoader />
    }

    if (mode === HELPER_MODE_LABELS.LIST_MODE) {
        return (
            <div className="m-2 sm:m-0">
                <PageHeader title={UI_TEXTS.SUBSCRIPTION_PLANS} rightContent={createButton} />
                <GenericTable
                    columns={columns}
                    data={subscriptions}
                    onToggleChange={handleToggleChange}
                    onRowClick={handleSubscriptionClick}
                />
            </div>
        );
    }

    return (
        <CreateSubscriptionPlans
            mode={mode}
            initialData={selectedSubscription}
            onBack={handleBackToList}
        />
    );
}