import { getPTByDivisionThunk, getPackageByDivisionThunk, postMemberUpgradeThunk } from "./upgradeMembershipThunk"
import { API_PEOPLE_MODULE } from '../../utils/axios/pathUrls'
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { VALIDATION_ERROR_MESSAGES } from "../../utils/constants/Prompts"
import { initialUpgradeBillingValues } from "../../components/People/memberProfile/UpgradeMembershipMethods"

const initialState = {
    packageDetail:[],
    ptDataDetail:[],
    gcDataDetail:[],
    isLoading:false,
    selectedPackageId:null,
    selectedPackageData:{},
    gstAmount: 0,
    totalBaseAmount:0,
    previousAmount:0,
    subTotal:0,
    totalDiscount:0,
    grandTotalAmount: 0,
    cashAmount: 0,
    amountReceived: 0,
    balance: 0,
    cashAmountError: '',
    paymentType: '',
    dueDate: '',
    gstEnabled: false
}

export const getPackageByDivisionName = createAsyncThunk(
    'people/getPackageByDivision',
    async ({ adminId,divisionName }, thunkAPI) => {
      try {
          const response = await getPackageByDivisionThunk(
              `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.GET_PACKAGE_BASEDON_ADMIN_ID_DIVISION_NAME}${adminId}/divisionName/${divisionName}`,
              thunkAPI
          )
          return response
      } catch (error) {
          throw error.message
      }
  }
  )
  export const getPTByDivisionName = createAsyncThunk(
    'people/getPTByDivisionName',
    async ({ adminId,divisionName }, thunkAPI) => {
      try {
          const response = await getPTByDivisionThunk(
              `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.GET_PT_BASEDON_ADMIN_ID_DIVISION_NAME}${adminId}/divisionName/${divisionName}`,
              thunkAPI
          )
          return response
      } catch (error) {
          throw error.message
      }
  }
  )
  export const getGCByDivisionName = createAsyncThunk(
    'people/getGCByDivisionName',
    async ({ adminId,divisionName }, thunkAPI) => {
      try {
          const response = await getPackageByDivisionThunk(
              `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.GET_GROUP_CLASS_BASEDON_ADMIN_ID_DIVISION_NAME}${adminId}/divisionName/${divisionName}`,
              thunkAPI
          )
          return response
      } catch (error) {
          throw error.message
      }
  }
  )
  export const postUpgradeMember = createAsyncThunk(
    'people/postUpgradeMember',
    async ({ upgradeMembershipData, billingPayload, adminId, memberId, updatedMembershipData, purchasedMembershipId }, thunkAPI) => {
      try {
          const response = await postMemberUpgradeThunk(
              `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.API_BILLING_MODULE.POST_MEMBERSHIP_BILLING_ON_ADMIN_ID_MEMBER_ID}${adminId}/memberId/${memberId}`, billingPayload,
              `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.POST_MEMBERSHIP_PURCHASE_DATA_ON_ADMIN_ID_MEMBER_ID}${adminId}/memberId/${memberId}`, upgradeMembershipData,
              `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.UPDATE_PURCHASED_MEMBERSHIP}${purchasedMembershipId}`, updatedMembershipData,
              thunkAPI
          )
          return response
      } catch (error) {
          throw error.message
      }
  }
  )

  const upgradeMembershipSlice = createSlice({
    name: 'upgradeMembership',
    initialState,
    reducers: {
        togglingCheckBox:(state,action)=>{
            const {id} = action.payload
            state.selectedPackageId = state.selectedPackageId === id ? null:id;
        },
        getSelectedPackageData:(state,{payload})=>{
            let selectedPackageId = state.selectedPackageId
            state.selectedPackageData = payload.find(
                (item) => item.id === selectedPackageId
              );
        },
        setInitialUpgradeBillingValues: (state) => {
            initialUpgradeBillingValues(state)
        },
        handleCalculation: (state, { payload }) => {
            state.totalBaseAmount = payload.totalBaseAmount;
            state.previousAmount = payload.previousAmount;
            state.subTotal = (Number(payload.totalBaseAmount) - Number(payload.previousAmount))-Number(payload.discount)
            state.grandTotalAmount = state.subTotal;
            state.totalDiscount = payload.discount === '' ? 0 : payload.discount
            if (Number(state.cashAmount) > Number(state.subTotal)) {
              state.balance = 0;
              return;
            } 
                state.balance = (
                  Number(state.subTotal) -
                  Number(state.amountReceived) +
                  Number(state.gstAmount)
                ).toFixed(2);
        },
        calculateUpgradeGrandTotal: (state, { payload }) => {
            let subTotal=state.subTotal
            if (payload.isGstChecked && payload.gstPercentage) {
                state.gstAmount = (parseFloat((Number(payload.gstPercentage) / 100) * subTotal)).toFixed(2);
                subTotal += parseFloat((Number(payload.gstPercentage) / 100) * subTotal);
            } else {
                state.gstAmount = 0;
            }

            if (!isNaN(subTotal)) {
                state.grandTotalAmount = subTotal.toFixed(2);
            } else {
                state.grandTotalAmount = 0;
            }
        },
        setGstEnabled: (state, { payload }) => {
            state.gstEnabled = payload;
        },
        validateCashAmount: (state, { payload }) => {
            const getCashAmountError = (cashAmount, grandTotalAmount) => {
                if (cashAmount === 0) {
                    return VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_REQUIRED;
                }
                if (cashAmount > grandTotalAmount) {
                    return VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_VALIDATATION;
                }
                return '';
            }

            state.cashAmountError = getCashAmountError(Number(payload), Number(state.grandTotalAmount));
        },
        handleBillingCalculation: (state, { payload }) => {
            const inputValue = payload.cashAmount;
            const numericInput = inputValue.replace(/[^0-9.]/g, '');

            if (numericInput !== inputValue) {
                state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_SHOULD_BE_NUMBER;
            } else if (numericInput === '' || numericInput === 0) {
                state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_REQUIRED;
                state.cashAmount = 0;
                state.amountReceived = 0;
                state.balance = 0;
                state.cashAmountError = '';
            } else {
                const numericValue = parseFloat(numericInput);
                if (!isNaN(numericValue) && numericValue <= state.grandTotalAmount) {
                    state.cashAmount = numericValue.toFixed(2);
                    state.amountReceived = numericValue.toFixed(2);
                    state.balance = (state.grandTotalAmount - numericValue.toFixed(2)).toFixed(2);
                    state.cashAmountError = '';
                    state.dueDate = state.balance == 0.00 ? '' : state.dueDate;
                } else {
                    state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_VALIDATATION;
                    state.cashAmount = numericValue.toFixed(2);
                    state.amountReceived = 0;
                    state.balance = 0;
                }
            }
        },
        setPaymentType: (state, { payload }) => {
            state.paymentType = payload;
        },
        setDueDate: (state, { payload }) => {
            state.dueDate = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPackageByDivisionName.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPackageByDivisionName.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.packageDetail=payload
                toast.success(payload)
            })
            .addCase(getPackageByDivisionName.rejected, (state, { payload }) => {
                state.isLoading = false
                // toast.error(payload)
            })
            .addCase(getPTByDivisionName.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPTByDivisionName.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.ptDataDetail=payload
                toast.success(payload)
            })
            .addCase(getPTByDivisionName.rejected, (state, { payload }) => {
                state.isLoading = false
                // toast.error(payload)
            })
            .addCase(getGCByDivisionName.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getGCByDivisionName.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.gcDataDetail=payload
                toast.success(payload)
            })
            .addCase(getGCByDivisionName.rejected, (state, { payload }) => {
                state.isLoading = false
                // toast.error(payload)
            })
            .addCase(postUpgradeMember.pending, (state) => {
                state.isLoading = true
            })
            
            .addCase(postUpgradeMember.fulfilled, (state, { payload }) => {
                state.isLoading = false
                toast.success(payload.message)
            })
            .addCase(postUpgradeMember.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload)
            })
    },
});
export const {
  togglingCheckBox,
  getSelectedPackageData,
  setInitialUpgradeBillingValues,
  calculateUpgradeGrandTotal,
  setDueDate,
  setPaymentType,
  handleBillingCalculation,
  validateCashAmount,
  setGstEnabled,
  handleCalculation,
} = upgradeMembershipSlice.actions;

export default upgradeMembershipSlice.reducer;
