import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilSquareIcon } from "@heroicons/react/24/solid";

import { getClubDetails } from "../../features/settings/clubProfileSlice";
import EditModal from "./SettingsEditModal";
import { HELPER_MODE_LABELS } from "../../utils/constants/keywords";

const InfoItem = ({ label, value }) => (
  <div className="pb-2">
    <div className="flex justify-between border-zinc-800 border-b-2 py-2">
      <p className="text-gray-500">{label}</p>
      <p className="text-gray-400 ml-10">{value}</p>
    </div>
  </div>
);

const ClubHeader = ({ clubLogo, clubName, onEdit }) => (
  <div className="relative w-full flex flex-col items-center mt-2">
    <img
      src={clubLogo}
      alt="Logo"
      className="w-32 h-32 object-contain rounded-full bg-white"
    />
    <p className="text-white mt-4">{clubName}</p>
    <PencilSquareIcon
      className="w-7 h-7 text-gray-500 absolute top-0 right-0 cursor-pointer"
      onClick={onEdit}
    />
  </div>
);

const SettingsEdit = () => {
  const {
    user: { userId: adminId },
  } = useSelector((store) => store.user);
  const clubDetails = useSelector((store) => store.clubProfile);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchClubDetails = () => {
    dispatch(getClubDetails(adminId));
  };

  useEffect(() => {
    const fetchClubDetails = () => {
      dispatch(getClubDetails(adminId));
    };
    fetchClubDetails();
  }, [adminId, dispatch]);

  return (
    <div className="w-full sm:w-6/12 bg-zinc-900 border border-zinc-800 rounded-lg p-4 flex flex-col">
      <ClubHeader
        clubLogo={clubDetails.clubLogo}
        clubName={clubDetails.clubName}
        onEdit={() => setIsModalOpen(true)}
      />
      <div className="px-5 py-10">
        <InfoItem label="Phone number" value={clubDetails.clubMobileNo} />
        <InfoItem label="Email-id" value={clubDetails.clubMailId} />
        <InfoItem label="Address" value={clubDetails.clubAddress} />
      </div>
      <EditModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          fetchClubDetails();
        }}
        data={clubDetails}
        mode={HELPER_MODE_LABELS.EDIT_MODE}
      />
    </div>
  );
};

export default SettingsEdit;
