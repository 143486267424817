
import { Fragment, useEffect, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Transition } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Button from './Button'
import { useDispatch, useSelector } from 'react-redux'
import { store } from '../../store'
import { getAllApprovalsById, postApprovalsByStatus } from '../../superAdminFeatures/Approvals/approvalsSlice'
import { STATUS_TYPES } from '../../utils/constants/keywords'
import { formatDateToISO, subscriptionEndDate } from '../../utils/formatter/dateFormatter'


const gymDetails = [
    { label: "Gym Name", key: "clubName" },
    { label: "Gym Owner", key: "username" },
    { label: "Mobile Number", key: "mobileNo" },
    { label: "Email", key: "email" },
    { label: "Address", key: "clubAddress" },
]

const BUTTON_TEXT = {
    REJECT: "Reject",
    APPROVE: "Approve"
}

export default function GenericDialogBox({ setIsDialog, selectedId, isDateRangeSelected }) {
    const [open, setOpen] = useState(true)

    const { getAllApprovalsDataById } = useSelector((store) => store.approval)

    const dispatch = useDispatch()

    const handleApprovalRejected = async (approvalStatus, isBoolean) => {

        const freeTrailSubscriptionData = {
            adminId: selectedId,
            hasActiveSubscription: true,
            accountStatus: "ACTIVE",
            subscriptions: [
                {
                    subscriptionId: "00000000-0000-0000-0000-000000000000",
                    startDate: await formatDateToISO(new Date()),
                    endDate: await subscriptionEndDate(1),
                    status: "ACTIVE",
                    isActive: true,
                    purchaseType: "FREE_TRIAL"
                }
            ]
        }

        await dispatch(postApprovalsByStatus({ id: selectedId, approvalStatus, isBoolean, freeTrailSubscriptionData }));

        setOpen(false);
        setIsDialog(false);
        isDateRangeSelected(false)
    };



    useEffect(() => {
        dispatch(getAllApprovalsById({ id: selectedId }))
    }, [dispatch])


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50 " onClose={() => { setOpen(false); setIsDialog(false) }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform m-2 md:m-5 lg:mx-20 overflow-hidden w-screen md:max-w-4xl rounded-lg bg-zinc-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6" onClick={(e) => e.stopPropagation()}>
                                <div className="px-4 sm:px-6 lg:px-8 py-3 pt-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <h1 className="text-lg font-normal text-white">Application Information</h1>
                                        </div>
                                    </div>
                                    <div className="mt-8 flow-root">
                                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-5">
                                                <table className="min-w-full divide-y gap-x-10 divide-gray-300">
                                                    <tbody className="bg-zinc-900">

                                                        {gymDetails.map((detail, index) => (
                                                            <tr key={index} className={index % 2 === 1 ? 'bg-zinc-800' : ''}>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-normal text-gray-400 sm:pl-3">
                                                                    {detail.label}
                                                                </td>
                                                                <td className="whitespace-normal break-words py-4 pl-4 pr-3 text-base font-normal text-white sm:pl-28">
                                                                    {getAllApprovalsDataById[detail.key] || ''}
                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                                <div className='flex flex-row space-x-4 sm:flex-row sm:justify-end sm:space-x-4 space-y-0' >
                                                    <Button buttonText={BUTTON_TEXT.REJECT} onClick={() => handleApprovalRejected(STATUS_TYPES.REJECTED, false)} className='bg-transparent text-white text-base font-normal border border-gray-400 rounded-lg px-9 py-2' />
                                                    <Button buttonText={BUTTON_TEXT.APPROVE} onClick={() => handleApprovalRejected(STATUS_TYPES.APPROVED, true)} className='bg-blue-500 text-white text-base font-normal border border-gray-400 rounded-lg px-9 py-2' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
