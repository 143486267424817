import { useSelector } from "react-redux";
import { DURATION_TYPE } from "../../utils/constants/keywords";

export const durationTypes = [
    { id: 1, name: "Month" },
    { id: 2, name: "Week" },
    { id: 3, name: "Day" },
];

export const useDivisionNames = () => {
    const { divisions } = useSelector((store) => store.divisions);
    const divisionNames = divisions.map((item) => ({
        id: item.id,
        name: item.divisionName,
    }));
    return divisionNames;
};

export const calculateDuration = (parent) => {
    const { duration, durationType } = parent;

    if (durationType.name === DURATION_TYPE.MONTH) {
        return 30 * duration;
    }

    if (durationType.name === DURATION_TYPE.WEEK) {
        return 7 * duration;
    }

    return 1 * duration;
};


