export const SUPER_ADMIN_SETTINGS_MODULE = {
    FEATURES: {
        GET_ALL_FEATURES: '/admin-api/api/v1/features',
        POST_CREATE_FEATURE: "/admin-api/api/v1/features",
        PUT_EDIT_FEATURE: "/admin-api/api/v1/features",
        GET_CHECK_FEATURE: "/admin-api/api/v1/features",
    },
};

export const SUPER_ADMIN_SUBSCRIPTION_MODULE = {
    CREATE_SUBSCRIPTION: "/admin-api/api/v1/subscriptions",
    ADD_FEATURES_TO_CREATED_SUBSCRIPTION: "/admin-api/api/v1/subscriptions",
    GET_ALL_SUBSCRIPTION: "/admin-api/api/v1/subscriptions",
    UPDATE_SUBSCRIPTION_ON_ID: "/admin-api/api/v1/subscriptions",
    CHECK_FOR_EXISTING_PLAN_NAME: "/admin-api/api/v1/subscriptions/check?planName="
}

export const SUPER_ADMIN_APPROVALS_MODULE = {
    GET_ALL_APPROVALS: '/auth-api/v1/clubs?',
    GET_All_APPROVALS_BY_DATE_RANGE: '/auth-api/v1/clubs/by-date-range?',
    GET_ALL_APPROVALS_BY_ID: '/auth-api/v1/clubs/',
    POST_APPROVALS: '/auth-api/v1/clubs/',
    ADD_FREE_TRAIL_SUBSCRIPTION_AFTER_APPROVAL: '/admin-api/api/v1/admin/subscriptions'
}

