
import ReactDOM from "react-dom/client";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getMemberDetails } from "../../../features/people/memberDetailsSlice";
import { getMemberOrderDetails } from "../../../features/people/memberOrderDetailsSlice";
import { getClubBillSettingsData } from "../../../features/people/clubSettings/clubBillSettingsSlice";
import Invoice from "./Invoice";
import { getClubDetails } from "../../../features/settings/clubProfileSlice";
import defaultClubLogo from "../../../assets/images/profileImg.png";
import { sendInvoice } from "../../../features/people/gymMembershipPurchaseFeatures/gymMembershipPurchaseSlice";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../../utils/constants/Prompts";

const clubData = {
    id: null,
    adminId: null,
    clubName: "",
    clubLogo: "",
    clubAddress: "",
    clubPincode: "",
    clubMobileNo: "",
    clubWhatsAppNo: "",
    clubMailId: "",
    clubWhatsAppNoAdd: "",
    clubStatus: false,
}

const renderInvoiceToDiv = (data) => {
    return new Promise((resolve) => {
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.left = '-9999px';
        const clubAddressElement = tempDiv.querySelector('.text-gray-500.w-full.sm\\:w-3\\/4');
        if (clubAddressElement) {
            clubAddressElement.classList.remove('w-full', 'sm:w-3/4');
            clubAddressElement.classList.add('w-96');
        }
        document.body.appendChild(tempDiv);

        const root = ReactDOM.createRoot(tempDiv);
        root.render(
            <Invoice
                billData={data.billData}
                membershipPurchasedData={data.memberOrderDetails}
                memberDetails={data.memberDetails}
                billSettingsData={data.billSettingsData}
                clubDetails={clubData}
            />
        );

        setTimeout(() => {
            resolve({ tempDiv, root });
        }, 0);
    });
};

const removeOverflowStyles = (element) => {
    const overflowElements = element.querySelectorAll('.overflow-x-auto');
    overflowElements.forEach(el => {
        el.style.overflow = 'visible';
        el.style.width = 'auto';
        el.style.maxWidth = 'none';
    });
};

export const generateInvoicePdf = async (memberId, adminId, billData, dispatch) => {

    try {
        const memberDetailsResponse = await dispatch(getMemberDetails(memberId));
        const memberDetails = memberDetailsResponse.payload;

        const memberOrderDetailsResponse = await dispatch(getMemberOrderDetails({ adminId: adminId, memberId: memberId }));
        const memberOrderDetails = memberOrderDetailsResponse.payload.purchasedPackages;

        const billSettingsDataResponse = await dispatch(getClubBillSettingsData({ adminId: adminId }));
        const billSettingsData = billSettingsDataResponse.payload

        const clubProfileResponse = await dispatch(getClubDetails(adminId));
        const clubProfile = clubProfileResponse.payload;
        Object.keys(clubData).forEach((eachField) => {
            if (eachField !== "clubLogo") {
                clubData[eachField] = clubProfile[eachField];
            }
        });
        clubData.clubLogo = clubProfile.clubLogo
            ? `data:image/jpeg;base64,${clubProfile.clubLogo}`
            : defaultClubLogo;

        buildInvoicePdf({ billData, memberDetails, memberOrderDetails, billSettingsData }, dispatch)

    } catch (error) {
        console.error("Error generating PDF:", error);
    }
};


const buildInvoicePdf = async (data, dispatch) => {
    try {
        const { tempDiv, root } = await renderInvoiceToDiv(data);
        removeOverflowStyles(tempDiv);

        const PDF_TOP_PADDING = 10;
        const PDF_BOTTOM_PADDING = 10;

        const canvas = await html2canvas(tempDiv, {
            scale: 1,
            useCORS: true,
            logging: true,
        });

        const imgData = canvas.toDataURL('image/jpeg', 0.8);
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
        });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const contentWidth = pdfWidth;
        const contentHeight = pdfHeight - (PDF_TOP_PADDING + PDF_BOTTOM_PADDING);

        const scaleX = contentWidth / canvas.width;
        const scaleY = contentHeight / canvas.height;
        const scale = Math.min(scaleX, scaleY);

        const imgWidth = canvas.width * scale;
        const imgHeight = canvas.height * scale;

        const xOffset = (pdfWidth - imgWidth) / 2;
        const yOffset = (pdfHeight - imgHeight) / 2;

        let heightLeft = imgHeight;
        let position = yOffset;

        pdf.addImage(imgData, 'JPEG', xOffset, position, imgWidth, imgHeight);
        heightLeft -= contentHeight;

        while (heightLeft > 0) {
            pdf.addPage();
            position = yOffset - (heightLeft % contentHeight);
            pdf.addImage(imgData, 'JPEG', xOffset, position, imgWidth, imgHeight);
            heightLeft -= contentHeight;
        }

        root.unmount(); // Unmount the React component
        document.body.removeChild(tempDiv); // Remove the div from the DOM
        const pdfBlob = pdf.output('blob');
        const pdfFile = new File([pdfBlob], 'invoice.pdf', { type: 'application/pdf' });

        const { billData, memberDetails, memberOrderDetails, billSettingsData } = data

        const formData = new FormData()
        formData.append('file', pdfFile)
        const invoiceInfo = JSON.stringify({
            adminId: billData.adminId,
            memberId: billData.memberId,
            billingId: billData.id,
            invoiceDate: billData.billingDate,
            invoiceAmount: billData.amountReceived,
            clubName: clubData.clubName,
            memberName: memberDetails.mobileNo,
            memberWhatsAppNumber: memberDetails.username,
            memberEmail: memberDetails.email,
            clubWhatsappNumber: clubData.clubWhatsAppNo
        })
        formData.append('invoiceDto', invoiceInfo)

        dispatch(sendInvoice(formData))
    } catch (error) {
        toast.error(ERROR_MESSAGES.ERROR_GENERATING_INVOICE_PDF)
    }
}


