import React from 'react';
import { Loader } from 'lucide-react';

const CustomLoader = () => {
    return (
        <div className="flex justify-center items-center h-screen">
            <Loader className="animate-spin text-blue-500" size={96} />
        </div>
    );
};

export default CustomLoader;
