import {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {getSubscriptionStatus, loginUser} from "../features/user/userSlice";
import {Link, useNavigate} from "react-router-dom";

import Logo from "../assets/images/logo.png"; //WellZy Background Image
import backgroundImage from "../assets/images/background.jpg"; //WellZy Background Image

import {FormRow} from "../components/Registry";
import {roles} from "../utils/constants/enums";

const initialState = {
  email: "",
  password: "",
};

const ROLE_NAVIGATION_PATHS = {
  ROLE_ADMIN: "/dashboard",
  ROLE_SUPERADMIN: "/superadmin/dashboard",
  ROLE_ADMIN_WITHOUT_SUBSCRIPTION: "/subscriptions/plans",
  DEFAULT: "/login", // fallback route
};

// Then modify the useEffect
const navigateBasedOnRole = async (user, navigate, dispatch) => {
  try {
    if (!user) return;

    console.log(user.roles[0]?.name);

    const subscriptionStatus = await dispatch(
      getSubscriptionStatus(user.userId)
    );

    const userRole = user.roles[0]?.name;

    let targetPath = ROLE_NAVIGATION_PATHS.DEFAULT;

    if (
      userRole == roles.admin &&
      subscriptionStatus?.payload?.hasActiveSubscription === true
    ) {
      targetPath =
        ROLE_NAVIGATION_PATHS[userRole] || ROLE_NAVIGATION_PATHS.DEFAULT;
    }

    if (
      userRole == roles.admin &&
      subscriptionStatus?.payload?.hasActiveSubscription === false
    ) {
      targetPath = ROLE_NAVIGATION_PATHS["ROLE_ADMIN_WITHOUT_SUBSCRIPTION"];
    }

    if (userRole == roles.superAdmin) {
      targetPath =
        ROLE_NAVIGATION_PATHS[userRole] || ROLE_NAVIGATION_PATHS.DEFAULT;
    }
    navigate(targetPath);
  } catch (error) {
    console.error("Navigation error:", error);
    navigate(ROLE_NAVIGATION_PATHS.DEFAULT);
  }
};

const Login = () => {
  const [values, setValues] = useState(initialState);
  const {user, isLoading} = useSelector(store => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({...values, [name]: value});
  };

  const onSubmit = e => {
    e.preventDefault();
    const {email, password} = values;
    if (!email || !password) {
      toast.error("Please fill in email and password!");
      return;
    }
    dispatch(loginUser({email, password}));
  };

  useEffect(() => {
    navigateBasedOnRole(user, navigate, dispatch);
  }, [user]);

  return (
    // <!-- TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com -->
    <>
      <div className="flex min-h-screen justify-center">
        {/* <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={backgroundImage}
            alt=""
          />
        </div> */}

        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div>
            <img className="mx-auto h-20 w-auto" src={Logo} alt="WellZy" />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
            <p className="mt-2 text-sm leading-6 text-gray-500">
              Not a member?{" "}
              <Link to="/register">
                <span className=" text-indigo-600 hover:text-indigo-500">
                  Register
                </span>
              </Link>
            </p>
          </div>
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="mt-10">
              <div>
                <form
                  action="#"
                  method="POST"
                  className="space-y-6"
                  onSubmit={onSubmit}
                >
                  <FormRow
                    type="text"
                    name="email"
                    labelText="User Name"
                    value={values.email}
                    handleChange={handleChange}
                  />
                  <FormRow
                    type="password"
                    name="password"
                    labelText="Password"
                    value={values.password}
                    handleChange={handleChange}
                  />

                  <div className="flex items-center justify-between">
                    {/* <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-3 block text-sm leading-6 text-gray-700"
                      >
                        Remember me
                      </label>
                    </div> */}

                    <div className="text-sm leading-6">
                      <Link
                        to="/forgotpassword"
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading ..." : "Sign in"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
