import React, { useEffect, useState } from 'react'
import PageHeader from '../superAdminComponents/genericComponents/pageHeader';
import GenericTable from '../superAdminComponents/genericComponents/GenericTable';
import { DatePicker } from 'antd';
import { dateFormat, dateFormatApi } from '../utils/formatter/dateFormatter';
import DateRangePicker from '../components/Generic/DateRangePicker/DateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../store';
import { getAllApprovals, getAllApprovalsByDateRange } from '../superAdminFeatures/Approvals/approvalsSlice';
import Pagination from '../components/Membership/GenericComponent/Pagination';
import AdvanceDateRangePicker from '../superAdminComponents/genericComponents/AdvanceDateRangePicker';
import GenericDialogBox from '../superAdminComponents/genericComponents/GenericDialogBox';
const { RangePicker } = DatePicker;

const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
};

const Approvals = () => {

    const date = new Date();
    const defaultDate = new Date();

    const { getAllApprovalsData, totalPages, getAllADateRange } = useSelector((store) => store.approval)

    const [currentPage, setCurrentPage] = useState(0)

    const [dateRange, setDateRange] = useState([null, null]);

    const [isDateRangeSelected, setIsDateRangeSelected] = useState(false)

    const dispatch = useDispatch()

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [selectedId, setSelectedId] = useState(null)

    const handleViewClick = (row) => {
        setSelectedId(row.id)
        setIsDialogOpen(true)
    }

    useEffect(() => {
        if (!isDateRangeSelected) {
            dispatch(getAllApprovals({ currentPageNo: currentPage }))
        }
    }, [dispatch, currentPage, isDateRangeSelected])

    useEffect(() => {
        if (isDateRangeSelected) {
            dispatch(getAllApprovalsByDateRange({ startDate: formatDate(dateRange[0]), endDate: formatDate(dateRange[1]) }))
        }
    }, [dispatch, currentPage, isDateRangeSelected, dateRange])

    const onPageChange = (page) => {
        setCurrentPage(page)
    }

    const columns = [
        { key: 'clubName', header: 'CLUB NAME' },
        { key: 'createdAt', header: 'DATE' },
        { key: 'approvalStatus', header: 'APPROVAL STATUS' },
        { key: 'view', header: '', type: 'view' }
    ];

    return (
        <>
            {
                <div className='m-2 sm:m-0'>
                    <PageHeader title={"Approvals"} rightContent={
                        <AdvanceDateRangePicker dateRange={dateRange}
                            setDateRange={(newRange) => {
                                setDateRange(newRange);
                                setIsDateRangeSelected(newRange[0] !== null && newRange[1] !== null);
                            }}
                            setSelectedButton={null}
                        />
                    } />

                    <GenericTable
                        columns={columns}
                        data={!isDateRangeSelected ? getAllApprovalsData : getAllADateRange}
                        onViewClick={handleViewClick}

                    />

                    {!isDateRangeSelected && (<Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />)}
                </div>
            }
            {isDialogOpen && <GenericDialogBox setIsDialog={setIsDialogOpen} selectedId={selectedId} isDateRangeSelected={setIsDateRangeSelected} />}
        </>
    )
}

export default Approvals;