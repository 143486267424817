import React from 'react';

const PageHeader = ({
    title,
    rightContent
}) => {
    return (
        <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
                <h1 className="font-inter text-2xl leading-[20px] tracking-[0.005em] text-white">
                    {title}
                </h1>
            </div>
            {rightContent && (
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    {rightContent}
                </div>
            )}
        </div>
    );
};

export default PageHeader;