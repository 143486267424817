import * as yup from 'yup';

// Validation Schema
export const createSubscriptionPlansSchema = yup.object({
    planName: yup.string()
        .required("Please enter the plan name")
        .matches(/^[A-Za-z0-9\s]*$/, "Plan name can only contain letters, numbers and spaces"),
    numberOfMonths: yup
        .number()
        .nullable()
        .transform((value, originalValue) => {
            if (typeof originalValue === 'string') {
                return originalValue.trim() === '' ? null : Number(originalValue);
            }
            return value;
        })
        .typeError('Please enter a valid number')
        .required('Number of months is required')
        .min(1, 'Number of months must be at least 1')
        .integer('Number of months must be a whole number'),
    basePrice: yup.number()
        .nullable()
        .transform((value, originalValue) => {
            if (typeof originalValue === 'string') {
                return originalValue.trim() === '' ? null : Number(originalValue);
            }
            return value;
        })
        .typeError('Please enter a valid number')
        .required('Base price is required')
        .integer('Must be a whole number')
        .positive('Must be a positive number'),
    finalPrice: yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(), // Make it nullable
    discountType: yup.string()
        .required('Please select a discount type'),
    discountValue: yup.number()
        .nullable()
        .transform((value, originalValue) => {
            if (typeof originalValue === 'string') {
                return originalValue.trim() === '' ? null : Number(originalValue);
            }
            return value;
        })
        .typeError('Please enter a valid number')
        .required('Amount/Percentage is required')
        .when('discountType', {
            is: 'PERCENTAGE',
            then: (schema) => schema
                .min(0, 'Percentage must be between 0 and 100')
                .max(100, 'Discount percentage cannot be more than 100%'),
            otherwise: (schema) => schema
                .min(0, 'Amount must be greater than or equal to 0')
                .test('max-discount', 'Discount price cannot be more than base price',
                    function (value) {
                        const basePrice = this.parent.basePrice;
                        return !value || !basePrice || value <= basePrice;
                    }
                )
        }),
    features: yup.array()
        .min(1, 'Please select at least one feature')
        .required('Please select at least one feature')
});

