export const SUCCESS_MESSAGES = {
  MEMBER_CREATED: "Member created successfully.",
  LEAD_CREATED: "Lead created successfully.",
  STAFF_CREATED: "Staff created successfully.",
  MEMBERSHIP_PURCHASED: "Membership purchased successfully.",
  MEMBERSHIP_RENEWED: "Membership renewed successfully.",
  MEMBER_UPDATED: "Member updated successfully.",
  LEAD_UPDATED: "Lead updated successfully.",
  STAFF_UPDATED: "Staff updated successfully.",
  SUCCESFULL_REGISTRATION:
    "Registration Successful, we'll get back to you soon!",
  SUCCESSFULL_DIVISION_CREATION: "New Division Created Successfully",
  SUCCESSFULL_BIOMETRIC_UPDATION:
    "Biometric Number sucessfully updated for division:",
};

export const ERROR_MESSAGES = {
  MEMBER_CREATION_ERROR: "Unable to create member,Please try after some time.",
  LEAD_CREATION_ERROR: "Unable to create lead,Please try after some time.",
  STAFF_CREATION_ERROR: "Unable to create staff,Please try after some time.",
  MEMBER_UPDATION_ERROR: "Unable to update member,Please try after some time.",
  LEAD_UPDATION_ERROR: "Unable to update lead,Please try after some time.",
  STAFF_UPDATION_ERROR: "Unable to update staff,Please try after some time.",
  MEMBERSHIP_PURCHASE_ERROR:
    "Unable to purchase the membership,Please try after some time.",
  MEMBERSHIP_RENEW_ERROR:
    "Unable to renew the membership,Please try after some time.",
  USER_IMAGE_UPLOAD_ERROR: "Unable to upload image.",
  MEMBER_ID_UPLOAD_ERROR: "Unable to upload member id proof.",
  STAFF_DOCUMENT_UPLOAD_ERROR: "Unable to upload staff document.",
  ERROR_FETCHING_MEMBERS_DATA: "Unable to fetch members data.",
  ERROR_FETCHING_LEADS_DATA: "Unable to fetch leads data.",
  ERROR_FETCHING_STAFF_DATA: "Unable to fetch staff data.",
  ERROR_FETCHING_USER_IMAGE: "Unable to fetch user image.",
  ERROR_FETCHING_MEMBER_DETAILS: "Failed to retrieve the member details.",
  ERROR_FETCHING_MEMBERS_MEMBERSHIP_DETAILS:
    "Failed to retrieve the members membership details.",
  ERROR_FETCHING_MEMBERS_MEMBERSHIP_AND_BILLING_DETAILS:
    "Failed to retrieve the members membership and billing details.",
  ERROR_FETCHING_MEMBERSHIP_ON_ID: "Unable to fetch membership details",
  ERROR_FETCHING_ALL_ACTIVE_PACKAGES: "Unable to fetch all active packages.",
  ERROR_FETCHING_ALL_ACTIVE_PT:
    "Unable to fetch all active personal trainings.",
  ERROR_FETCHING_ALL_ACTIVE_GC: "Unable to fetch all active group classes.",
  ERROR_FETCHING_ALL_ACTIVE_OFFERS: "Unable to fetch all active offers.",
  ADMIN_LOGO_FETCH_FAIL: "Unable to load profile image",
  DIVISION_FETCH_FAIL: "Couldn't fetch divisions",
  DIVISION_CREATION_FAIL: "Unable to create division, try again later",
  BIOMETRIC_UPDATE_FAIL:
    "Couldn't update Biometric Number, please try again later",
};

export const WARNING_MESSAGES = {
  CART: "Please add items into cart.",
  BILLS_UPGRADE_WARNING: "Please enter correct values, can not upgrade",
  MAX_LIMIT_TERMS_AND_CONDITION: "Maximum terms reached, max seven terms",
};

export const VALIDATION_ERROR_MESSAGES = {
  MOBILE_NO_EXIST: "Mobile No. already exists!",
  CASH_AMOUNT_REQUIRED: "*Cash amount is required",
  CASH_AMOUNT_VALIDATATION:
    "*Cash Amount should be a valid number less than or equal to Grand Total",
  CASH_AMOUNT_SHOULD_BE_NUMBER: "*Cash Amount should only contain numbers",
  EXISTING_MEMBERSHIP_ERROR:
    "*Already membership is existing, select the startDate greater than previous membership endDate:",
  DISCOUNT_VALIDATION: "*Total amount after discount must be between",
  BILLING_DATE_REQUIRED: "*Billing date is required.",
  DUE_DATE_REQUIRED: "*Due date is required",
  PAYMENT_TYPE_REQUIRED: "*Please select the payment method",
  START_DATE_REQUIRED: "*Start date is required",
  START_DATE_AFTER_BILLING_DATE:
    "*Start date of membership should be after billing date.",
  GST_NUMBER_VALIDATION: "Only alphanumerics allowed, must be 15 characters",
  GST_PERCENTAGE_VALIDATION: "Must be Digits",
  GST_NUMBER_REQUIRED: 'Gst number is required.',
  GST_PERCENTAGE_REQUIRED: 'Gst percentage is required.'
};
