import { MISCELLANEOUS_LABELS } from "../../utils/constants/keywords";

const RowsContainer = ({ json, mapper }) => {
  return (
    <dl className="mt-2 flex flex-grow flex-col justify-evenly">
      {Object.keys(json).map((key, i) => {
        return (
          <div key={i} className="flex justify-between border-b border-gray-200 p-3">
            <dt className="flex text-sm text-left text-gray-800">{mapper[key]}</dt>
            <dd className="text-sm text-right text-gray-800 pl-24 break-all">
              <p>{json[key] ? json[key] : MISCELLANEOUS_LABELS.NOT_AVAILABLE}</p>
            </dd>
          </div>
        );
      })}
    </dl>
  );
};

export default RowsContainer;
