import {useEffect} from "react";

const KnowYourWellness = () => {
  return (
    <div className="w-full h-screen overflow-hidden">
      <iframe 
        src="https://beamish-kataifi-16b3a3.netlify.app/" 
        className="w-full h-full"
        title="Know Your Wellness"
        frameBorder="0"
      />
    </div>
  );
};

export default KnowYourWellness;