import {
  UsersIcon,
  HomeIcon,
  UserCircleIcon,
  UserIcon,
  DocumentDuplicateIcon,
  GlobeAltIcon,
  Cog8ToothIcon,
  ClipboardDocumentCheckIcon,
  TagIcon,
  ReceiptPercentIcon,
  CurrencyRupeeIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/24/outline";

const routeToIconMapper = {
  Dashboard: HomeIcon,
  People: UsersIcon,
  "Follow Up": UserIcon,
  Membership: UserCircleIcon,
  Reports: DocumentDuplicateIcon,
  "E-Market": GlobeAltIcon,
  Settings: Cog8ToothIcon,
  Approvals: ClipboardDocumentCheckIcon,
  Subscriptions: TagIcon,
  Promo: ReceiptPercentIcon,
  Payments: CurrencyRupeeIcon,
  Clubs: BuildingOfficeIcon,
  login: HomeIcon,
};

const roleBasedRoutes = {
  ROLE_ADMIN: [
    "Dashboard",
    "People",
    "Membership",
    "Reports",
    "Subscriptions",
    "E-Market",
    "Settings",
  ],
  ROLE_SUPERADMIN: [
    "Dashboard",
    "Approvals",
    "Subscriptions",
    "Promo",
    "Payments",
    "Clubs",
    "Settings",
  ],
  DEFAULT: ["login"],
  // Add more roles as needed
};

// Function to get routes based on role
const getRoutesByRole = role => {
  return roleBasedRoutes[role]; // Default to user routes if role not found
};

const routeNames = role => getRoutesByRole(role);

export {routeToIconMapper, routeNames};
