import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import {
  groupClassSlice,
  offerSlice,
  packageSlice,
  personalTainerSlice,
} from "./features/membership";

import dashboardSlice from "./features/dashboard/dashboardSlice";
import {
  peopleLeadSlice,
  peopleMemberSlice,
  peopleStaffSlice,
  memberDetailsSlice,
  peopleProfileSlice,
  memberOrdersSlice,
  memberOrderDetailsSlice,
  peopleCommonSlice,
  clubBillSettingsSlice,
  designationSlice,
  getAllMembershipSlice,
  gymMembershipPurchaseSlice,
  freezeMembershipSlice,
  memberBioSyncSlice,
  collectDueSlice,
} from "./features/people";

import dashboardChartSlice from "./features/dashboard/dashboardChartSlice";
import reportsSlice from "./features/reports/reportsSlice";
import { downloadSlice } from "./features/reports";
import clubProfileSlice from "./features/settings/clubProfileSlice";
import divisionSlice from "./features/settings/divisionSlice";
import settingsDesignationSlice from "./features/settings/settingsDesignationSlice";
import billsSlice from "./features/settings/billsSlice";
import staffProfileSlice from "./features/profile/staffProfileSlice";
import leadProfileSlice from "./features/profile/leadProfileSlice";
import searchSlice from "./features/search/searchSlice";
import transferMembershipSlice from "./features/people/transferMembershipSlice";
import upgradeMembershipSlice from "./features/people/upgradeMembershipSlice";
import attendanceSlice from "./features/people/attendanceSlice";

import { approvalSlice, featuresSlice, superAdminSubscriptionSlice, } from "./superAdminFeatures";

import subscriptionslice from "./features/subscription/subscriptionslice";


export const store = configureStore({
  reducer: {
    user: userSlice,
    offers: offerSlice,
    packages: packageSlice,
    personalTrainers: personalTainerSlice,
    groupClasses: groupClassSlice,
    dashboard: dashboardSlice,
    dashboardChart: dashboardChartSlice,
    members: peopleMemberSlice,
    peopleProfile: peopleProfileSlice,
    leads: peopleLeadSlice,
    staffs: peopleStaffSlice,
    memberDetails: memberDetailsSlice,
    memberOrders: memberOrdersSlice,
    memberOrderDetails: memberOrderDetailsSlice,
    clubBillSettings: clubBillSettingsSlice,
    peopleCommonSlice: peopleCommonSlice,
    divisions: divisionSlice,
    designations: designationSlice,
    reports: reportsSlice,
    download: downloadSlice,
    clubProfile: clubProfileSlice,
    settingsDesignation: settingsDesignationSlice,
    bills: billsSlice,
    getAllMemberships: getAllMembershipSlice,
    gymMembershipPurchase: gymMembershipPurchaseSlice,
    freezeMembership: freezeMembershipSlice,
    staffProfile: staffProfileSlice,
    leadProfile: leadProfileSlice,
    memberBioSync: memberBioSyncSlice,
    collectDue: collectDueSlice,
    search: searchSlice,
    transferMembership: transferMembershipSlice,
    upgradeMembership: upgradeMembershipSlice,
    attendance: attendanceSlice,
    features: featuresSlice,
    superAdminSubscription: superAdminSubscriptionSlice,
    approval: approvalSlice,

    subscriptions: subscriptionslice,
  },
});
