import React, { useEffect } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../genericComponents/Button";
import { getAllFeatures } from "../../superAdminFeatures/settings/featuresSlice";
import { HELPER_MODE_LABELS } from "../../utils/constants/keywords";

const Feature = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allFeatures } = useSelector((state) => state.features);

  useEffect(() => {
    dispatch(getAllFeatures());
  }, [dispatch]);

  const handleNavigate = (mode, featureData = {}) => {
    navigate("/superadmin/createnewfeature", {
      state: { mode, allFeatures: featureData },
    });
  };

  return (
    <div className="text-white">
      <div className="flex justify-between mb-6 mt-2">
        <div>
          <p className="text-lg">Feature Library</p>
          <p className="text-gray-600">Create or modify features.</p>
        </div>
        <Button
          buttonText="Create Feature"
          type="button"
          className="flex items-center gap-2.5 sm:w-48 mt-2 rounded-md bg-blue-700 h-12 px-8 py-2.5 text-sm text-white hover:bg-indigo-500"
          onClick={() => handleNavigate(HELPER_MODE_LABELS.CREATE_MODE)}
          icon={<PlusIcon className="w-5 h-5" />}
        />
      </div>

      <div>
        <p className="text-lg mt-6">Feature Library</p>
        {allFeatures.map((feature, index) => (
          <p
            key={index}
            className="text-gray-400 py-1 cursor-pointer hover:text-white"
            onClick={() =>
              handleNavigate(HELPER_MODE_LABELS.EDIT_MODE, feature)
            }
          >
            {feature.name}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Feature;
