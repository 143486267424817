import React from 'react';

const Button = ({
    buttonText,
    onClick,
    type = 'button',
    disabled = false,
    className = '',
    icon,
}) => {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={className}
        >
            {buttonText}
            {icon && <span>{icon}</span>}
        </button>
    );
};

export default Button;