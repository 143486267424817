import React, { useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { LeadProfileDetails, EnquiryTable } from "../../components/Profile/";
import { getLeadDetails } from "../../features/profile/leadProfileSlice";

const TITLE_LABELS_MAP={
  leadEnquiryDetails: "Lead Enquiry Details"
}

const LeadProfile = () => {
  const { leadId } = useParams();
  const { leadDetails } = useSelector((store) => store.leadProfile);
  const dispatch = useDispatch();

  const leadLabelMapper = {
    interested: "Enquired Division",
    leadSource: "Lead Source",
    // createdAt: "Date of Enquiry/Joining",
    followUpDate: "Next Follow Up Date",
    shortNotes: "Notes",
    leadMobileNo: "Phone Number",
    gender: "Gender",
    leadMail: "Email Id",
    pincode: "Pincode",
    leadName: "Lead Name",
    leadRating: "Rating",
  };

  const formEnquiryDetails = (leadDetails) => {
    var { interested, leadSource, followUpDate, shortNotes } = leadDetails;
    // createdAt = dayjs(createdAt).format("DD/MM/YYYY");
    return {
      interested,
      leadSource,
      // createdAt,
      followUpDate,
      shortNotes,
    };
  };

  const formLeadDetails = (leadDetails) => {
    var {
      leadName,
      pincode,
      leadMobileNo,
      // createdAt,
      gender,
      leadMail,
      leadRating,
    } = leadDetails;
    // createdAt = dayjs(createdAt).format("DD/MM/YYYY");

    return {
      leadName,
      pincode,
      leadMobileNo,
      // createdAt,
      gender,
      leadMail,
      leadRating,
    };
  };

  const formEditLeadDetails = (leadDetails) => {
    var {
      leadName,
      pincode,
      interested,
      leadSource,
      // createdAt,
      followUpDate,
      shortNotes,
      leadMobileNo,
      gender,
      leadMail,
      leadRating,
    } = leadDetails;
    // createdAt = dayjs(createdAt).format("DD/MM/YYYY");

    return {
      leadName,
      pincode,
      interested,
      leadSource,
      // createdAt,
      followUpDate,
      shortNotes,
      leadMobileNo,
      gender,
      leadMail,
      leadRating,
    };
  };

  useEffect(() => {
    dispatch(getLeadDetails(leadId));
  }, [leadId]);

  return (
    <div>
      <div className="max-w-7xl sm:px-2 lg:px-5">
        <div className=" flex items-center max-w-2xl px-4 lg:max-w-3xl lg:px-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-7 h-7 text-gray-500 cursor-pointer"
            onClick={() => window.history.back()}
          >
            <path
              fillRule="evenodd"
              d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10Z"
              clipRule="evenodd"
            />
          </svg>
          <h6 className="text-2xl pl-2 font-normal tracking-tight text-black sm:text-2xl">
            Lead profile
          </h6>
        </div>
      </div>
      <div className="px-4 sm:px-6 lg:px-6 lg:py-6">
        <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-6">
          <div className="mt-8 lg:col-span-4 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
            <h2 className="sr-only">Images</h2>
            <LeadProfileDetails
              leadId={leadId}
              leadDetails={formLeadDetails(leadDetails)}
              editLeadDetails={formEditLeadDetails(leadDetails)}
              labelMapper={leadLabelMapper}
              followUpStatus={leadDetails?.followUpStatus}
            />
          </div>
          <div className="mt-5 lg:mt-0 lg:col-span-8">
            <div className="bg-white rounded-lg sticky lg:h-screen lg:overflow-auto shadow-md">
              <div className="py-4 sm:py-1 px-4">
                <div className="mt-6">
                  <h2 className="sr-only">Recent orders</h2>
                  <div className="mx-auto max-w-8xl sm:px-2 lg:px-2">
                    <div className=" max-w-2xl space-y-6 sm:px-4 sm:max-w-full lg:px-0">
                      <EnquiryTable
                        Title={TITLE_LABELS_MAP.leadEnquiryDetails}
                        JSON={formEnquiryDetails(leadDetails)}
                        LabelMapper={leadLabelMapper}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default LeadProfile;
