import { toast } from "react-toastify";

import customFetch from "../../utils/axios/axiosAdmin";
import { getAllSubscriptions } from "./superAdminSubscriptionsSlice";
import { SUPER_ADMIN_ERROR_MESSAGES } from "../../utils/constants/Prompts/APIError";
import { SUPER_ADMIN_SUCCESS_MESSAGES } from "../../utils/constants/Prompts/APISuccess";

// Utility functions
const isSuccessStatus = (status) => status === 200 || status === 201;

const handleApiResponse = (response) => {
    return isSuccessStatus(response.status) && response.data;
};

const handleApiError = (message, thunkAPI) => {
    toast.error(message);
    return thunkAPI.rejectWithValue(message);
};

// API interaction functions
const makeApiCall = async (method, url, data = null) => {
    const response = await customFetch[method](url, data);
    return handleApiResponse(response);
};

const updateFeatures = async (url, subscriptionId, featureData) => {
    return await makeApiCall('put', `${url}/${subscriptionId}/features`, featureData);
};

// Core functionality
const handleSubscriptionCreation = async (subscriptionResponse, featureUrl, featureData, thunkAPI) => {
    if (!subscriptionResponse) {
        return handleApiError(SUPER_ADMIN_ERROR_MESSAGES.SUBCRIPTION_CREATION_ERROR, thunkAPI);
    }

    const featureResponse = await updateFeatures(featureUrl, subscriptionResponse.id, featureData);
    await thunkAPI.dispatch(getAllSubscriptions());

    if (!featureResponse) {
        return toast.error(SUPER_ADMIN_ERROR_MESSAGES.FAILED_TO_ADD_FEATURES_TO_SUBSCRIPTION);
    }

    return toast.success(SUPER_ADMIN_SUCCESS_MESSAGES.SUBSCRIPTION_CREATED);
};

const handleSubscriptionUpdate = async (subscription, featureUrl, featureData, thunkAPI) => {
    if (!subscription) {
        return handleApiError(SUPER_ADMIN_ERROR_MESSAGES.SUBCRIPTION_UPDATION_ERROR, thunkAPI);
    }

    if (featureData) {
        const featureResponse = await makeApiCall(
            'put',
            `${featureUrl}/${subscription.id}/features`,
            featureData
        );

        await thunkAPI.dispatch(getAllSubscriptions());

        if (!featureResponse) {
            return toast.error(SUPER_ADMIN_ERROR_MESSAGES.FAILED_TO_UPDATE_FEATURES_TO_SUBSCRIPTION);
        }
    }

    await thunkAPI.dispatch(getAllSubscriptions());
    return toast.success(SUPER_ADMIN_SUCCESS_MESSAGES.SUBSCRIPTION_UPDATED);
};

// Thunk functions
export const createSubscriptionsThunk = async (
    createSubscriptionUrl,
    subscriptionData,
    addFeaturesToSubscriptionUrl,
    featuredData,
    thunkAPI
) => {
    try {
        const subscriptionResponse = await makeApiCall('post', createSubscriptionUrl, subscriptionData);
        return await handleSubscriptionCreation(
            subscriptionResponse,
            addFeaturesToSubscriptionUrl,
            featuredData,
            thunkAPI
        );
    } catch (error) {
        return handleApiError(SUPER_ADMIN_ERROR_MESSAGES.SUBCRIPTION_CREATION_ERROR, thunkAPI);
    }
};

export const getAllSubscriptionsThunk = async (
    getAllSubscriptionsURL,
    thunkAPI
) => {
    try {
        const data = await makeApiCall('get', getAllSubscriptionsURL);
        if (!data) {
            return handleApiError(SUPER_ADMIN_ERROR_MESSAGES.FAILED_TO_FETCH_SUBSCRIPTIONS, thunkAPI);
        }
        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(SUPER_ADMIN_ERROR_MESSAGES.FAILED_TO_FETCH_SUBSCRIPTIONS);
    }
};

export const updateSubscriptionOnIdThunk = async (
    updateSubscriptionURl,
    subscriptionData,
    addFeaturesToSubscriptionUrl,
    featuredData,
    thunkAPI
) => {
    try {
        const subscriptionResponse = await makeApiCall('put', updateSubscriptionURl, subscriptionData);
        return await handleSubscriptionUpdate(
            subscriptionResponse,
            addFeaturesToSubscriptionUrl,
            featuredData,
            thunkAPI
        );
    } catch (error) {
        return handleApiError(SUPER_ADMIN_ERROR_MESSAGES.SUBCRIPTION_UPDATION_ERROR, thunkAPI);
    }
};

export const checkForExistingPlanNameThunk = async (
    checkForExistingPlanNameURL,
    thunkAPI
) => {
    try {
        return await makeApiCall('get', checkForExistingPlanNameURL);
    } catch (error) {
        return thunkAPI.rejectWithValue(SUPER_ADMIN_ERROR_MESSAGES.FAILED_TO_CHECK_PLAN_NAME);
    }
};