import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createNewDivision,
  handleCreateDivisionModal,
} from "../../features/settings/divisionSlice";

import { DIVISIONS } from "../../utils/constants/settings";

const CreateDivisionModal = ({ userAdminId, allDivisions }) => {
  const [divisionData, setDivisionData] = useState({
    adminId: userAdminId,
    divisionName: "",
    divisionStatus: true,
    biometricSerialNo: "",
  });
  const [activeDivisons, setActiveDivisions] = useState([]);
  const [modalError, setModalError] = useState({
    divisionName: "",
    biometricSerialNo: "",
  });

  const dispatch = useDispatch();

  const createActiveDivisions = () => {
    DIVISIONS.forEach((eachDivision) => {
      allDivisions.forEach(({ divisionName }) => {
        if (eachDivision.name == divisionName) {
          eachDivision.disabled = true;
        }
      });
    });
    return DIVISIONS;
  };

  useEffect(() => {
    const divisions = createActiveDivisions();
    setActiveDivisions(divisions);
  }, [allDivisions]);

  const captureDivisionValues = (e) => {
    setModalError({
      ...modalError,
      [e.target.name]: "",
    });
    setDivisionData({
      ...divisionData,
      [e.target.name]: e.target.value,
    });
  };

  const handlecreateNewDivision = () => {
    if (!divisionData.biometricSerialNo || !divisionData.divisionName)
      setModalError({
        divisionName: !divisionData.divisionName ? "None Selected" : "",
        biometricSerialNo: !divisionData.biometricSerialNo
          ? "Required Field"
          : "",
      });
    if (divisionData.biometricSerialNo && divisionData.divisionName) {
      //dispatch
      dispatch(createNewDivision(divisionData));
      dispatch(handleCreateDivisionModal(false));
    }
  };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto" onClick={() => dispatch(handleCreateDivisionModal(false))}>
      <div className="flex items-center justify-center min-h-full px-4 pt-12 text-left">
        {/* Modal Background */}
        <div className="fixed inset-0 bg-black opacity-50"></div>

        {/* Modal Content */}
        <div className="relative bg-white rounded-lg p-8 max-w-4xl w-full md:w-2/3 lg:w-1/2" onClick={(e) => e.stopPropagation()}>
          {/* Heading */}
          <button
            onClick={() => dispatch(handleCreateDivisionModal(false))}
            className="absolute top-4 right-4 p-2 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h2 className="text-lg font-semibold mb-6 text-left">
            Create Division
          </h2>

          {/* First Row - Select Dropdown */}
          <div className="mb-8 relative">
            <label
              htmlFor="divisionName"
              className="block text-sm font-medium text-gray-700"
            >
              Division Name
            </label>
            <select
              onChange={captureDivisionValues}
              id="divisionName"
              name="divisionName"
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-white rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select Division</option>

              {activeDivisons.map(({ name, disabled }, i) => {
                return (
                  <option key={i} disabled={disabled} value={name}>
                    {name}
                  </option>
                );
              })}
            </select>
            {modalError.divisionName && (
              <p className="mt-2 text-sm text-red-600">
                {modalError.divisionName}
              </p>
            )}
          </div>

          {/* Second Row - Input */}
          <div className="mb-8">
            <label
              htmlFor="biometricSerialNo"
              className="block text-sm font-medium text-gray-700"
            >
              Biometric Serial Number
            </label>
            <input
              onChange={captureDivisionValues}
              value={divisionData.biometricSerialNo}
              type="text"
              id="biometricSerialNo"
              name="biometricSerialNo"
              className="mt-1 block w-full py-3 px-4 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            {modalError.biometricSerialNo && (
              <p className="mt-2 text-sm text-red-600">
                {modalError.biometricSerialNo}
              </p>
            )}
          </div>

          {/* Buttons */}
          <div className="flex justify-end">
            <button
              onClick={() => dispatch(handleCreateDivisionModal(false))}
              className="mr-4 px-6 py-3 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
            >
              Cancel
            </button>
            <button
              onClick={handlecreateNewDivision}
              className="px-6 py-3 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDivisionModal;
