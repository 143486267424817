import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAdmin";

import { MEMBERSHIP_MODULE } from "../../utils/axios/pathUrls";
import { SUCCESS_MESSAGES } from "../../utils/constants/Prompts";

const initialState = {
  isLoading: false,
  currentPageNo: 0,
  packageLists: [],
  package: null,
  currentPackageData: null,
  isPackageModalOpen: false,
  activePackages: [],
};

export const getPackages = createAsyncThunk(
  "package/getPackages",
  async ({ adminId, pageNo, pageItems }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${MEMBERSHIP_MODULE.API_PACKAGE_MODULE.GET_PACKAGES}/${adminId}?page=${pageNo}&size=${pageItems}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch the Packages data");
    }
  }
);

export const createPackage = createAsyncThunk(
  "package/createPackage",
  async ({ packageData, packageRefetch }, thunkAPI) => {
    try {
      const response = await customFetch.post(
        MEMBERSHIP_MODULE.API_PACKAGE_MODULE.POST_PACKAGE,
        packageData
      );
      thunkAPI.dispatch(getPackages(packageRefetch));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to create package please try later"
      );
    }
  }
);

export const updatePackage = createAsyncThunk(
  "package/updatePackage",
  async ({ packageId, packageData, packageRefetch }, thunkAPI) => {
    try {
      const response = await customFetch.put(
        `${MEMBERSHIP_MODULE.API_PACKAGE_MODULE.UPDATE_PACKAGE}${packageId}`,
        packageData
      );
      thunkAPI.dispatch(getPackages(packageRefetch));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to update package");
    }
  }
);

export const getPackageById = createAsyncThunk(
  "package/getPackageById",
  async ({ packageId }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${MEMBERSHIP_MODULE.API_PACKAGE_MODULE.GET_BY_ID}${packageId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch package");
    }
  }
);

export const getAllActivePackages = createAsyncThunk(
  "package/getAllActivePackages",
  async ({ adminId }, thunkAPI) => {
    try {
      const response = await customFetch.get(
        `${MEMBERSHIP_MODULE.API_PACKAGE_MODULE.GET_ALL_ACTIVE_PACKAGES}${adminId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Unable to fetch active packages");
    }
  }
);

const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    openCreatePackageModal: (state, { payload }) => {
      state.isPackageModalOpen = payload.isOpen;
      state.currentPackageData = null;
    },
    openUpdatePackageModal: (state, { payload }) => {
      state.isPackageModalOpen = payload.isOpen;
      state.currentPackageData = payload.packageData || null;
      state.currentPageNo = payload.currentPage
    },
    closePackageModal: (state) => {
      state.isPackageModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPackages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPackages.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.packageLists = payload;
      })
      .addCase(getPackages.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(createPackage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPackage.fulfilled, (state, { payload }) => {
        state.packageLists.content?.unshift(payload);
        state.isLoading = false;
        toast.success("Package is created successfully");
      })
      .addCase(createPackage.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(updatePackage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePackage.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(SUCCESS_MESSAGES.PACKAGE_UPDATED_SUCCESS_MESSAGE);
      })
      .addCase(updatePackage.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error("updating package is failed");
      })
      .addCase(getPackageById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPackageById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.package = payload;
      })
      .addCase(getPackageById.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      })
      .addCase(getAllActivePackages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllActivePackages.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.activePackages = payload;
      })
      .addCase(getAllActivePackages.rejected, (state, { payload }) => {
        state.isLoading = false;
        // toast.error(payload);
      });
  },
});
export const {
  openCreatePackageModal,
  openUpdatePackageModal,
  closePackageModal,
} = packageSlice.actions;
export default packageSlice.reducer;
