import React from 'react';
import { Check } from 'lucide-react';

export default function SubscriptionCard({ duration, price, planName,features }) {
    return (
        <div className="w-full max-w-sm ring-1 ring-gray-300 rounded-lg p-8 xl:p-10">
            <div className="flex flex-col items-center justify-center gap-x-4">
                <h3 className="text-gray-900 text-2xl font-semibold leading-8">
                    {planName}
                </h3>
                <p className=" text-sm leading-6 text-center text-gray-600">
                {duration}{ duration>1? " Months":" Month"}
            </p>
            </div>
            <p className="mt-4 text-sm leading-6 text-center text-gray-600 line-through">
                ${price.basePrice}
            </p>
            <p className=" text-xl leading-6 text-center text-gray-600">
                ${price.finalPrice}
            </p>
            <a href='#' className="mt-6 block rounded-md px-3 py-4 text-orange-600 text-center text-lg font-semibold leading-6">
                Plan Details
            </a>
            <ul role="list" className="mt-8 space-y-3 text-md leading-6 text-gray-600 xl:mt-10">
                {features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                        <Check className="h-6 w-5 flex-none text-indigo-600" />
                        {feature.name}
                    </li>
                ))}
            </ul>
        </div>
    );
}