import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getGCByDivisionName,
  getPTByDivisionName,
  getPackageByDivisionName,
  getSelectedPackageData,
  handleCalculation,
  setInitialUpgradeBillingValues,
  togglingCheckBox,
} from "../../../features/people/upgradeMembershipSlice";
import moment from "moment/moment";
import UpgradeBillingContainer from "./UpgradeBillingContainer";
import { VALIDATION_ERROR_MESSAGES } from "../../../utils/constants/Prompts";
import { MEMBERSHIP_NAMINGS } from "../../../utils/constants/keywords";
import { getMemberOrderDetails } from "../../../features/people/memberOrderDetailsSlice";

const UpgradeMembership = () => {
  const { state } = useLocation();
  const { user } = useSelector((store) => store.user);
  const { selectedPackageId, selectedPackageData } = useSelector(
    (store) => store.upgradeMembership
  );
  const { orderBillings } = useSelector((store) => store.memberOrderDetails);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [grandTotal, setGrandTotal] = useState(0);
  const [billingPayload, setBillingPayload] = useState(null);
  const { data, membershipData, status,membershipName } = state;
  const [discountError, setDiscountError] = useState("");
  const [discount, setDiscount] = useState(data?.discount || 0);
  const [amountRecieved, setAmountRecived] = useState(0);
  const [allMembershipData,setAllMembershipData] = useState([])
  const [filteredPackageDetail, setFilteredPackageDetail] = useState([]);
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const adminId = user.userId;

  function calculateEndDate(startDate, noOfDays) {
    const startDateSelected = startDate ? moment(startDate) : null;
    const endDate = startDateSelected
      ? startDateSelected?.add(noOfDays, "days").format("YYYY-MM-DD")
      : null;
    return endDate;
  }
  const endDate = calculateEndDate(
    data?.startDate,
    selectedPackageData?.noOfDays
  );

  const generateUpgradeMembershipPayload = (
    data,
    selectedPackageData,
    membershipName,
    adminId,
    discount,
    amountRecieved,
    endDate
  ) => {
    const gymPackageId =
      membershipName ===  MEMBERSHIP_NAMINGS.PACKAGE ? selectedPackageData?.id || "" : "";
    const groupClassId =
      membershipName === MEMBERSHIP_NAMINGS.GROUP_CLASS ? selectedPackageData?.id || "" : "";
    const personalTrainingId =
      membershipName === MEMBERSHIP_NAMINGS.PERSONAL_TRAINING
        ? selectedPackageData?.id || ""
        : "";
    const durationTime = membershipName === MEMBERSHIP_NAMINGS.GROUP_CLASS ? selectedPackageData?.classTime : selectedPackageData?.durationTime
    const durationType = membershipName === MEMBERSHIP_NAMINGS.GROUP_CLASS ? selectedPackageData?.classType : selectedPackageData?.durationType

    return {
      adminId: adminId,
      baseAmount: selectedPackageData?.cost,
      discount: discount || 0,
      divisionId: selectedPackageData?.divisionId,
      divisionName: selectedPackageData?.divisionName,
      durationTime: durationTime,
      durationType: durationType,
      endDate: endDate,
      feedback: true,
      memberId: selectedPackageData?.memberId,
      membershipName: membershipName || "",
      noOfDays: selectedPackageData?.noOfDays,
      packageBuyType: "Renewal",
      packageStatus: true,
      packageType: "",
      ratingsOne: selectedPackageData?.ratingsOne || 0,
      ratingsTwo: selectedPackageData?.ratingsTwo || 0,
      receivedAmount: amountRecieved || selectedPackageData?.cost,
      reviewsOne: selectedPackageData?.reviewsOne || "",
      reviewsTwo: selectedPackageData?.reviewsTwo || "",
      selectDay: selectedPackageData?.selectDay || "",
      session: selectedPackageData?.session || "",
      startDate: data?.startDate || "",
      startTime: selectedPackageData?.startTime || "",
      trainerId: selectedPackageData?.trainerId || "",
      trainerName: selectedPackageData?.trainerName || "",
      transferReason: "",
      transferredBy: "",
      transferredTo: "",
      gymPackageId: gymPackageId,
      groupClassId: groupClassId,
      personalTrainingId: personalTrainingId,
      previousUpgradeId:data?.id || ""
    };
  };

  const upgradeMembershipDataPayload = generateUpgradeMembershipPayload(
    data,
    selectedPackageData,
    membershipName,
    adminId,
    discount,
    amountRecieved,
    endDate
  );

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCheckboxChange = (id) => {
    dispatch(setInitialUpgradeBillingValues({}))
    dispatch(togglingCheckBox({ id }));
    setDiscount(0)
    setDiscountError('')
  };

  useEffect(()=>{
    dispatch(getMemberOrderDetails({adminId,memberId}))
    dispatch(togglingCheckBox({}));
  },[dispatch])
  
  useEffect(() => {
    dispatch(getSelectedPackageData(filteredPackageDetail));
  }, [selectedPackageId]);

  const createActionDispatcher = (membershipName, adminId, divisionName) => {
    switch (membershipName) {
      case MEMBERSHIP_NAMINGS.PACKAGE:
        return getPackageByDivisionName({ adminId, divisionName });
      case MEMBERSHIP_NAMINGS.PERSONAL_TRAINING:
        return getPTByDivisionName({ adminId, divisionName });
      case MEMBERSHIP_NAMINGS.GROUP_CLASS:
        return getGCByDivisionName({ adminId, divisionName });
      default:
        throw new Error(`Unsupported membership name: ${membershipName}`);}
  };
  
  useEffect(() => {
    const fetchData = async () => {
      if (membershipName && data?.divisionName) {
        const fetchedData = await dispatch(
          createActionDispatcher(membershipName, adminId, data?.divisionName)
        );
        
        if (Array.isArray(fetchedData?.payload))
        {
        setAllMembershipData(fetchedData?.payload);
        }
      }
    };
  
    fetchData();
  }, [dispatch, membershipName, data?.divisionName]);
  

  useEffect(() => {
    const fetchFilteredData = async()=>{
      if (allMembershipData && data?.noOfDays) {
        const filteredData = allMembershipData?.filter((item) => {
          return Number(item.noOfDays) > Number(data.noOfDays);
        });
        setFilteredPackageDetail(filteredData);
      }
    }
    fetchFilteredData()
  }, [dispatch,allMembershipData,data?.noOfDays]); // Dependencies
  
  useEffect(() => {
    dispatch(
      handleCalculation({
        totalBaseAmount: selectedPackageData?.cost,
        previousAmount: data?.memberShipData?.cost,
        discount: discount,
      })
    );
  }, [selectedPackageId, discount, selectedPackageData]);

  function handleCheckDiscount(e, totalCost, minCost, maxCost) {
    // Convert discount to a number
    setDiscount(parseFloat(e.target.value) || 0);
    const discountAmount = parseFloat(e.target.value) || 0;
    // Calculate discounted cost
    const discountedCost = totalCost - discountAmount;
    setAmountRecived(discountedCost);
    const remainingAmount = discountedCost - membershipData?.cost;
    if (discountedCost < minCost || discountedCost > maxCost) {
      // Return an error message if discounted cost is outside the range
      setDiscountError(VALIDATION_ERROR_MESSAGES.DISCOUNT_ERROR);
      setGrandTotal(remainingAmount);
      return; // Early return if discounted cost is outside the range
    }
    // If the function reaches here, it means discounted cost is within the range
    setDiscountError(""); // Clear any previous error
    setGrandTotal(remainingAmount); 
  }
  const handleUpgradeBilling = (billingData) => {
    setBillingPayload(billingData);
  };
  const membershipBillingData = orderBillings && orderBillings.find(
    (billing) => billing?.id === data?.billingId
  );
  return (
    <div className="sm:flex flex-row mx-4">
      <div className="flex flex-col w-full rounded-l-lg border border-solid bg-zinc-900 border-zinc-900 max-w-[480px] h-[1000px] overflow-y-auto">
        <div className="justify-center items-start px-6 py-7 w-full text-base font-medium tracking-wide text-indigo-50 uppercase rounded-lg border-t border-b border-gray-800 border-solid bg-zinc-900">
          Choose packages
        </div>
        <div className="flex gap-2.5 justify-between px-6 py-5 w-full whitespace-nowrap border-b border-gray-800 border-solid bg-zinc-800">
          <div className="flex gap-2 self-start">
            <div className="text-base tracking-normal text-slate-300">
              {membershipName?.toUpperCase()}
            </div>
            <div className="justify-center px-2.5 py-px text-sm font-medium leading-5 text-center bg-gray-700 rounded-[10000px] text-slate-300">
              {filteredPackageDetail?.length}
            </div>
          </div>
          <button onClick={toggleCollapse} className="mr-2">
            {/* Conditional rendering for the arrow icon */}
            {isCollapsed ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-slate-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 transform rotate-180 text-slate-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            )}
          </button>
        </div>
        {filteredPackageDetail && filteredPackageDetail?.map((packageData) => (
          <div
            key={packageData.id}
            className={`flex flex-col mt-5 w-full ${
              isCollapsed ? "hidden" : "block"
            }`}
          >
            <div className="flex gap-5 justify-between">
              <div className="flex  gap-5 justify-between px-5 text-sm">
                <div className="flex flex-col whitespace-nowrap">
                  <div className="text-gray-500">Division</div>
                  <div className="mt-1 text-slate-300">
                    {packageData.divisionName}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-gray-500">Duration</div>
                  <div className="mt-1 text-slate-300">
                  {packageData?.classTime ? packageData?.classTime : packageData?.durationTime} {packageData?.classType ? packageData?.classType : packageData?.durationType}
                  </div>
                </div>
                <div className="flex flex-col whitespace-nowrap">
                  <div className="text-gray-500">Cost</div>
                  <div className="mt-1 text-slate-300">{packageData.cost}</div>
                </div>
              </div>
              <div className="flex p-2 my-auto mr-2">
                <input
                  id="selectedPackage"
                  aria-describedby="candidates-description"
                  name="selectedPackage"
                  type="checkbox"
                  checked={selectedPackageId === packageData.id}
                  onChange={() => handleCheckboxChange(packageData.id)}
                  className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 appearance-none"
                  style={{
                    // Custom CSS to style the checkbox
                    backgroundColor: "#1E293B", // Dark fill color
                    border: "2px solid #718096", // Border color
                    borderRadius: "4px", // Border radius
                  }}
                />
              </div>
            </div>
            <div className="mt-5 w-full border border-solid bg-zinc-800 border-zinc-800 min-h-[1px]" />
          </div>
        ))}
      </div>
      <div className="w-full mt-4 sm:mt-0">
        <div className="justify-center items-start px-6 py-7 w-full text-base font-medium tracking-wide text-indigo-50 uppercase rounded-r-lg border-t border-b border-gray-800 border-solid bg-zinc-900">
          Generate Bill
        </div>
        {membershipBillingData?.balance === 0 && status && filteredPackageDetail && (
          <div className="mt-4 sm:mt-8 sm:-mx-0 p-0 sm:p-4 overflow-y-auto">
            <div className="flex flex-col md:flex-row justify-between items-start mb-4 mx-auto">
            <table className="min-w-full divide-y divide-gray-300 mx-auto">
              <thead className="bg-gray-300 pl-4">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Division
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Start Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    End Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Discount
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Cost
                  </th>
                  {/* <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Action
                </th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {selectedPackageData && (
                  <tr key={selectedPackageData.id} className="space-x-4">
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                      {membershipName}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {selectedPackageData.divisionName}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {data?.memberShipData.startDate}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {endDate}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 " style={{ maxWidth: '100px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                      <input
                        className="rounded-md w-[80px] sm:w-[100px]"
                        id="discount"
                        name="discount"
                        type="text"
                        value={discount}
                        onChange={(e) =>
                          handleCheckDiscount(
                            e,
                            selectedPackageData.cost,
                            selectedPackageData.minimumPackageCost,
                            selectedPackageData.maximumPackageCost
                          )
                        }
                      />
                      {discountError && (
                        <p className="text-red-500  text-sm">
                          {discountError}
                        </p>
                      )}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {selectedPackageData.cost}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            </div>
            {selectedPackageId && (
              <UpgradeBillingContainer
                grandTotal={grandTotal}
                adminId={data?.adminId}
                memberId={memberId}
                handleUpgradeBilling={handleUpgradeBilling}
                upgradeMembershipDataPayload={upgradeMembershipDataPayload}
                discountError={discountError}
                setDiscountError={setDiscountError}
              />
            )}
          </div>
        )}
        {!status && (
          <div className="mx-3">
            <div className="mt-6 mx-auto flex items-center justify-center space-x-4 divide-gray-200 border-t border-gray-200 pt-4 text-xl font-normal sm:my-12 sm:border-none sm:pt-0 text-center text-gray-800">
              <div>Upgrade is not allowed for this membership.</div>
            </div>
          </div>
        )}
        {status && filteredPackageDetail?.length === 0 && (
          <div className="mx-3">
            <div className="mt-6 mx-auto flex items-center justify-center space-x-4 divide-gray-200 border-t border-gray-200 pt-4 text-xl font-normal sm:my-12 sm:border-none sm:pt-0 text-center text-gray-800">
              <div>No packages with a greater duration are available to upgrade membership.</div>
            </div>
          </div>
        )}
        {status && filteredPackageDetail?.length !== 0 && membershipBillingData?.balance !== 0 && (
          <div className="mx-3">
            <div className="mt-6 mx-auto flex items-center justify-center space-x-4 divide-gray-200 border-t border-gray-200 pt-4 text-xl font-normal sm:my-12 sm:border-none sm:pt-0 text-center text-gray-800">
              <div>
                Please pay the due amount in order to upgrade the membership.
              </div>
            </div>
          </div>
        )}
        {/* <div className="mt-5 w-full border border-solid bg-zinc-800 border-zinc-800 min-h-[1px]" /> */}
      </div>
    </div>
  );
};

export default UpgradeMembership;
