import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE } from '../../utils/axios/pathUrls'
import { getMemberAttendanceDetailsThunk, getMemberDetailsThunk } from './peopleThunk'

const initialState = {
  isLoading: false,
  memberDetails: [],
  imgSrc: null,
  memberStatus: null,
  memberAttendanceData: {}
}

export const getMemberDetails = createAsyncThunk(
  'memberDetails/getmemberDetails',
  async (memberId, thunkAPI) => {
    try {
      const response = await getMemberDetailsThunk(
        `${API_PEOPLE_MODULE.API_MEMBER_MODULE.GET_MEMBER_DETAILS}${memberId}`,
        thunkAPI
      )
      return response
    } catch (error) {
      throw error.message
    }
  }
)

export const getMemberAttendanceDetails = createAsyncThunk(
  'memberDetails/getMemberAttendanceDetails',
  async ({ adminId, memberId, startDate, endDate }, thunkAPI) => {
    try {
      const response = await getMemberAttendanceDetailsThunk(
        `${API_PEOPLE_MODULE.API_MEMBER_MODULE.GET_MEMBER_ATTENDENCE_DATA}${adminId}/memberId/${memberId}/startDate/${startDate}/endDate/${endDate}`,
        thunkAPI
      )
      return response
    } catch (error) {
      throw error.message
    }
  }
)

const memberDetailsSlice = createSlice({
  name: 'memberDetails',
  initialState,
  reducers: {
    setMemberData: (state, { payload }) => {
      state.imgSrc = payload.imgSrc
      state.memberStatus = payload.memberStatus
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMemberDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMemberDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.memberDetails = payload
      })
      .addCase(getMemberDetails.rejected, (state, { payload }) => {
        state.isLoading = false
        state.memberDetails = []
        // toast.error(payload)
      })
      .addCase(getMemberAttendanceDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMemberAttendanceDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.memberAttendanceData = payload
      })
      .addCase(getMemberAttendanceDetails.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
  },
})

export const { setMemberData } = memberDetailsSlice.actions;

export default memberDetailsSlice.reducer
