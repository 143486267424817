import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SUPERADMIN } from "../../utils/constants/keywords";

const SettingsTabbar = () => {
  const [activeTab, setActiveTab] = useState(SUPERADMIN.TABBAR.CHANGE_PASSWORD);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.endsWith("/superadmin/settings") &&
      location.pathname !== "changepassword"
    ) {
      navigate("changepassword", { replace: true });
      setActiveTab(SUPERADMIN.TABBAR.CHANGE_PASSWORD);
    }
    if (location.pathname.endsWith("/changepassword")) {
      navigate("changepassword", { replace: true });
      setActiveTab(SUPERADMIN.TABBAR.CHANGE_PASSWORD);
    }
    if (location.pathname.endsWith("/feature")) {
      navigate("feature", { replace: true });
      setActiveTab(SUPERADMIN.TABBAR.FEATURES);
    }
  }, [location.pathname, navigate, activeTab]);

  return (
    <>
      <div className="w-full  bg-zinc-900 border border-zinc-800 rounded-lg p-4 h-screen overflow-y-auto">
        <div className="flex space-x-4 text-white  border-b border-gray-600">
          <button
            onClick={() => {
              setActiveTab(SUPERADMIN.TABBAR.CHANGE_PASSWORD);
              navigate("changepassword");
            }}
            className={`px-4 py-2 ${
              activeTab === SUPERADMIN.TABBAR.CHANGE_PASSWORD
                ? "text-blue-400  border-b border-blue-400"
                : "text-gray-600"
            }`}
          >
            Change Password
          </button>
          <button
            onClick={() => {
              setActiveTab(SUPERADMIN.TABBAR.FEATURES);
              navigate("feature");
            }}
            className={`px-4 py-2 ${
              activeTab === SUPERADMIN.TABBAR.FEATURES
                ? "text-blue-400  border-b border-blue-400"
                : "text-gray-600"
            }`}
          >
            Features
          </button>
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default SettingsTabbar;
