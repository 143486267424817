import React, { useState } from 'react';
import { Switch } from '@headlessui/react';

import { ACTION_LABELS, MISCELLANEOUS_LABELS, STATUS_TYPES } from '../../utils/constants/keywords';
import GenericDialogBox from './GenericDialogBox';
import { TrashIcon } from '@heroicons/react/24/outline';

const GenericTable = ({
    columns,
    data,
    onToggleChange = () => { },
    onRowClick = () => { },
    onViewClick = () => { },
}) => {


    return (
        <>
            {<div className="mt-6 flow-root">
                <div className="-mx-2 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-t-lg">
                            <table className="min-w-full">
                                <thead className="bg-zinc-800">
                                    <tr>
                                        {columns.map((column, index) => (
                                            <th
                                                key={column.key}
                                                scope="col"
                                                className="py-4 pl-4 pr-3 text-left text-xs font-semibold text-white sm:pl-6"
                                            >
                                                {column.header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-zinc-800 bg-zinc-900">
                                    {data.map((row, rowIndex) => (
                                        <tr key={rowIndex} onClick={() => onRowClick(row)} className='cursor-pointer'>
                                            {columns.map((column, colIndex) => {
                                                if (column.key === STATUS_TYPES.ISACTIVE && column.type === MISCELLANEOUS_LABELS.TOGGLE) {
                                                    return (
                                                        <td key={colIndex} className="whitespace-nowrap py-4 pl-4 pr-3 font-inter text-sm leading-[21px] text-white sm:pl-6" onClick={(e) => e.stopPropagation()}>
                                                            <Switch.Group as="div" className="flex items-center">
                                                                <Switch
                                                                    checked={row[column.key]}
                                                                    onClick={() => onToggleChange(row)}
                                                                    className={`group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent ${row[column.key] ? 'bg-indigo-600' : 'bg-gray-500'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                                                >
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className={`pointer-events-none inline-block h-4 w-4 transform rounded-full bg-gray-300 shadow ring-0 transition duration-200 ease-in-out ${row[column.key] ? 'translate-x-5' : 'translate-x-0'}`}
                                                                    />
                                                                </Switch>
                                                                <Switch.Label className="ml-3 text-sm">
                                                                    <span className="font-medium text-white">{row[column.key] ? STATUS_TYPES.ACTIVE : STATUS_TYPES.INACTIVE}</span>
                                                                </Switch.Label>
                                                            </Switch.Group>
                                                        </td>
                                                    );
                                                }
                                                if (column.key === ACTION_LABELS.VIEW && column.type === ACTION_LABELS.VIEW) {
                                                    return (
                                                        <td key={colIndex} onClick={() => onViewClick(row)} className="whitespace-nowrap py-4 pl-4 pr-3 font-inter text-sm leading-[21px] text-white sm:pl-6">
                                                            <div className="flex items-center">
                                                                <a href="#" className="text-orange-600 hover:text-orange-600 underline justify-center items-center" onClick={(e) => e.preventDefault()}>
                                                                    View<span className="sr-only"></span>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    );
                                                }


                                                if (column.key === 'action' && column.type === 'delete') {
                                                    return (
                                                        <td key={colIndex} className="whitespace-nowrap py-4 pl-4 pr-3 font-inter text-sm leading-[21px] text-white sm:pl-6" onClick={(e) => e.stopPropagation()}>
                                                            <TrashIcon className='w-5 h-5' />
                                                        </td>
                                                    );
                                                }

                                                return (
                                                    <td
                                                        key={colIndex}
                                                        className="whitespace-nowrap py-4 pl-4 pr-3 font-inter text-sm leading-[21px] text-white sm:pl-6"
                                                    >
                                                        {column.render ? column.render(row[column.key], row) : row[column.key]}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default GenericTable;