import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE, MARKETING_MODULE } from '../../utils/axios/pathUrls'
import { getMemberAttendanceDataThunk, sendAttendanceReportThunk } from './attendanceThunk'


const initialState = {
    isLoading: false,
    attendanceData: []
}

export const getMemberAttendanceData = createAsyncThunk(
    'attendance/getMemberAttendanceData',
    async ({ adminId, memberId, startDate, endDate }, thunkAPI) => {
        try {
            const response = await getMemberAttendanceDataThunk(
                `${API_PEOPLE_MODULE.ATTENDANCE_MODULE.GET_MEMBER_ATTENDANCE_DATA}/${adminId}/members/${memberId}?start_date=${startDate}&end_date=${endDate}`,
                thunkAPI
            )
            return response
        } catch (error) {
            throw error.message
        }
    }
)

export const sendAttendanceReportViaWhatsapp = createAsyncThunk(
    'attendance/sendAttendanceReportViaWhatsapp',
    async (formData, thunkAPI) => {
        try {
            const response = await sendAttendanceReportThunk(
                MARKETING_MODULE.SEND_ATTENDANCE_REPORT_THROUGH_WHATSAPP,
                formData,
                thunkAPI
            )
            return response
        } catch (error) {
            throw error.message
        }
    }
)

const attendanceSlice = createSlice({
    name: 'attendance',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getMemberAttendanceData.pending, (state) => {
                state.isLoading = true
                state.attendanceData = []
            })
            .addCase(getMemberAttendanceData.fulfilled, (state, { payload }) => {

                console.log(payload);

                state.isLoading = false
                state.attendanceData = payload
                // toast.success(payload)
            })
            .addCase(getMemberAttendanceData.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload)
                state.attendanceData = []
            })
            .addCase(sendAttendanceReportViaWhatsapp.pending, (state) => {
                state.isLoading = true
            })
            .addCase(sendAttendanceReportViaWhatsapp.fulfilled, (state, { payload }) => {
                state.isLoading = false
                toast.success(payload)
            })
            .addCase(sendAttendanceReportViaWhatsapp.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload)
            })
    },
})

export default attendanceSlice.reducer
