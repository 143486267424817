import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { API_PEOPLE_MODULE, MARKETING_MODULE } from '../../../utils/axios/pathUrls'
import { checkForExistingMembershipThunk, postNewMembershipPurchaseRecordThunk, postRenewMembershipPurchaseRecordThunk, sendInvoiceThunk } from './gymMembershipPurchaseThunk'
import { CONSTANTS, MEMBERSHIP_NAMINGS } from '../../../utils/constants/keywords'
import { VALIDATION_ERROR_MESSAGES } from '../../../utils/constants/Prompts'

const initialState = {
  isLoading: false,
  activeTab: 'Offers',
  openCartModal: false,
  openBillingModal: false,
  cart: [],
  offerCart: [],
  existingMembershipEndDate: '',
  totalBaseAmount: 0,
  totalDiscount: 0,
  subTotal: 0,
  totalOfSubTotal: 0,
  membershipFee: 0,
  gstAmount: 0,
  grandTotalAmount: 0,
  cashAmount: 0,
  amountReceived: 0,
  balance: 0,
  cashAmountError: '',
  paymentType: '',
  dueDate: '',
  membershipFeeEnabled: false,
  gstEnabled: false

}

export const sendInvoice = createAsyncThunk(
  'gymMembershipPurchase/sendInvoice',
  async (formData, thunkAPI) => {
    try {
      const response = await sendInvoiceThunk(
        MARKETING_MODULE.SEND_INVOICE_THROUGH_EMAIL_WHATSAPP,
        formData,
        thunkAPI
      )
      return response
    } catch (error) {
      throw error.message
    }
  }
)

export const checkForExistingMembership = createAsyncThunk(
  'gymMembershipPurchase/checkForExistingMembership',
  async ({ adminId, memberId, membership }, thunkAPI) => {
    try {
      const response = await checkForExistingMembershipThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.CHECK_FOR_EXISTING_MEMBERSHIP}${adminId}/memberId/${memberId}/divisionId/${membership.divisionId}`,
        thunkAPI
      )
      return { response, membership }
    } catch (error) {
      throw error.message
    }
  }
)

export const postNewMembershipPurchaseRecords = createAsyncThunk(
  'gymMembershipPurchase/postNewMembershipPurchaseRecords',
  async ({ adminId, memberId, membershipPurchaseBillingData, membershipData }, thunkAPI) => {
    try {
      const response = await postNewMembershipPurchaseRecordThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.POST_MEMBER_CREATION_DATA}`,
        membershipPurchaseBillingData,
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.POST_MEMBERSHIP_PURCHASE_DATA_ON_ADMIN_ID_MEMBER_ID}${adminId}/memberId/${memberId}`,
        membershipData,
        thunkAPI
      )
      return response;
    } catch (error) {
      throw error.message
    }
  }
)

export const postRenewMembershipPurchaseRecords = createAsyncThunk(
  'gymMembershipPurchase/postRenewMembershipPurchaseRecords',
  async ({ adminId, memberId, membershipPurchaseBillingData, membershipData }, thunkAPI) => {
    try {
      const response = await postRenewMembershipPurchaseRecordThunk(
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.API_BILLING_MODULE.POST_MEMBERSHIP_BILLING_ON_ADMIN_ID_MEMBER_ID}${adminId}/memberId/${memberId}`,
        membershipPurchaseBillingData,
        `${API_PEOPLE_MODULE.MEMBERSHIP_PURCHASE_MODULE.POST_MEMBERSHIP_PURCHASE_DATA_ON_ADMIN_ID_MEMBER_ID}${adminId}/memberId/${memberId}`,
        membershipData,
        thunkAPI
      )
      return response;
    } catch (error) {
      throw error.message
    }
  }
)

const gymMembershipPurchaseSlice = createSlice({
  name: 'gymMembershipPurchase',
  initialState,
  reducers: {
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload
    },
    setOpenCartModal: (state, { payload }) => {
      state.openCartModal = payload
    },
    setOpenBillingModal: (state, { payload }) => {
      state.openBillingModal = payload
    },
    setCart: (state, { payload }) => {
      state.cart = payload
    },
    setOfferCart: (state, { payload }) => {
      state.offerCart = payload
    },
    setInitialValues: (state, { payload }) => {
      state.totalBaseAmount = 0;
      state.totalDiscount = 0;
      state.subTotal = 0;
      state.totalOfSubTotal = 0;
      state.membershipFee = 0;
      state.gstAmount = 0;
      state.grandTotalAmount = 0;
      state.cashAmount = 0;
      state.amountReceived = 0;
      state.balance = 0;
      state.cashAmountError = '';
      state.paymentType = '';
      state.dueDate = ''
      state.membershipFeeEnabled = false
      state.gstEnabled = false
    },
    handleCalculation: (state, { payload }) => {
      if (payload.length > 0) {
        state.totalBaseAmount = 0;
        state.totalDiscount = 0;
        state.subTotal = 0;

        payload.forEach(item => {
          state.totalBaseAmount += Number(item.cost);
          state.totalDiscount += Number(item.discount);
          state.subTotal += Number(item.totalAmount);
        });
        state.totalOfSubTotal = state.subTotal
        state.grandTotalAmount = state.totalOfSubTotal;

        if (Number(state.cashAmount) <= Number(state.grandTotalAmount)) {
          state.balance = ((Number(state.subTotal) - Number(state.amountReceived) + Number(state.gstAmount) + Number(state.membershipFee)).toFixed(2))
        }
        else {
          state.balance = 0;
        }
      }
    },
    calculateGrandTotal: (state, { payload }) => {
      let subtotal = state.subTotal;

      if (payload.isMembershipFeeChecked && payload.membershipFee) {
        subtotal += parseFloat(payload.membershipFee);
        state.membershipFee = payload.membershipFee
      }
      else {
        state.membershipFee = 0;
      }

      if (payload.isGstChecked && payload.gstPercentage) {
        state.gstAmount = (parseFloat((Number(payload.gstPercentage) / 100) * subtotal)).toFixed(2);
        subtotal += parseFloat((Number(payload.gstPercentage) / 100) * subtotal);
      }
      else {
        state.gstAmount = 0;
      }

      if (isNaN(subtotal)) {
        state.grandTotalAmount = 0;
        return;
      }

      state.grandTotalAmount = (subtotal.toFixed(2));

      if (!state.cashAmount) {
        return;
      }

      state.balance = (state.grandTotalAmount - state.cashAmount).toFixed(2);

      if (Number(state.balance) > 0 && !state.dueDate) {
        state.balanceError = VALIDATION_ERROR_MESSAGES.DUE_DATE_REQUIRED_FOR_BALANCE;
      }
    },
    handleBillingCalculation: (state, { payload }) => {
      const inputValue = payload.cashAmount;
      const numericInput = inputValue.replace(/[^0-9.]/g, '')

      if (numericInput !== inputValue) {
        state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_SHOULD_BE_NUMBER
      } else if (numericInput === '' || numericInput === 0) {
        state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_REQUIRED
        state.cashAmount = 0
        state.amountReceived = 0
        state.balance = 0
        state.cashAmountError = ''
      } else {
        const numericValue = parseFloat(numericInput);
        if (!isNaN(numericValue) && numericValue <= state.grandTotalAmount) {
          state.cashAmount = numericValue.toFixed(2);
          state.amountReceived = numericValue.toFixed(2);
          state.balance = (state.grandTotalAmount - numericValue.toFixed(2)).toFixed(2);
          state.cashAmountError = ''
        } else {
          state.cashAmountError = VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_VALIDATATION
          state.cashAmount = numericValue.toFixed(2)
          state.amountReceived = 0;
          state.balance = 0;
        }
      }
    },
    setPaymentType: (state, { payload }) => {
      state.paymentType = payload
    },
    setDueDate: (state, { payload }) => {
      state.dueDate = payload
    },
    validateCashAmount: (state, { payload }) => {
      const getCashAmountError = (cashAmount, grandTotalAmount) => {
        if (cashAmount === 0) {
          return VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_REQUIRED;
        }
        if (cashAmount > grandTotalAmount) {
          return VALIDATION_ERROR_MESSAGES.CASH_AMOUNT_VALIDATATION;
        }
        return '';
      }

      state.cashAmountError = getCashAmountError(Number(payload), Number(state.grandTotalAmount));
    },
    setMembershipFeeEnabled: (state, { payload }) => {
      state.membershipFeeEnabled = payload
    },
    setGstEnabled: (state, { payload }) => {
      state.gstEnabled = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkForExistingMembership.pending, (state) => {
        state.isLoading = true
      })
      .addCase(checkForExistingMembership.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { response, membership } = payload;

        if (response.length > 0) {
          let responseData = response;
          responseData.forEach(item => {
            const isPackage = item.packageType === MEMBERSHIP_NAMINGS.PACKAGE.charAt(0).toUpperCase() + MEMBERSHIP_NAMINGS.PACKAGE.substring(1);
            const isPT = item.packageType === MEMBERSHIP_NAMINGS.PT.toUpperCase();
            const isGC = item.packageType === MEMBERSHIP_NAMINGS.GC.toUpperCase();

            const isMatchingPackage = isPackage && membership.packageId !== '' && membership.ptId === '' && membership.gcId === '';
            const isMatchingPT = isPT && membership.packageId === '' && membership.ptId !== '' && membership.gcId === '';
            const isMatchingGC = isGC && membership.packageId === '' && membership.ptId === '' && membership.gcId !== '';

            if (isMatchingPackage || isMatchingPT || isMatchingGC) {
              state.existingMembershipEndDate = item.enddate;
            }
          });
        }
        else {
          state.existingMembershipEndDate = '';
        }
      })
      .addCase(checkForExistingMembership.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(postNewMembershipPurchaseRecords.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postNewMembershipPurchaseRecords.fulfilled, (state, { payload }) => {
        state.isLoading = false
        toast.success(payload.message)
      })
      .addCase(postNewMembershipPurchaseRecords.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(postRenewMembershipPurchaseRecords.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postRenewMembershipPurchaseRecords.fulfilled, (state, { payload }) => {
        state.isLoading = false
        toast.success(payload.message)
      })
      .addCase(postRenewMembershipPurchaseRecords.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
      .addCase(sendInvoice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendInvoice.fulfilled, (state, { payload }) => {
        state.isLoading = false
        toast.success(payload)
      })
      .addCase(sendInvoice.rejected, (state, { payload }) => {
        state.isLoading = false
        // toast.error(payload)
      })
  },
})

export const { setActiveTab, setCart, setOfferCart, setOpenCartModal, setOpenBillingModal, setInitialValues, handleCalculation, calculateGrandTotal, handleBillingCalculation, setPaymentType, setDueDate, validateCashAmount, setMembershipFeeEnabled, setGstEnabled } = gymMembershipPurchaseSlice.actions
export default gymMembershipPurchaseSlice.reducer
