// Subscription.js
import React, { useState,useEffect } from 'react';
import createPaymentService from '../../utils/services/PaymentService';
// import { subscriptionFeatures } from './features';

import OrderSummary from '../../components/adminSubscriptions/SubscriptionOrderSummary';
import Toast from '../../components/adminSubscriptions/Toast';

import SubscriptionCard from '../../components/adminSubscriptions/SubscriptionCard';
import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import { getSubscriptionById,buySubscription,getLatestSubscription } from "../../features/subscription/subscriptionslice"
import { formatDateToISO,subscriptionEndDate,subscriptionEndDateForTheDate} from "../../utils/formatter/dateFormatter";
import { last } from 'lodash';
import { useNavigate } from "react-router-dom";




const paymentService = createPaymentService({
    apiKey: process.env.REACT_APP_RAZORPAY_KEY_ID,
    baseURL: process.env.REACT_APP_API_URL
});



export default function BuySubscription() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [toastMessage, setToastMessage] = useState(null);
    const dispatch = useDispatch()
    const { individualSub,latestEndDate } = useSelector((store) => store.subscriptions)
    const { user,subscriptionStatus } = useSelector((store) => store.user)
    const [isGSTEnable, setIsGSTEnable] = useState(false)
    const [paymentInfo,setPaymentInfo] = useState({
        subTotal:Number( 0),
        gst:Number(0),totalAmount:Number(0)
    })

    const calculateDates = (latestEndDate, months) => {
        
        console.log("lastEndDate",latestEndDate)

        const formatedLatestEndDate = new Date(latestEndDate);
        console.log("formatedLatestEndDate",formatedLatestEndDate)
        if (formatedLatestEndDate < new Date()) {

            return { startDate: formatDateToISO(new Date()), endDate: subscriptionEndDate(months) }

        } else if (formatedLatestEndDate >= new Date()) {

            const nextDate = new Date(formatedLatestEndDate.getTime() + 24 * 60 * 60 * 1000);
            return { startDate: formatDateToISO(nextDate), endDate: subscriptionEndDateForTheDate(nextDate, months) }

        }     
    }


    useEffect(() => {
        setPaymentInfo({...paymentInfo,subTotal:Number(individualSub.finalPrice),totalAmount:Number(individualSub.finalPrice)
        })
    },[individualSub])
    

    const handleGST =  () => { 
         setIsGSTEnable(!isGSTEnable)
        if (!isGSTEnable) {
            const gstAmount = (parseFloat((Number(18) / 100) * paymentInfo.subTotal)).toFixed(2);
            setPaymentInfo({...paymentInfo, gst: gstAmount,totalAmount:paymentInfo.totalAmount+Number(gstAmount)});
        }
        else {        
            setPaymentInfo({...paymentInfo, gst: 0,totalAmount:individualSub.finalPrice})
          }
    }
    

    const { id } = useParams();
    useEffect(() => { 
        dispatch(getSubscriptionById(id))
        dispatch(getLatestSubscription((user.userId)))
        
    },[])

    const showToast = (message, type) => {
        setToastMessage({ message, type });
        setTimeout(() => setToastMessage(null), 3000);
    };


    const handlePayment = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const sdkLoaded = await paymentService.loadRazorpayScript();
            if (!sdkLoaded) {
                throw new Error('Failed to load Razorpay SDK');
            }


            const data = {
                subTotal: paymentInfo.subTotal*1,
                gstAmount: paymentInfo.gstAmount*1,
                totalAmount: paymentInfo.totalAmount*1,
                userId: user.userId ,
                userName: user.mobileNo,
                individualProducts: [{...individualSub,
                    userDetails: {...user
                    }
                }
                ],
                subscription: null
            }

            const orderData = await paymentService.createOrder(data);
            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                amount: orderData.amount,
                currency: 'INR',
                name: user.username,
                description: 'Subscription Payment',
                order_id: orderData.orderId,
                prefill: {
                    name: user.username,
                    email: '',
                    contact: user.mobileNo
                },
                handler: async function (response) {
                    try {
                        setIsLoading(true);
                        console.log(response);

                        if (!response.razorpay_payment_id && !response.razorpay_order_id && !response.razorpay_signature) {
                        

                            return showToast('Unable to make payment,Please try after some time', 'error');
                        }

                        const paymentStatus = await paymentService.verifyPayment({
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature
                        });
                        

                        if (
                            paymentStatus.status === "success" && paymentStatus.paymentStatus === "captured"
                        ) { 
                            console.log('Payment verified', paymentStatus);
                            const { startDate, endDate } = calculateDates(latestEndDate, individualSub.numberOfMonths);
                            const payloadforbacked= {             
                                  
                                    "subscriptionId": individualSub.id,
                                    "startDate": startDate,
                                    "endDate": endDate,
                                    "status": "ACTIVE",
                                    "isActive": true,
                                    "purchaseType": "FIRST_TIME"
                                 
                            }    
                            
                            console.log("payloadforbacked",payloadforbacked)

                            const data = dispatch(buySubscription({ id: subscriptionStatus.id, payload: payloadforbacked }))
                            showToast('Payment susccessfull Subscription activated', 'success');
                            navigate("/subscriptions/plans");
                        }
                        


                        
                    } catch (error) {
                        setError('Payment verification failed. Please contact support.');
                        showToast('Payment verification failed', 'error');
                    } finally {
                        setIsLoading(false);
                    }
                },
                modal: {
                    ondismiss: function () {
                        setIsLoading(false);
                    }
                },
                theme: {
                    color: '#4F46E5'
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            setError(error.message);
            showToast(error.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const orderSummary = {
        subtotal: 99.00,
        shipping: 5.00,
        tax: 8.32,
        total: 112.32
    };

    

    return (
        <>
            <div className="grid mx-2 lg:mx-auto mt-6 max-w-screen-md grid-cols-1 lg:grid-cols-2">
                <SubscriptionCard
                    duration={individualSub.numberOfMonths}
                    planName={individualSub.planName}
                    price={{ basePrice: individualSub.basePrice, finalPrice: individualSub.finalPrice,gst:18 }}
                    features={individualSub.features||[]}
                />

                <div className="w-full max-w-xl rounded-lg p-8 xl:p-1">
                    <OrderSummary
                        amountDetails={paymentInfo}                        
                        onPaymentClick={handlePayment}
                        handleGST={handleGST }
                        isLoading={isLoading}
                        error={error}
                    />
                </div>
            </div>

            {toastMessage && (
                <Toast
                    message={toastMessage.message}
                    type={toastMessage.type}
                    onClose={() => setToastMessage(null)}
                />
            )}
        </>
    );
}