/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { useDispatch, useSelector } from "react-redux";
import DashboardDurationDropdown from "./DashboardDurationDropdown";
import DashboardPaymentTypeChart from "./DashboardPaymentTypeChart";
import {
  getPaymentTypeDataByDays,
  getPaymentTypeDataByMonths,
  getPaymentTypeDataByYears,
} from "../../features/dashboard/dashboardChartSlice";
import { useEffect, useState } from "react";
import { PERIOD_FILTERS } from "../../utils/constants/keywords";

export default function DashboardPaymentTypeChartContainer({
  dateRange,
}) {
  const [chartTypeData, setChartTypeData] = useState([]);
  const [valueSelected, setValueSelected] = useState(PERIOD_FILTERS.SEVEN_DAYS);
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const adminId = user?.userId;
  const { paymentTypeDataByDays, paymentTypeDataByWeeks } = useSelector(
    (store) => store.dashboardChart
  );
  const dateFormatApi = (value) => {
    const data = new Date(value);
    const date = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = data.getFullYear();
    return `${year}-${month}-${date}`;
  };

  const totalCash =
    (chartTypeData && chartTypeData.length>0) ?
    chartTypeData.reduce((acc, item) => acc + item.total_cash, 0):0;
  const totalUpi =
    (chartTypeData && chartTypeData.length>0) ?
    chartTypeData.reduce((acc, item) => acc + item.total_upi, 0):0;
  const totalCard =
    (chartTypeData && chartTypeData.length>0) ?
    chartTypeData.reduce((acc, item) => acc + item.total_card, 0):0;

  const plans = [
    {
      id: "total_upi",
      name: "UPI",
      description: totalUpi?.toFixed(2),
      color: "#0028EA",
    },
    {
      id: "total_card",
      name: "Card",
      description: totalCard?.toFixed(2),
      color: "#508EFF",
    },
    {
      id: "total_cash",
      name: "Cash",
      description: totalCash?.toFixed(2),
      color: "#BFD6FF",
    },
  ];
  const [selectedPlans, setSelectedPlans] = useState(
    plans.map((plan) => plan.id)
  );

  useEffect(() => {
    if (selectedPlans.length === 0) {
      setSelectedPlans(plans.map((plan) => plan.id));
    }
    handleChange({ target: { value: PERIOD_FILTERS.SEVEN_DAYS } });
  }, []);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedPlans((prevSelectedPlans) => [...prevSelectedPlans, value]);
    } else {
      if (selectedPlans.length > 1) {
        setSelectedPlans((prevSelectedPlans) =>
          prevSelectedPlans.filter((plan) => plan !== value)
        );
      }
    }
  };

  const handleChange = async (e) => {
    const selectedValue = e.target.value;
    setValueSelected(selectedValue);
    const actionCreators = {
      seven_days: getPaymentTypeDataByDays,
      monthly: getPaymentTypeDataByMonths,
      yearly: getPaymentTypeDataByYears,
    };

    const actionCreator = actionCreators[selectedValue];

    // Calculate fromDate and toDate based on the selected value
    const currentDate = new Date();
    const currentDateClone = new Date(currentDate.getTime());
    let fromDate, toDate;
    if (selectedValue === PERIOD_FILTERS.SEVEN_DAYS) {
      fromDate = new Date(currentDateClone.setDate(currentDateClone.getDate() - 6));
      toDate = currentDate;
    } else if (selectedValue === PERIOD_FILTERS.MONTHLY) {
      // Set to the 1st day of the month 12 months before the current date
      fromDate = new Date(
        currentDateClone.getFullYear(),
        currentDateClone.getMonth() - 11,
        1
      );
      toDate = currentDateClone;
    } else if (selectedValue === PERIOD_FILTERS.YEARLY) {
      // Set to the 1st month of the year 10 years before the current date
      fromDate = new Date(currentDateClone.getFullYear() - 10, 0, 1);
      toDate = currentDateClone;
    }
    if (actionCreator) {
      const { payload } = await dispatch(
        actionCreator({
          adminId,
          fromDate: dateFormatApi(fromDate),
          toDate: dateFormatApi(toDate),
        })
      );
      setChartTypeData(payload?.data);
    }
  };

  return (
    <div className=" flex flex-col p-2 mx-5 lg:ml-5 lg:my-5 lg:mr-0 rounded-lg shadow space-y-4 h-[397px] ">
      <div className="flex px-4 lg:px-7 justify-between items-center sm:my-0">
        <h4 className="text-xl font-base">Transaction Type</h4>
        <DashboardDurationDropdown
          handleChange={handleChange}
          periodRange={dateRange}
        />
      </div>
      <div>
        <DashboardPaymentTypeChart
          plans={plans}
          selectedPlans={selectedPlans}
          chartTypeData={chartTypeData}
          valueSelected={valueSelected}
        />
      </div>
      <div className="sm:m-auto flex sm:items-center justify-between pl-2">
        <div className="flex flex-col sm:items-center sm:flex-row relative left-0 sm:space-x-4 xl:space-x-10">
          {plans.map((plan) => {
            return (
              <div key={plan.id} className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id={plan.id}
                    aria-describedby={`${plan.id}-description`}
                    name="plan"
                    type="checkbox"
                    value={plan.id}
                    checked={selectedPlans.includes(plan.id)}
                    onChange={handleCheckboxChange}
                    className={`h-4 w-4 rounded-full appearance-none border-indigo-600  text-indigo-600 focus:ring-transparent`}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor={plan.id}
                    className="font-medium text-gray-900"
                  >
                    {plan.name}
                  </label>
                  <span
                    id={`${plan.id}-description`}
                    className="ml-1 text-gray-500"
                  >
                    ₹{plan.description}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
