import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
};

const GenericMenu = ({ menuItems, hidden, menuText }) => {
    return (
        <Menu as="div" className={`relative flex justify-end ${hidden ? 'lg:hidden' : ''}`}>
            <div className="flex items-center">
                <Menu.Button className="lg:-m-2 flex items-center p-2 border rounded-md border-indigo-600 bg-white text-indigo-600 hover:text-white hover:bg-indigo-600">
                    <span className="sr-only">Options</span>
                    {menuText == null ?
                        <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
                        : <div className="text-sm font-semibold">{menuText}</div>
                    }
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-8 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {menuItems.map((item, index) => (
                            <Menu.Item key={index}>
                                {({ active }) => (
                                    <div
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-1 text-sm'
                                        )}
                                        onClick={item.onClick}
                                    >
                                        {item.label}
                                    </div>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default GenericMenu;