import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';

import { dateFormat, timeConstants, } from '../../../utils/constants/enums';
import { MISCELLANEOUS_LABELS } from '../../../utils/constants/keywords';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const weekdays = [
    { short: 'Sun', long: 'day' },
    { short: 'Mon', long: 'day' },
    { short: 'Tue', long: 'sday' },
    { short: 'Wed', long: 'nesday' },
    { short: 'Thu', long: 'rsday' },
    { short: 'Fri', long: 'day' },
    { short: 'Sat', long: 'urday' },

];

const CalendarHeader = ({ currMonth }) => (
    <div className='bg-orange-400 rounded-t-lg'>
        <div className="flex items-center sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-1 text-white">
            <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                <div className='flex items-center'>
                    <div className="font-normal text-xs whitespace-nowrap inline-block pl-1">
                        {currMonth.format(dateFormat.monthYearFormat)}
                    </div>
                </div>
            </dl>
            <div className="lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4 pr-1">
                <div className="flex items-center font-normal text-xs">
                    Printed {moment().format(dateFormat.slashedReverse)}
                </div>
            </div>
        </div>
        <div className="grid grid-cols-7 gap-px text-center text-xs font-normal leading-10 text-white lg:flex-none">
            {weekdays.map((day, index) => (
                <div key={index} className="py-2">
                    {day.short}
                    <span className="sr-only sm:not-sr-only">{day.long}</span>
                </div>
            ))}
        </div>
    </div>
);

export default function AttendanceReportCalendar({ daysInMonth, currMonth }) {
    const { attendanceData } = useSelector(store => store.attendance);

    const formatTime = (time) => {
        if (!time || time === MISCELLANEOUS_LABELS.STATUS_NA) return MISCELLANEOUS_LABELS.STATUS_NA;
        return moment(time, timeConstants.timeFormatWithSeconds).format(timeConstants.timeFormatWithAmPm);
    };

    const getAttendanceStatus = (day) => {
        const formattedDay = day.format(dateFormat.ISO);
        return attendanceData?.logs?.find(item => item.logdate === formattedDay);
    };

    const numRows = Math.ceil(daysInMonth.length / 7) || 7;

    const renderAttendanceStatus = (attendanceStatus) => {
        if (!attendanceStatus) {
            return (
                <div className="group flex">
                    <p className="flex-auto"></p>
                </div>
            );
        }

        if (attendanceStatus.memberPresent) {
            return (
                <>
                    <div className="group flex">
                        <p className="flex-auto">Check In : {formatTime(attendanceStatus.logTime)}</p>
                    </div>
                    <div className="group flex">
                        <p className="flex-auto">Check Out : N/A</p>
                    </div>
                </>
            );
        }

        return (
            <div className="group flex">
                <p className="flex-auto font-bold">-</p>
            </div>
        );
    };

    return (
        <div className="lg:flex lg:h-full lg:flex-col mt-6">
            <div className="rounded-lg lg:flex lg:flex-auto lg:flex-col">
                <CalendarHeader currMonth={currMonth} />
                <div className="flex border-2 border-gray-400 bg-gray-400 text-xs leading-4 text-gray-700 lg:flex-auto">
                    <div className={`hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-${numRows} lg:gap-[2px] text-center`}>
                        {daysInMonth.map((day, dayIdx) => {
                            const attendanceStatus = getAttendanceStatus(day);
                            const isCurrentMonth = day.month() === currMonth.month();
                            return (
                                <div
                                    key={day.format(dateFormat.ISO)}
                                    className={classNames(
                                        isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                                        'relative py-0.5'
                                    )}
                                >
                                    <div className='border-b-2 border-gray-500 pb-1 font-semibold'>
                                        <time dateTime={day.format(dateFormat.ISO)}>
                                            {day.format(dateFormat.dayOfMonth)}
                                        </time>
                                    </div>
                                    <ol className="mt-2">
                                        <li className={`px-0.5 font-semibold ${attendanceStatus ? 'text-[10px] py-4' : 'text-[10px] py-7'} text-gray-900`}>
                                            {renderAttendanceStatus(attendanceStatus)}
                                        </li>
                                    </ol>
                                </div>
                            )
                        })}
                        {Array.from({ length: 42 - daysInMonth.length }).map((_, index) => (
                            <div key={`empty-${index}`} className="bg-white relative"></div>
                        ))}
                    </div>
                    <div className={`isolate grid w-full grid-cols-7 grid-rows-${numRows} gap-px lg:hidden`}>
                        {daysInMonth.map((day) => {
                            const attendanceStatus = getAttendanceStatus(day);
                            const isCurrentMonth = day.month() === currMonth.month();
                            return (
                                <div
                                    key={day.format(dateFormat.ISO)}
                                    className={classNames(
                                        isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                        'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10'
                                    )}
                                >
                                    <time
                                        dateTime={day.format(dateFormat.ISO)}
                                        className={classNames(
                                            'ml-auto',
                                            isCurrentMonth ? 'text-gray-900' : 'text-gray-400'
                                        )}
                                    >
                                        {day.format(dateFormat.dayOfMonth)}
                                    </time>
                                    <div className="mt-auto">
                                        {attendanceStatus && attendanceStatus?.memberPresent && (
                                            <CheckCircleIcon className="h-5 w-5 text-green-500" />
                                        )}
                                        {attendanceStatus && !attendanceStatus?.memberPresent && (
                                            <XCircleIcon className="h-5 w-5 text-red-500" />
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                        {Array.from({ length: 42 - daysInMonth.length }).map((_, index) => (
                            <div
                                key={`empty-mobile-${index}`}
                                className="bg-white flex h-14 flex-col px-3 py-2"
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}