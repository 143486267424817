import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getFormattedDate } from '../../../utils/formatter/dateFormatter'
import { getClubDetails } from '../../../features/settings/clubProfileSlice'
import GenericTable from './GenericTable'
import BillingTransactionDetails from './BillingTransactionDetails'
import profileImg from '../../../assets/images/profileImg.png'
import DueBillingContainer from './DueBillingContainer'
import { handleCalculation, setInitialDueBillingValues, setIsCollectDueClicked } from '../../../features/people/collectDueSlice'

const billingDetailsNameMapper = {
    id: 'Invoice',
    billingDate: 'Date',
    grandTotalAmount: 'Amount',
    amountReceived: 'Paid',
    balance: 'Balance',
    paymentType: 'Mode',
    dueDate: 'Due date',
    transferredBy: 'Transfer From',
    transferredTo: 'Transfer To',
    startDate: 'Freeze Start Date',
    endDate: 'Freeze End Date',
    transferFee: 'Transfer Fee',
    FreezeFee: 'Freeze Fee'
}

export default function CollectDue({ billData, memberDetails }) {

    const {
        user: { userId: adminId },
    } = useSelector((store) => store.user);
    const clubDetails = useSelector((store) => store.clubProfile);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getClubDetails(adminId));
        dispatch(setInitialDueBillingValues())
        dispatch(handleCalculation({ balance: billData?.balance }))
    }, [])

    const getFormattedBillingData = (item) => {
        return {
            id: item.id,
            billingDate: getFormattedDate(item.billingDate),
            grandTotalAmount: item.grandTotalAmount || item.grandAmount,
            amountReceived: item.amountReceived,
            balance: item.balance,
            paymentType: item.paymentType,
            dueDate: item.dueDate ? getFormattedDate(item.dueDate) : '---'
        }
    }


    return (
        <div className="bg-transparent sm:rounded-md sm:border sm:border-gray-300">
            <div className="flex bg-gray-100 items-center border-b border-gray-300 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-4">
                <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                    <div className='flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-gray-500 cursor-pointer flex-none" onClick={() => dispatch(setIsCollectDueClicked(false))}> {/* Added flex-none class */}
                            <path fillRule="evenodd" d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10Z" clipRule="evenodd" />
                        </svg>
                        <div className="font-normal text-lg text-gray-800 whitespace-nowrap inline-block ml-2">
                            Collect Due
                        </div>
                    </div>
                </dl>
            </div>

            <main id="pdf-content">
                <header className="relative isolate ">
                    <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-4 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-6 xl:pb-4 xl:pt-4">
                        <div className="flex items-center justify-between sm:items-start">

                            <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-lg sm:h-16 sm:w-16">
                                <img
                                    src={clubDetails.clubLogo || profileImg}
                                    className="h-full w-full object-fit object-center"
                                />
                            </div>
                            <div className="ml-4 flex-1 text-sm">
                                <div className="font-bold text-xl text-gray-900 sm:flex sm:justify-between">
                                    <div>{clubDetails.clubName}</div>
                                </div>
                                <p className="text-gray-500 w-full sm:w-3/4 sm:mt-1 sm:block break-all">{clubDetails.clubAddress}</p>
                            </div>


                        </div>


                        <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-1">
                            <div className="">

                                <dd className="mt-2 text-gray-500">
                                    <span className="font-medium text-lg text-gray-900">Invoice #{billData?.id}</span>
                                    <br />
                                    {getFormattedDate(billData.billingDate)}
                                    <br />
                                    GSTIN NO: {billData.clubGstNo || '---'}
                                </dd>
                            </div>
                            <div className="mt-8 sm:mt-2 sm:pt-2">
                                <dd className="mt-2 text-gray-500">
                                    <span className="font-medium text-lg text-gray-900">Bill To</span>
                                    <br />
                                    {memberDetails.mobileNo}
                                    <br />
                                    {memberDetails.members.address1 || '---'}
                                </dd>
                            </div>
                        </dl>
                        <div className="mt-8 sm:mt-2 sm:pt-2">
                            <dd className=" text-gray-500">
                                <span className="font-medium text-lg text-gray-900">Payment history</span>
                            </dd>
                        </div>
                        <div
                            className="mt-4 rounded-md border border-gray-300"
                        >
                            <div>
                                <div className="my-2">
                                    <div className="-my-2 py-3 overflow-x-auto px-2 lg:px-0">
                                        {billData?.dueMembers?.length > 0 && (
                                            <div>
                                                <BillingTransactionDetails data={billData?.dueMembers} mapper={billingDetailsNameMapper} getFormattedBillingData={getFormattedBillingData} />
                                            </div>
                                        )}
                                        {billData?.dueMembers?.length === 0 && (
                                            <GenericTable data={getFormattedBillingData(billData)} mapper={billingDetailsNameMapper} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2">
                            <DueBillingContainer billData={billData} />
                        </div>
                    </div>
                </header>
            </main>
        </div>
    )
}