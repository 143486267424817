import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { SUPER_ADMIN_SUBSCRIPTION_MODULE } from "../../utils/axios/superAdminpathUrl";
import { checkForExistingPlanNameThunk, createSubscriptionsThunk, getAllSubscriptionsThunk, updateSubscriptionOnIdThunk } from "./superAdminSubscriptionsThunk";

export const createSubscriptions = createAsyncThunk(
    "superAdminSubscriptions/createSubscriptions",
    async ({ subscriptionData, featuresData }, thunkAPI) => {
        return createSubscriptionsThunk(
            SUPER_ADMIN_SUBSCRIPTION_MODULE.CREATE_SUBSCRIPTION,
            subscriptionData,
            SUPER_ADMIN_SUBSCRIPTION_MODULE.ADD_FEATURES_TO_CREATED_SUBSCRIPTION,
            featuresData,
            thunkAPI
        );
    }
);

export const getAllSubscriptions = createAsyncThunk(
    "superAdminSubscriptions/getAllSubscriptions",
    async (thunkAPI) => {
        return getAllSubscriptionsThunk(
            SUPER_ADMIN_SUBSCRIPTION_MODULE.GET_ALL_SUBSCRIPTION,
            thunkAPI
        );
    }
);

export const updateSubscriptionOnId = createAsyncThunk(
    "superAdminSubscriptions/updateSubscriptionOnId",
    async ({ id, subscriptionData, featuresData }, thunkAPI) => {
        return updateSubscriptionOnIdThunk(
            `${SUPER_ADMIN_SUBSCRIPTION_MODULE.UPDATE_SUBSCRIPTION_ON_ID}/${id}`,
            subscriptionData,
            SUPER_ADMIN_SUBSCRIPTION_MODULE.ADD_FEATURES_TO_CREATED_SUBSCRIPTION,
            featuresData,
            thunkAPI
        );
    }
);

export const checkForExistingPlanName = createAsyncThunk(
    "superAdminSubscriptions/checkForExistingPlanName",
    async ({ planName }, thunkAPI) => {
        return checkForExistingPlanNameThunk(
            `${SUPER_ADMIN_SUBSCRIPTION_MODULE.CHECK_FOR_EXISTING_PLAN_NAME}${planName}`,
            thunkAPI
        );
    }
);

const initialState = {
    isLoading: false,
    getAllSubscriptionsLoading: false,
    subscriptions: [],
    existingPlanName: {
        status: false,
        message: ''
    }
};

const superAdminSubscriptionsSlice = createSlice({
    name: "superAdminSubscriptions",
    initialState,
    reducers: {
        setExistingPlanName: (state) => {
            state.existingPlanName.status = false;
            state.existingPlanName.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createSubscriptions.pending, (state) => {
                state.isLoading = true;

            })
            .addCase(createSubscriptions.fulfilled, (state, { payload }) => {

                state.isLoading = false;
            })
            .addCase(createSubscriptions.rejected, (state, { payload }) => {
                state.isLoading = false;

                // toast.error(payload);
            })
            .addCase(getAllSubscriptions.pending, (state) => {
                state.getAllSubscriptionsLoading = true;
                state.subscriptions = []
            })
            .addCase(getAllSubscriptions.fulfilled, (state, { payload }) => {

                state.getAllSubscriptionsLoading = false;
                state.subscriptions = payload
            })
            .addCase(getAllSubscriptions.rejected, (state, { payload }) => {
                state.getAllSubscriptionsLoading = false;
                state.subscriptions = []
                // toast.error(payload);
            })
            .addCase(updateSubscriptionOnId.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateSubscriptionOnId.fulfilled, (state, { payload }) => {

                state.isLoading = false;
            })
            .addCase(updateSubscriptionOnId.rejected, (state, { payload }) => {
                state.isLoading = false;
                // toast.error(payload);
            })
            .addCase(checkForExistingPlanName.pending, (state) => {
                state.isLoading = true;
                state.existingPlanName.status = false;
                state.existingPlanName.message = '';
            })
            .addCase(checkForExistingPlanName.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.existingPlanName.status = payload.status;
                state.existingPlanName.message = payload.message;

            })
            .addCase(checkForExistingPlanName.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.existingPlanName.status = false;
                state.existingPlanName.message = '';
                // toast.error(payload);
            })
    },
});

export const { setExistingPlanName } = superAdminSubscriptionsSlice.actions
export default superAdminSubscriptionsSlice.reducer;