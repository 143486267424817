import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllApprovalsByDateRangeThunk, getAllApprovalsThunk, getAllApprovalsThunkById, postAllApprovalsByStatusThunk } from "./approvalsThunk";
import { SUPER_ADMIN_APPROVALS_MODULE } from "../../utils/axios/superAdminpathUrl";
import { getAllFeatures } from "../settings/featuresSlice";


export const getAllApprovals = createAsyncThunk(
    "approvals/getAllApprovals",
    async ({ currentPageNo }, thunkAPI) => {
        return getAllApprovalsThunk(
            `${SUPER_ADMIN_APPROVALS_MODULE.GET_ALL_APPROVALS}page=${currentPageNo}&size=10`,
            thunkAPI
        )
    }
);

export const getAllApprovalsByDateRange = createAsyncThunk(
    "approvals/getAllApprovalsByDateRange",
    async ({ startDate, endDate }, thunkAPI) => {
        return getAllApprovalsByDateRangeThunk(
            `${SUPER_ADMIN_APPROVALS_MODULE.GET_All_APPROVALS_BY_DATE_RANGE}startDate=${startDate}&endDate=${endDate}`,
            thunkAPI
        )
    }

)

export const getAllApprovalsById = createAsyncThunk(
    "approvals/getAllApprovalsById",
    async ({ id }, thunkAPI) => {
        return getAllApprovalsThunkById(
            `${SUPER_ADMIN_APPROVALS_MODULE.GET_ALL_APPROVALS_BY_ID}${id}`,
            thunkAPI
        );
    }
)

export const postApprovalsByStatus = createAsyncThunk(
    "approvals/postApprovalsByStatus",
    async ({ id, approvalStatus, isBoolean, freeTrailSubscriptionData }, thunkAPI) => {
        return postAllApprovalsByStatusThunk(
            `${SUPER_ADMIN_APPROVALS_MODULE.POST_APPROVALS}${id}/approval-status`,
            approvalStatus,
            isBoolean,
            SUPER_ADMIN_APPROVALS_MODULE.ADD_FREE_TRAIL_SUBSCRIPTION_AFTER_APPROVAL,
            freeTrailSubscriptionData,
            thunkAPI
        )
    }
)



const initialState = {
    isLoading: false,
    getAllApprovalsData: [],
    getAllApprovalsDataById: {},
    getApprovalStatus: {},
    totalPages: "",
    getAllADateRange: [],
}

const approvalSlice = createSlice({
    name: 'approvals',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllApprovals.pending, (state) => {
                state.isLoading = true;
                state.getAllApprovalsData = []
            })
            .addCase(getAllApprovals.fulfilled, (state, { payload }) => {
                state.getAllApprovalsData = payload.data.content;
                console.log(`payloadthunkiefjif: ${JSON.stringify(payload)}`);
                state.totalPages = payload.data.totalPages;
                state.isLoading = false;
            })
            .addCase(getAllApprovals.rejected, (state) => {
                state.isLoading = false;
                state.getAllApprovalsData = []
            }).
            addCase(getAllApprovalsById.pending, (state) => {
                state.isLoading = true;
                state.getAllApprovalsDataById = {}
            })
            .addCase(getAllApprovalsById.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.getAllApprovalsDataById = payload.data
            })
            .addCase(getAllApprovalsById.rejected, (state) => {
                state.isLoading = false;
                state.getAllApprovalsDataById = {}
            })
            .addCase(postApprovalsByStatus.pending, (state) => {
                state.isLoading = true;
                state.getApprovalStatus = {}
            })
            .addCase(postApprovalsByStatus.fulfilled, (state, payload) => {
                state.isLoading = false;
                state.getApprovalStatus = payload.data;
                window.location.reload()
            })
            .addCase(postApprovalsByStatus.rejected, (state) => {
                state.isLoading = false;
                state.getApprovalStatus = {}
            })
            .addCase(getAllApprovalsByDateRange.pending, (state) => {
                state.isLoading = true;
                state.getAllADateRange = []
            })
            .addCase(getAllApprovalsByDateRange.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.getAllADateRange = payload.data.content
            })
            .addCase(getAllApprovalsByDateRange.rejected, (state,) => {
                state.isLoading = false;
                state.getAllADateRange = []
            })
    },
})

export default approvalSlice.reducer

