import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  FormRow,
  MultilineFormRow,
  ClubLogo,
  clubInfoValidationSchema,
} from "./";

import {
  handleModal,
  updateClubDetails,
} from "../../features/settings/clubProfileSlice";

const EditClubProfileModal = ({ clubDetails, mapper, clubLogo }) => {
  const dispatch = useDispatch();
  const { register, formState, handleSubmit, getFieldState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(clubInfoValidationSchema),
    defaultValues: { ...clubDetails, clubLogo },
  });

  const { adminId, id: clubId } = useSelector((store) => store.clubProfile);

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (typeof data.clubLogo == "string") {
      //Do not call update logo API
      data.clubLogo = null;
    } else {
      formData.append("file", data.clubLogo[0]);
      data.clubLogo = formData;
    }
    data.id = clubId;
    //call for dispatch
    dispatch(updateClubDetails({ data, adminId }));
    // dispatch(handleModal(false));
  };
  const submitError = async (error) => { };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto" onClick={() => dispatch(handleModal(false))}>
      <div className="flex items-center justify-center min-h-screen px-4 pt-12 text-left">
        {/* Modal Background */}
        <div className="fixed inset-0 bg-black opacity-50"></div>

        {/* Modal Content */}
        <div className="relative bg-white rounded-lg p-14 max-w-6xl w-full" onClick={(e) => e.stopPropagation()}>
          <button
            onClick={() => dispatch(handleModal(false))}
            className="absolute top-4 right-4 p-2 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
          <form onSubmit={handleSubmit(onSubmit, submitError)}>
            {/* Option button to edit image */}
            {/* <div>
              <div className="flex">
                <img
                  src="path_to_your_image"
                  alt="Image Preview"
                  className="w-36 h-36 rounded-full border border-gray-300 mb-4"
                />
                <button className="px-4 py-2 mx-10 my-12 h-12 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500">
                  Edit Logo
                </button>
              </div>
            </div> */}
            <ClubLogo
              name="clubLogo"
              clubLogo={clubLogo}
              register={register}
              formState={formState}
              getFieldState={getFieldState}
            />
            {/* Club Details Heading */}
            <div className="py-2">
              <h2 
              className="text-xl font-semibold mb-4"
              data-testid = "club"
              >Club Details</h2>
            </div>

            {/* Form */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {/* First Row */}
              {Object.keys(clubDetails).map((eachDetail, i) => {
                if (eachDetail === "clubAddress") {
                  return (
                    <MultilineFormRow
                      key={i}
                      name={eachDetail}
                      labelText={mapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (eachDetail === "clubPincode") {
                  return (
                    <FormRow
                      key={i}
                      type="text"
                      maxLength={8}
                      name={eachDetail}
                      labelText={mapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (
                  eachDetail === "clubMobileNo" ||
                  eachDetail === "clubWhatsAppNo" ||
                  eachDetail === "clubWhatsAppNoAdd"
                ) {
                  return (
                    <FormRow
                      key={i}
                      type="text"
                      maxLength={10}
                      name={eachDetail}
                      labelText={mapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (eachDetail === "clubMailId") {
                  return (
                    <FormRow
                      key={i}
                      type="email"
                      name={eachDetail}
                      labelText={mapper[eachDetail]}
                      register={register}
                      formState={formState}
                    />
                  );
                }
                if (eachDetail === "id") return null;
                return (
                  <FormRow
                    key={i}
                    type="text"
                    name={eachDetail}
                    labelText={mapper[eachDetail]}
                    register={register}
                    formState={formState}
                  />
                );
              })}
              {/* <div className="mb-2">
                <label
                  htmlFor="clubName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Club Name
                </label>
                <input
                  type="text"
                  id="clubName"
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-12"
                />
              </div> */}
              {/* <div className="mb-2">
                <label
                  htmlFor="clubNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Club Number
                </label>
                <input
                  type="text"
                  id="clubNumber"
                  maxLength="10"
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-12"
                />
              </div> */}

              {/* Second Row */}
              {/* <div className="mb-2">
                <label
                  htmlFor="clubEmail"
                  className="block text-sm font-medium text-gray-700"
                >
                  Club Email-ID
                </label>
                <input
                  type="email"
                  id="clubEmail"
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-12"
                />
              </div> */}
              {/* <div className="mb-2">
                <label
                  htmlFor="whatsappNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Club Whatsapp Number
                </label>
                <input
                  type="text"
                  id="whatsappNumber"
                  maxLength="10"
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-12"
                />
              </div> */}

              {/* Third Row */}
              {/* <div className="mb-2">
                <label
                  htmlFor="additionalWhatsapp"
                  className="block text-sm font-medium text-gray-700"
                >
                  Additional Whatsapp No.
                </label>
                <input
                  type="text"
                  id="additionalWhatsapp"
                  maxLength="10"
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-12"
                />
              </div> */}
              {/* <div className="mb-2">
                <label
                  htmlFor="clubAddress"
                  className="block text-sm font-medium text-gray-700"
                >
                  Club Address
                </label>
                <textarea
                  id="clubAddress"
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md resize-none h-16"
                ></textarea>
              </div> */}

              {/* Fourth Row */}
              {/* <div className="mb-2">
                <label
                  htmlFor="clubPincode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Club Pincode
                </label>
                <input
                  type="text"
                  id="clubPincode"
                  maxLength="8"
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-12"
                />
              </div> */}
            </div>

            {/* Buttons */}
            <div className="mt-8 flex justify-end">
              <button
                onClick={() => dispatch(handleModal(false))}
                className="mr-2 px-4 py-2 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
                data-testid = "cancel-button"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-white text-gray-600 font-semibold rounded-lg border border-gray-400 hover:bg-gray-100 hover:border-gray-500"
                data-testid = "submit-button"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditClubProfileModal;
