import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getImagesOnDivisionName } from "../../../pages/DivisionImages";
import { checkForExistingMembership } from "../../../features/people/gymMembershipPurchaseFeatures/gymMembershipPurchaseSlice";
import { formatDateToISO, getFormattedDate } from "../../../utils/formatter/dateFormatter";
import { CONSTANTS, MEMBERSHIP_CATEGORIES, MEMBERSHIP_NAMINGS } from "../../../utils/constants/keywords";

function renderDetail(label, value) {
    return (
        <div className="flex items-center justify-between">
            <div className='font-medium text-base text-gray-900'>{label}</div>
            <p className="ml-4 text-base">{value}</p>
        </div>
    );
}

function renderError(message) {
    return <div className=' text-sm text-red-500'>{message}</div>;
}


const CartMembershipLists = ({ adminId, memberId, membership, index, removeMembershipFromCart, selectedBillingDate, handleStartDate, existingMembershipError, handleDiscount, membershipDiscountErrors, startDateErrors, activeTab }) => {
    const { existingMembershipEndDate } = useSelector((store) => store.gymMembershipPurchase)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(checkForExistingMembership({ adminId, memberId, membership }))
    }, [adminId, memberId, membership])

    const handleRemoveMembershipFromCart = (membership) => {
        let id, type;
        switch (membership.name) {
            case MEMBERSHIP_NAMINGS.PACKAGE:
                id = membership.packageId;
                type = MEMBERSHIP_NAMINGS.PACKAGE;
                break;
            case MEMBERSHIP_NAMINGS.PERSONAL_TRAINING:
                id = membership.ptId;
                type = MEMBERSHIP_NAMINGS.PT;
                break;
            case MEMBERSHIP_NAMINGS.GROUP_CLASS:
                id = membership.gcId;
                type = MEMBERSHIP_NAMINGS.GC;
                break;
            case MEMBERSHIP_NAMINGS.OFFER:
                id = membership.offerId;
                break;
            default:
                id = '';
                break;
        }
        removeMembershipFromCart(id, type, index);
    }

    return (
        <li key={index} className="">
            <div className='flex items-center py-3'>
                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                    <img
                        src={`${getImagesOnDivisionName(membership.divisionName)}`}
                        alt={membership.imageAlt}
                        className="h-full w-full object-cover object-center"
                    />
                </div>
                <div className="ml-6 flex flex-1 flex-col">
                    <div>
                        {renderDetail("Membership Name", membership.name)}
                        {renderDetail("Division", membership.divisionName)}
                        {renderDetail("Duration", `${membership.durationTime} ${membership.durationType}`)}
                        {renderDetail("Cost (₹)", membership.cost)}
                        {renderDetail("Discount (₹)",
                            <input
                                id={1}
                                type='text'
                                name={'discount'}
                                value={membership.discount}
                                onChange={(event) => handleDiscount(event.target.value, index, membership.maximumCost, membership.minimumCost, membership.cost)}
                                className={`block text-right w-24 rounded-md border-0 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${activeTab == 'Offers' ? 'bg-gray-100' : ''}`}
                                disabled={activeTab === MEMBERSHIP_CATEGORIES.OFFERS}
                                data-testid="discount-input" 
                            />
                        )}
                        {renderDetail("Total Amount (₹)", membership.totalAmount)}
                        {renderDetail("Start Date",
                            <input
                                id={1}
                                type='date'
                                name='startDate'
                                value={membership.startDate || ''}
                                onChange={(event) => handleStartDate(event.target.value, index, membership.noOfDays, existingMembershipEndDate)}
                                className={`block text-right w-full rounded-md border-0 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${selectedBillingDate == '' ? 'bg-gray-100' : ''}`}
                                min={selectedBillingDate ? formatDateToISO(new Date(selectedBillingDate)) : ''}
                                disabled={!selectedBillingDate}
                                data-testid="start-date-input" 
                            />
                        )}
                        {membership.startDate !== '' && renderDetail("End Date", getFormattedDate(membership.endDate))}
                    </div>
                    <div className="flex flex-1 items-center justify-between text-sm">
                        <div>
                            {selectedBillingDate == '' && renderError("*Billing date is required to select start date")}
                            {existingMembershipError[index] != '' && renderError(existingMembershipError[index])}
                            {membershipDiscountErrors[index] != '' && renderError(membershipDiscountErrors[index])}
                            {startDateErrors[index] != '' && renderError(startDateErrors[index])}
                        </div>
                        <div className="flex py-2">
                            {membership.name && (
                                <button
                                    type="button"
                                    className="font-medium border p-1 rounded-lg text-indigo-600 shadow-sm hover:text-indigo-500"
                                    onClick={() => handleRemoveMembershipFromCart(membership)}
                                >
                                    Remove
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}
export default CartMembershipLists;