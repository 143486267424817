import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import GenericTable from "./GenericTable";
import FreezeBillingContainer from './FreezeBillingContainer'
import { freezeMembershipSchema } from "./FreezeMembershipSchema";
import { handleCalculation, postFreezeMembershipDetails, setInitialfreezeBillingValues } from "../../../features/people/freezeMembershipSlice";
import { getUpdatedMembershipData } from "./freezeMembershipMethods";
import { formatDateToISO } from "../../../utils/formatter/dateFormatter";
import { timeConstants } from "../../../utils/constants/enums";
import { generateInvoicePdf } from "../gymMembershipPurchaseComponents/GenerateInvoicePdf";

const membershipDetailsNameMapper = {
    divisionName: 'Division',
    duration: 'Duration',
    startDate: 'Start date',
    endDate: 'End date',
    cost: 'Amount',
    packageStatus: 'Status'
}

const keyMappers = {
    startDate: 'startDate',
    endDate: 'endDate',
    noOfDays: 'noOfDays'
}

const onChangeMode = 'onChange'

const FreezeMembership = ({ membershipData, memberId, data, status, setActionStatus }) => {
    const dispatch = useDispatch()
    const { orderBillings } = useSelector((store) => store.memberOrderDetails);
    const [billingPayload, setBillingPayload] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        dispatch(setInitialfreezeBillingValues())
        dispatch(handleCalculation({ freezeFee: membershipData?.freezeFee }));
    }, [])

    const freezeMembershipData = {
        billingId: '',
        endDate: '',
        startDate: '',
        reason: '',
        freezeStatus: true,
        noOfDays: '',
    }

    const { register, handleSubmit, formState: { errors }, setValue, getValues, trigger } = useForm({
        resolver: yupResolver(freezeMembershipSchema(membershipData?.miniFreezeDur, membershipData?.maxFreezeDur, data?.startDate, data?.endDate)),
        defaultValues: freezeMembershipData,
        mode: onChangeMode
    })

    const calculateFreezeEndDate = (startDate, noOfDays) => {
        const days = Number(noOfDays);
        const freezeStartDate = new Date(startDate);
        const endDate = new Date(freezeStartDate.getTime() + days * timeConstants.milliSecondsPerDay);
        // return endDate.toISOString().split('T')[0];
        return formatDateToISO(endDate);
    };

    const handleStartDateChange = async (e) => {
        const startDateValue = e.target.value;
        setValue(keyMappers.startDate, startDateValue);
        const days = getValues(keyMappers.noOfDays);
        if (startDateValue && !isNaN(days) && days >= membershipData?.miniFreezeDur && days <= membershipData?.maxFreezeDur) {
            const endDate = calculateFreezeEndDate(startDateValue, days);
            setValue(keyMappers.endDate, endDate);
        }
        await trigger([keyMappers.startDate, keyMappers.endDate]);
    };

    const handleNoOfDaysChange = async (e) => {
        const daysValue = e.target.value;
        setValue(keyMappers.noOfDays, daysValue);
        const startDate = getValues(keyMappers.startDate);
        if (startDate && daysValue && !isNaN(daysValue) && daysValue >= membershipData?.miniFreezeDur && daysValue <= membershipData?.maxFreezeDur) {
            const endDate = calculateFreezeEndDate(startDate, daysValue);
            setValue(keyMappers.endDate, endDate);
        }
        await trigger([keyMappers.noOfDays, keyMappers.endDate]);
    };

    const handleFreezeBilling = (billingData) => {
        setBillingPayload(billingData);
    };

    const onSubmit = async (freezeData) => {
        freezeData.startDate.setDate(freezeData.startDate.getDate() + 1);
        const updatedMembershipData = getUpdatedMembershipData(data, freezeData)
        if (freezeData && billingPayload) {
            setIsSubmitting(true)
            const response = await dispatch(postFreezeMembershipDetails({ freezeMembershipData: freezeData, billingPayload, adminId: data?.adminId, memberId, updatedMembershipData, purchasedMembershipId: data?.id }))
            if (response.payload.billData) {
                generateInvoicePdf(memberId, data?.adminId, response.payload.billData, dispatch)
                setActionStatus(false)
                setIsSubmitting(false)
                return
            }
            return setIsSubmitting(false)
        }
    }

    const membershipBillingData = orderBillings.find((billing) => billing.id === data.billingId)

    return (

        <div className=" sm:rounded-md sm:border sm:border-gray-300">
            <div className="px-6 py-3 bg-gray-100">
                <div className="flex font-normal text-lg text-gray-800 whitespace-nowrap">
                    <div className="text-gray-800 font-semibold">Freeze</div>
                    <div className="pl-2">{data.membershipName}</div>
                </div>

                <h4 className="sr-only">Items</h4>
                <ul role="list" className="divide-y divide-gray-200">
                    <li className="p-4 sm:p-0">
                        <div className=" flow-root divide-y divide-gray-200  border-gray-800">
                            <div className="-mx-4 -my-2 py-3 overflow-x-auto sm:-mx-6  px-3">
                                <GenericTable data={data?.memberShipData} mapper={membershipDetailsNameMapper} />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <>
                {membershipBillingData?.balance === 0 && (
                    <div>
                        {!data.freezeMembers && (
                            <div>
                                {status && (
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="mt-1.5">
                                            <div className="font-semibold text-black text-medium px-6 py-3">
                                                Freeze Details
                                            </div>
                                            <div className="grid px-6 py-1 grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-6">
                                                <div className="sm:col-span-2 sm:col-start-1 ">
                                                    <label htmlFor="startDate" className="block text-sm font-normal leading-6  text-gray-800">
                                                        Freeze start Date*
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="date"
                                                            name="startDate"
                                                            id="startDate"
                                                            autoComplete="off"
                                                            className="bg-transparent block w-full rounded-md border border-gray-300 py-2.5 text-gray-400 shadow-sm"
                                                            {...register("startDate")}
                                                            onChange={handleStartDateChange}
                                                            min={formatDateToISO(new Date())}
                                                        />
                                                        {errors && <p className="mt-1 text-sm text-red-500">{errors.startDate?.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="noOfDays" className="block text-sm font-normal leading-6 text-gray-800">
                                                        No. of days to be frozen
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="noOfDays"
                                                            id="noOfDays"
                                                            autoComplete="off"
                                                            className="block w-full rounded-md border border-gray-300 py-2.5 text-gray-400 shadow-sm "
                                                            {...register("noOfDays")}
                                                            onChange={handleNoOfDaysChange}
                                                        />
                                                        {errors && <p className="mt-1 text-sm text-red-500">{errors.noOfDays?.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="freezeFee" className="block text-sm font-normal leading-6 text-gray-800">
                                                        Freeze Fee*
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="freezeFee"
                                                            value={membershipData.freezeFee}
                                                            id="freezeFee"
                                                            autoComplete="off"
                                                            className="block w-full rounded-md border border-gray-300  py-2.5 text-gray-400 shadow-sm "
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-6 sm:col-start-1 ">
                                                    <label htmlFor="reason" className="block text-sm font-normal leading-6  text-gray-800">
                                                        Reason
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="reason"
                                                            id="reason"
                                                            autoComplete="off"
                                                            className="bg-transparent block w-full rounded-md border border-gray-300  py-2.5 text-gray-400 shadow-sm "
                                                            {...register("reason")}
                                                        />
                                                        {errors && <p className="mt-1 text-sm text-red-500">{errors.reason?.message}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <FreezeBillingContainer freezeFee={membershipData?.freezeFee} adminId={data?.adminId} memberId={memberId} handleFreezeBilling={handleFreezeBilling} setActionStatus={setActionStatus} isSubmitting={isSubmitting} />
                                        </div>
                                    </form>
                                )}
                                {!status && (
                                    <div className="mx-3">
                                        <div className="mt-6 mx-auto flex items-center justify-center space-x-4 divide-gray-200 border-t border-gray-200 pt-4 text-xl font-normal sm:my-12 sm:border-none sm:pt-0 text-center text-gray-800">
                                            <div>Freeze is not allowed for this membership.</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {data.freezeMembers && (
                            <div className="mx-3">
                                <div className="mt-6 mx-auto flex items-center justify-center space-x-4 divide-gray-200 border-t border-gray-200 pt-4 text-xl font-normal sm:my-12 sm:border-none sm:pt-0 text-center text-gray-800">
                                    <div>Already this membership is freezed once. You are not able to freeze the membership again.</div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {membershipBillingData?.balance !== 0 && (
                    <div className="mx-3">
                        <div className="mt-6 mx-auto flex items-center justify-center space-x-4 divide-gray-200 border-t border-gray-200 pt-4 text-xl font-normal sm:my-12 sm:border-none sm:pt-0 text-center text-gray-800">
                            <div>Please pay the due amount in order to freeze the membership.</div>
                        </div>
                    </div>
                )}
            </>
        </div>
    )
}

export default FreezeMembership;