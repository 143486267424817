import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkFeaturesThunk,
  craeteFeaturesThunk,
  editFeaturesThunk,
  editSettingsDetailsThunk,
  getAllFeaturesThunk,
} from "./featuresThunk";
import { SUPER_ADMIN_SETTINGS_MODULE } from "../../utils/axios/superAdminpathUrl";
import { toast } from "react-toastify";
import { SETTINGS_MODULE } from "../../utils/axios/pathUrls";

export const getAllFeatures = createAsyncThunk(
  "features/getAllFeatures",
  async (thunkAPI) => {
    return getAllFeaturesThunk(
      SUPER_ADMIN_SETTINGS_MODULE.FEATURES.GET_ALL_FEATURES,
      thunkAPI
    );
  }
);

export const createFeature = createAsyncThunk(
  "features/createFeatures",
  async (data, thunkAPI) => {
    return craeteFeaturesThunk(
      SUPER_ADMIN_SETTINGS_MODULE.FEATURES.POST_CREATE_FEATURE,
      data,
      thunkAPI
    );
  }
);

export const editFeature = createAsyncThunk(
  "features/editFeatures",
  async (data, thunkAPI) => {
    return editFeaturesThunk(
      `${SUPER_ADMIN_SETTINGS_MODULE.FEATURES.PUT_EDIT_FEATURE}/${data.id}`,
      data,
      thunkAPI
    );
  }
);

export const checkFeature = createAsyncThunk(
  "features/checkFeatures",
  async ({ featureName }, thunkAPI) => {
    return checkFeaturesThunk(
      `${SUPER_ADMIN_SETTINGS_MODULE.FEATURES.GET_CHECK_FEATURE}/check?name=${featureName}`,
      thunkAPI
    );
  }
);

export const editSettingsDetails = createAsyncThunk(
  "features/editSettingsDetails",
  async (data, thunkAPI) => {
    return editSettingsDetailsThunk(
      `${SETTINGS_MODULE.CLUB_PROFILE.UPDATE_CLUB_DETAILS}/${data.id}`,
      data,
      thunkAPI
    );
  }
);

const initialState = {
  isLoading: false,
  allFeatures: [],
  errorMessage: "",
  isFeatureNamePresent: false,
  featureNameExistingMessage: "",
};

const featuresSlice = createSlice({
  name: "features",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllFeatures.pending, (state) => {
        state.isLoading = true;
        state.allFeatures = [];
      })
      .addCase(getAllFeatures.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.allFeatures = payload;
        state.isLoading = false;
      })
      .addCase(getAllFeatures.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.allFeatures = [];
      })
      .addCase(createFeature.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFeature.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success("Feature created successfully!");
      })
      .addCase(createFeature.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMessage = payload;
        toast.error(payload);
      })
      .addCase(checkFeature.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.checkFeatureMessage = payload.message;
        state.featureNameExistingMessage = payload.message;
        state.isFeatureNamePresent = payload.status;
      })
      .addCase(checkFeature.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.featureNameExistingMessage = "";
        state.isFeatureNamePresent = false;
      })
      .addCase(checkFeature.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editSettingsDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success("Details update successfully!");
      })
      .addCase(editSettingsDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMessage = payload;
        toast.error(payload);
      })
      .addCase(editSettingsDetails.pending, (state) => {
        state.isLoading = true;
      });
  },
});

export default featuresSlice.reducer;
