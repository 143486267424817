import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Dialog, Transition } from "@headlessui/react";

import { getDivision } from "../../features/settings/divisionSlice";
import {
  closePTModal,
  createPT,
  getPersonalTrainerNames,
  updatePT,
} from "../../features/membership/personalTrainerSlice";

import { SelectTag, FormField, ToggleSwitch, CloseIconButton } from "./index";
import { durationTypes, useDivisionNames } from "./membershipMethods";
import {
  buildPTData,
  defaultPTValues,
  pTSchema,
  resetPTFormValues,
  usePersonalTrainerNames,
} from "./personalTrainerMethods";

const initialState = {
  title: "",
  freezeStatus: true,
  upgradeStatus: true,
  transferStatus: true,
  PTStatus: true,
  editPT: true,
  currentPTId: "",
  PTUpdateStatus: false,
};

const PersonalTrainerModal = () => {
  const [pTState, setPTState] = useState(initialState);

  const { user } = useSelector((store) => store.user);
  const { isPTModalOpen, currentPTData } = useSelector(
    (store) => store.personalTrainers
  );

  const dispatch = useDispatch();

  const divisionNames = useDivisionNames();

  const personalTrainerNames = usePersonalTrainerNames();

  const updatePTState = (newState) => {
    setPTState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  useEffect(() => {
    //form initial state
    dispatch(getPersonalTrainerNames({ adminId: user.userId }));
    dispatch(getDivision({ adminId: user.userId }));

    if (currentPTData) {
      resetPTForm(currentPTData);
      updatePTState({
        title: "Update Personal Training",
        PTUpdateStatus: true,
      });
    }
    if (!currentPTData) {
      reset(defaultPTValues);
      updatePTState({
        editPT: false,
        title: "Create Personal Training",
      });
    }
  }, [currentPTData, defaultPTValues]);

  const resetPTForm = (currentPT) => {
    reset(resetPTFormValues(currentPT));

    const updateStateValues = {
      currentPTId: currentPT.id,
      freezeStatus: currentPT.freezeOption ?? false,
      upgradeStatus: currentPT.upgradeOption ?? false,
      transferStatus: currentPT.transferOption ?? false,
      PTStatus: currentPT.ptStatus ?? false,
      PTUpdateStatus: true,
      editPT: true,
    };
    updatePTState(updateStateValues);
  };

  const schema = pTSchema(
    pTState.freezeStatus,
    pTState.upgradeStatus,
    pTState.transferStatus
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultPTValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    const pTData = buildPTData(
      user,
      formData,
      pTState.freezeStatus,
      pTState.upgradeStatus,
      pTState.transferStatus,
      pTState.PTStatus
    );

    const action = pTState.PTUpdateStatus
      ? updatePT({
        PTId: pTState.currentPTId,
        PTData: pTData,
      })
      : createPT({
        PTData: pTData,
        PTRefetch: {
          adminId: user.userId,
          pageNo: 0,
          pageItems: 9,
        },
      });

    dispatch(action);
    dispatch(closePTModal());
    updatePTState({
      editPT: true,
      freezeStatus: true,
      upgradeStatus: true,
      transferStatus: true,
    });
  };

  const togglePTStatus = () => {
    const newPTStatus = !pTState.PTStatus;
    dispatch(
      updatePT({
        PTId: pTState.currentPTId,
        PTData: { ptStatus: newPTStatus },
      })
    );
    dispatch(closePTModal());
  };

  const handleCloseModal = () => {
    dispatch(closePTModal());
  };

  const toggleState = (key) => {
    updatePTState({ [key]: !pTState[key] });
  };

  return (
    <Transition.Root show={isPTModalOpen} as={Fragment}>
      <Dialog
        as="div"
        static={false}
        className="relative z-50 "
        onClose={handleCloseModal}
        onClick={() => handleCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform m-2 md:m-5 lg:mx-20 overflow-hidden w-screen md:max-w-5xl rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="sm:flex sm:items-start mb-3 sm:justify-between">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left ">
                    {!pTState.PTUpdateStatus ? (
                      <div className="absolute right-0 top-0 pr-4 pt-8 sm:pt-4 sm:block">
                        <CloseIconButton onClick={handleCloseModal} />
                      </div>
                    ) : null}
                    <Dialog.Title
                      as="h3"
                      className="text-xl sm:text-2xl font-semibold leading-6 text-gray-900 text-left"
                    >
                      {
                        (pTState.title = pTState.PTUpdateStatus
                          ? pTState.editPT
                            ? "View Personal Training"
                            : "Edit Personal Training"
                          : "Create Personal Training")
                      }
                    </Dialog.Title>
                  </div>
                  {pTState.PTUpdateStatus ? (
                    <div className="mt-4 sm:mt-0 flex flex-row-reverse">
                      <div className="sm:block mt-4 sm:mt-1">
                        <CloseIconButton onClick={handleCloseModal} />
                      </div>
                      <button
                        className="mr-5 mt-3 inline-flex w-full justify-center rounded-md bg-sky-400 hover:bg-sky-300 px-3 py-2 text-sm font-semibold text-sky-700 shadow-sm ring-1 ring-inset ring-gray-300  sm:mt-0 sm:w-auto"
                        onClick={() => toggleState("editPT")}
                      >
                        {pTState.editPT ? "Edit" : "View"}
                      </button>
                      <button
                        type="button"
                        className={`mt-3 mr-2 inline-flex w-full justify-center rounded-md ${pTState.PTStatus
                          ? "bg-red-300 text-red-700 hover:bg-red-200"
                          : "bg-green-300 text-green-700 hover:bg-green-200"
                          } px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300  sm:mt-0 sm:w-auto`}
                        onClick={togglePTStatus}
                      >
                        {pTState.PTStatus ? "Inactivate" : "Activate"}
                      </button>
                    </div>
                  ) : null}
                </div>
                <hr />
                <div className="mt-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-12">
                      <div className="pb-12 ">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
                          <div className="sm:col-span-2 sm:col-start-1">
                            <Controller
                              name="division"
                              control={control}
                              render={({ field }) => (
                                <SelectTag
                                  {...field}
                                  label={"Division *"}
                                  name="division"
                                  optionData={divisionNames}
                                  error={errors.division}
                                  disable={pTState.PTUpdateStatus}
                                  onSelect={(selectedOption) =>
                                    field.onChange(selectedOption)
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <Controller
                              name="durationType"
                              control={control}
                              render={({ field }) => (
                                <SelectTag
                                  {...field}
                                  label={"Duration Type *"}
                                  name="durationType"
                                  optionData={durationTypes}
                                  error={errors.durationType}
                                  disable={pTState.PTUpdateStatus}
                                  onSelect={(selectedOption) =>
                                    field.onChange(selectedOption)
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <FormField
                              disable={pTState.PTUpdateStatus}
                              label="Duration *"
                              name="duration"
                              id="duration"
                              type="text"
                              register={register}
                              errors={errors}
                              placeholder="Enter duration"
                            />
                          </div>
                          <div className="sm:col-span-2 sm:col-start-1">
                            <FormField
                              disable={pTState.PTUpdateStatus}
                              label="Personal Training Name * "
                              name="persionalTrainingName"
                              id="Persional-Training-Name"
                              type="text"
                              register={register}
                              errors={errors}
                              placeholder="Persional Training Name"
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <Controller
                              name="selectTrainer"
                              control={control}
                              render={({ field }) => (
                                <SelectTag
                                  {...field}
                                  label={"Select Trainer *"}
                                  name="selectTrainer"
                                  optionData={personalTrainerNames}
                                  error={errors.selectTrainer}
                                  disable={pTState.PTUpdateStatus}
                                  onSelect={(selectedOption) =>
                                    field.onChange(selectedOption)
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <FormField
                              disable={pTState.PTUpdateStatus}
                              label="Session *  "
                              name="session"
                              id="session"
                              type="text"
                              register={register}
                              errors={errors}
                              placeholder="session"
                            />
                          </div>
                          <div className="sm:col-span-3">
                            <FormField
                              disable={pTState.editPT}
                              label="Personal Training Cost (In Rs.) *  "
                              name="personalTrainingCost"
                              id="pT-cost"
                              type="text"
                              register={register}
                              errors={errors}
                              placeholder="Personal Training Cost"
                            />
                          </div>
                          <div className="sm:col-span-3">
                            <FormField
                              disable={pTState.editPT}
                              label="Minimum Cost (In Rs.) *"
                              name="minPTCost"
                              id="minimun-cost"
                              type="text"
                              register={register}
                              errors={errors}
                              placeholder="Enter minimum cost"
                            />
                          </div>

                          <div className="sm:col-span-2 sm:col-start-1">
                            <ToggleSwitch
                              label="Freeze Status"
                              checked={pTState.freezeStatus}
                              disabled={pTState.editPT}
                              onChange={() => toggleState("freezeStatus")}
                            />
                          </div>
                          {pTState.freezeStatus ? (
                            <>
                              <div className="sm:col-span-2 sm:col-start-1">
                                <FormField
                                  disable={pTState.editPT}
                                  label="Freeze Fee * "
                                  name="freezeFee"
                                  id="freeze-fee"
                                  type="text"
                                  register={register}
                                  errors={errors}
                                  placeholder="freeze Fee"
                                />
                              </div>
                              <div className="sm:col-span-2">
                                <FormField
                                  disable={pTState.editPT}
                                  label="Max Freeze Duration(In Days) *"
                                  name="maxFreezeDuration"
                                  id="max-freeze-duration"
                                  type="text"
                                  register={register}
                                  errors={errors}
                                  placeholder="Max Freeze Duration"
                                />
                              </div>
                              <div className="sm:col-span-2">
                                <FormField
                                  disable={pTState.editPT}
                                  label=" Min Freeze Duration(In Days) *"
                                  name="minfreezeDuration"
                                  id="min-freeze-duration"
                                  type="text"
                                  register={register}
                                  errors={errors}
                                  placeholder="Min Freeze Duration "
                                />
                              </div>
                            </>
                          ) : null}
                          <div className="sm:col-span-2 sm:col-start-1">
                            <ToggleSwitch
                              label="Upgrade Status"
                              checked={pTState.upgradeStatus}
                              disabled={pTState.editPT}
                              onChange={() => toggleState("upgradeStatus")}
                            />
                          </div>
                          {pTState.upgradeStatus ? (
                            <div className="sm:col-span-3 sm:col-start-1">
                              <FormField
                                disable={pTState.editPT}
                                label=" No. Of Days To Upgrade *"
                                name="noDaysToUpgrade"
                                id="no-days-to-upgrade"
                                type="text"
                                register={register}
                                errors={errors}
                                placeholder="No. Of Days To Upgrade"
                              />
                            </div>
                          ) : null}

                          <div className="sm:col-span-2 sm:col-start-1">
                            <ToggleSwitch
                              label="Transfer Status  "
                              checked={pTState.transferStatus}
                              disabled={pTState.editPT}
                              onChange={() => toggleState("transferStatus")}
                            />
                          </div>
                          {pTState.transferStatus ? (
                            <>
                              <div className="sm:col-span-3 sm:col-start-1">
                                <FormField
                                  disable={pTState.editPT}
                                  label="No. Of Days To Transfer *"
                                  name="noDaysToTransfer"
                                  id="no-days-to-transfer"
                                  register={register}
                                  errors={errors}
                                  placeholder="No. Of Days To Transfer"
                                />
                              </div>
                              <div className="sm:col-span-3">
                                <FormField
                                  disable={pTState.editPT}
                                  label="Transfer Fee*"
                                  name="transferFee"
                                  id="transfer-fee"
                                  register={register}
                                  errors={errors}
                                  placeholder="Transfer Fee"
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {!pTState.editPT ? (
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse border-t border-gray-900/10 pt-6 ">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                          disabled={pTState.editPT}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          // onClick={() => setIsModalOpen(false)}
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : null}
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PersonalTrainerModal;
