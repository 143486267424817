// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAuth";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../utils/constants/Prompts";

import { getAllDesignations } from "./settingsDesignationSlice";

export const getAllDesignationsThunk = async (
  getDesignationURL,
  adminId,
  thunkAPI
) => {
  try {
    const response = await customFetch.get(`${getDesignationURL}/${adminId}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_FETCHING_DESIGNATION);
  }
};

export const createNewDesignationThunk = async (
  createNewDesignationURL,
  designation,
  thunkAPI
) => {
  try {
    const { adminId } = designation;
    const response = await customFetch.post(
      `${createNewDesignationURL}`,
      designation
    );
    thunkAPI.dispatch(getAllDesignations(adminId));
    return SUCCESS_MESSAGES.DESIGNATION_ADDED;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.DESIGNATION_ADD_ERROR);
  }
};
