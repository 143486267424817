import React from "react";
import moment from 'moment';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { DURATION_FILTER, MISCELLANEOUS_LABELS } from '../../../utils/constants/keywords';
import { dateFormat, timeConstants, weekDays } from '../../../utils/constants/enums';
import Logo from '../../../assets/images/logo.png';
import { getFormattedDate } from '../../../utils/formatter/dateFormatter';
import profileImg from '../../../assets/images/profileImg.png';
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../../utils/constants/Prompts";

// Constants
const SCALE = 2;
const IMAGE_FORMAT = 'image/jpeg';
const IMAGE_QUALITY = 0.95;
const PDF_ORIENTATION = 'portrait';
const PDF_UNIT = 'pt';
const PDF_FORMAT = 'a4';
const PDF_COMPRESS = true;
const INITIAL_Y_POSITION = 30;
const IMAGE_TYPE = 'JPEG';
const PDF_OUTPUT_TYPE = 'blob';
const PDF_IMAGE_COMPRESSION = 'FAST';

export const generateAttendancePDF = async (pdfContentRef) => {
    try {
        if (!pdfContentRef.current) {
            toast.error(ERROR_MESSAGES.ERROR_GENERATING_ATTENDANCE_REPORT);
        }

        const canvas = await html2canvas(pdfContentRef.current, {
            scale: SCALE,
            logging: false,
            useCORS: true,
            allowTaint: true,
        });

        const imgData = canvas.toDataURL(IMAGE_FORMAT, IMAGE_QUALITY);

        const pdf = new jsPDF({
            orientation: PDF_ORIENTATION,
            unit: PDF_UNIT,
            format: PDF_FORMAT,
            compress: PDF_COMPRESS,
        });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width / SCALE;
        const imgHeight = canvas.height / SCALE;

        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = INITIAL_Y_POSITION;

        pdf.addImage(imgData, IMAGE_TYPE, imgX, imgY, imgWidth * ratio, imgHeight * ratio, null, PDF_IMAGE_COMPRESSION);

        // If the content doesn't fit on one page, add more pages
        if (imgHeight * ratio > pdfHeight - imgY) {
            let heightLeft = imgHeight * ratio;
            let position = -pdfHeight + imgY;

            while (heightLeft > 0) {
                position = position + pdfHeight;
                heightLeft -= pdfHeight;
                pdf.addPage();
                pdf.addImage(imgData, IMAGE_TYPE, imgX, position, imgWidth * ratio, imgHeight * ratio, null, PDF_IMAGE_COMPRESSION);
            }
        }

        return pdf.output(PDF_OUTPUT_TYPE);
    } catch (error) {
        toast.error(ERROR_MESSAGES.ERROR_GENERATING_ATTENDANCE_REPORT);
    }
};

const PDFContent = React.forwardRef(({ memberDetails, attendanceData, clubDetails, currMonth, stats }, ref) => {
    const formatTime = (time) => {
        if (!time || time === MISCELLANEOUS_LABELS.STATUS_NA) return MISCELLANEOUS_LABELS.STATUS_NA;
        return moment(time, timeConstants.timeFormatWithSeconds).format(timeConstants.timeFormatWithAmPm);
    };

    const generateCalendar = () => {
        const daysInMonth = moment(currMonth).daysInMonth();
        const firstDayOfMonth = moment(currMonth).startOf(DURATION_FILTER.MONTH).day();

        const numRows = Math.ceil((daysInMonth + firstDayOfMonth) / 7);

        return (
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                <thead>
                    <tr style={{ backgroundColor: '#4a5568', color: 'white' }}>
                        {weekDays.map(day => (
                            <th key={day} style={{ padding: '10px', border: '1px solid #e2e8f0', width: '14.28%' }}>{day}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: numRows }).map((_, weekIndex) => (
                        <tr key={weekIndex}>
                            {Array.from({ length: 7 }).map((_, dayIndex) => {
                                const dayCount = weekIndex * 7 + dayIndex - firstDayOfMonth + 1;
                                const isValidDay = dayCount > 0 && dayCount <= daysInMonth;
                                const date = isValidDay ? moment(currMonth).date(dayCount).format(dateFormat.ISO) : null;
                                const attendanceStatus = isValidDay ? attendanceData.logs?.find(log => log.logdate === date) : null;

                                const cellStyle = {
                                    padding: '10px',
                                    border: '1px solid #e2e8f0',
                                    width: '14.28%',
                                    height: '120px',
                                    verticalAlign: 'top',
                                    textAlign: 'left',
                                    position: 'relative',
                                };

                                return (
                                    <td key={dayIndex} style={cellStyle}>
                                        {isValidDay && (
                                            <>
                                                <div style={{ fontWeight: 'bold', position: 'absolute', top: '5px', left: '5px' }}>{dayCount}</div>
                                                {attendanceStatus && (
                                                    attendanceStatus.memberPresent ? (
                                                        <>
                                                            <div style={{ fontSize: '13px', color: '#008000', fontWeight: 'semibold', textAlign: 'center', marginTop: '32px' }}>
                                                                Check In: {formatTime(attendanceStatus.logTime)}
                                                            </div>
                                                            <div style={{ fontSize: '13px', color: '#008000', textAlign: 'center' }}>
                                                                Check Out: {formatTime(attendanceStatus.checkOutTime)}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div style={{ color: '#FF5252', textAlign: 'center', marginTop: '30px' }}>Absent</div>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div ref={ref} style={{ fontFamily: 'Arial, sans-serif', padding: '20px', width: '1000px' }}>
            <h1 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>Monthly Attendance Report - {currMonth.format('MMMM YYYY')}</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px', marginTop: '50px' }}>
                <img src={clubDetails?.clubLogo || profileImg} style={{ height: '100px' }} alt="Logo" />
            </div>
            <div style={{ marginBottom: '50px' }}>
                <p><strong>Member Name:</strong> {memberDetails.mobileNo}</p>
                <p><strong>Date of Joining:</strong> {getFormattedDate(memberDetails.createdAt)}</p>
                <p><strong>Mobile Number:</strong> +91 {memberDetails.username}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '50px' }}>
                {stats.map(item => (
                    <div key={item.name} style={{ textAlign: 'center', padding: '10px', backgroundColor: '#e2e8f0', borderRadius: '5px', width: '30%' }}>
                        <h3>{item.name}</h3>
                        <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{item.noOfDays}</p>
                    </div>
                ))}
            </div>
            {generateCalendar()}
            <div colSpan={7} className="text-right">
                <div className='flex justify-end items-center'>
                    <div className='mr-2'>Powered By</div>
                    <img src={Logo} alt="Logo" className="h-6 mt-4" />
                </div>
            </div>
        </div>
    );
});

export default PDFContent;