// import customFetch from "../../../utils/axios/axios";
import customFetch from "../../../utils/axios/axiosAdmin";
import customInstanceForMarketing from "../../../utils/axios/axiosMarketting";
import { toast } from "react-toastify";

import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../../utils/constants/Prompts";

export const getAllActiveOffersThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      ERROR_MESSAGES.ERROR_FETCHING_ALL_ACTIVE_OFFERS
    );
  }
};

export const getAllActivePackagesThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      ERROR_MESSAGES.ERROR_FETCHING_ALL_ACTIVE_PACKAGES
    );
  }
};

export const getAllActivePersonalTrainingsThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      ERROR_MESSAGES.ERROR_FETCHING_ALL_ACTIVE_PT
    );
  }
};

export const getAllActiveGroupClassesThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      ERROR_MESSAGES.ERROR_FETCHING_ALL_ACTIVE_GC
    );
  }
};

export const getMembershipBasedOnIdThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      ERROR_MESSAGES.ERROR_FETCHING_MEMBERSHIP_ON_ID
    );
  }
};

export const checkForExistingMembershipThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};

export const postNewMembershipPurchaseRecordThunk = async (
  memberCreationUrl,
  membershipPurchaseBillingData,
  membershipPostUrl,
  membershipData,
  thunkAPI
) => {
  try {
    const response1 = await customFetch.post(
      memberCreationUrl,
      membershipPurchaseBillingData
    );
    if (response1.status == 200 || response1.status == 201) {
      const billingData = response1.data.billings[0];
      const membershipDataWithBillingId = membershipData.map((pack) => ({
        ...pack,
        billingId: billingData.id,
      }));
      const response2 = await customFetch.post(
        membershipPostUrl,
        membershipDataWithBillingId
      );
      if (response2.status == 200 || response2.status == 201) {
        return { billData: response1.data.billings[0], message: SUCCESS_MESSAGES.MEMBERSHIP_PURCHASED };
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.MEMBERSHIP_PURCHASE_ERROR);
  }
};

export const postRenewMembershipPurchaseRecordThunk = async (
  billingPostUrl,
  membershipPurchaseBillingData,
  membershipPostUrl,
  membershipData,
  thunkAPI
) => {
  try {
    const response1 = await customFetch.post(
      billingPostUrl,
      membershipPurchaseBillingData
    );
    if (response1.status == 200 || response1.status == 201) {
      const billingData = response1.data;
      const membershipDataWithBillingId = membershipData.map((pack) => ({
        ...pack,
        billingId: billingData.id,
      }));
      const response2 = await customFetch.post(
        membershipPostUrl,
        membershipDataWithBillingId
      );
      if (response2.status == 200 || response2.status == 201) {
        return { billData: response1.data, message: SUCCESS_MESSAGES.MEMBERSHIP_RENEWED };
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.MEMBERSHIP_RENEW_ERROR);
  }
};


export const sendInvoiceThunk = async (
  SendInvoiceApi,
  formData,
  thunkAPI
) => {
  try {
    const response = await customInstanceForMarketing.post(
      SendInvoiceApi,
      formData
    )    
    if (response.status == 200 && response.data.result) {
      return SUCCESS_MESSAGES.INVOIVE_SEND
    }
    toast.error(ERROR_MESSAGES.FAIL_TO_SEND_INVOICE)
  } catch (error) {
    if(error.status == 400) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGES.INSUFFICIENT_MESSAGE_BALANCE);
    }
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.FAIL_TO_SEND_INVOICE);
  }
}
