import customFetch from "../axios/axiosPayments";
import { PAYMENTS_MODULE } from "../axios/paymentsPathUrls";

export default function createPaymentService(config) {
    return {
        loadRazorpayScript: async () => {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = "https://checkout.razorpay.com/v1/checkout.js";
                script.onload = () => resolve(true);
                script.onerror = () => resolve(false);
                document.body.appendChild(script);
            });
        },

        createOrder: async (data) => {
            try {
                const response = await customFetch.post(PAYMENTS_MODULE.CREATE_ORDER, data)
                return response.data;
            } catch (error) {
                throw new Error('Failed to create order');
            }
        },

        verifyPayment: async (paymentData) => {
            try {
                const response = await customFetch.post(PAYMENTS_MODULE.VERIFY_ORDER, paymentData)
                return response.data;
            } catch (error) {
                throw new Error('Payment verification failed');
            }
        }
    };
}