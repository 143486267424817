import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getAllSubscriptions,getCurrentSubscriptionByAdminId,getSubscriptionById,getFreeTrialDetails } from "../../features/subscription/subscriptionslice"

const frequencies = [
  {
    value: "currentPlan",
    label: "Current Plan",
    priceSuffix: "15 days",
    active: true,
  },
  { value: "upgrade", label: "Upgrade", priceSuffix: "/month", active: false },
];

export default function SubscriptionPlans() {
  const navigate = useNavigate();
  const location = useLocation();
const dispatch = useDispatch();
const [freeTrial,setFreeTrial]= useState(false)
  const { subscriptions, activeSubscriptions, individualSub } = useSelector(
    (store) => store.subscriptions
  );
  const { user } = useSelector((store) => store.user);

  const [plan, setPlan] = useState(() => {
    return location.pathname.includes("upgradePlans")
      ? "upgrade"
      : "currentPlan";
  });

  const handleClick = (value) => {
    setPlan(value);
    if (value === "currentPlan") {
      navigate("/subscriptions/plans");
    } else {
      navigate("/subscriptions/upgradePlans");
    }
  };

  const handlePlans = (id) => {
    navigate(`/subscriptions/buySubscription/${id}`);
  };

  useEffect(() => {
    dispatch(getAllSubscriptions());
    dispatch(getCurrentSubscriptionByAdminId(user.userId));
  }, [dispatch, user.userId]);

  useEffect(() => {
    if (activeSubscriptions.length > 0) {
        if (activeSubscriptions[0].purchaseType !== "FREE_TRIAL") { setFreeTrial(false); dispatch(getSubscriptionById(activeSubscriptions[0].subscriptionId)); }
        else { 
            setFreeTrial(true)
            dispatch(getFreeTrialDetails());
        }
    } 
  }, [activeSubscriptions, dispatch]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes("upgradePlans")) {
      setPlan("upgrade");
    } else if (currentPath.includes("plans")) {
      setPlan("currentPlan");
    }
  }, [location.pathname]);

  const filtteredSubscriptions =
    (subscriptions ?? []).length === 0
      ? []
      : subscriptions.map((sub) => {
          return {
            name: sub.planName,
            id: sub.id,
            href: "#",
            price: { basePrice: sub.basePrice, finalPrice: sub.finalPrice },
            gst: "GST",
            features: sub.features,
            ...sub,
          };
        });

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-2">
      <div className="flex justify-center mt-6 sm:mt-2">
        <fieldset aria-label="Payment frequency">
          <RadioGroup
            value={frequencies.find((f) => f.value === plan)}
            onChange={(selectedFrequency) =>
              handleClick(selectedFrequency.value)
            }
            className="grid grid-cols-2 gap-x-1 rounded-lg text-center text-sm font-semibold leading-5 ring-1 ring-inset ring-gray-300"
          >
            {frequencies.map((option) => (
              <button
                key={option.value}
                value={option}
                className={`cursor-pointer rounded-lg px-8 py-2 leading-6 ${
                  plan === option.value
                    ? "bg-indigo-600 text-white"
                    : "text-gray-500"
                }`}
                onClick={() => handleClick(option.value)}
              >
                {option.label}
              </button>
            ))}
          </RadioGroup>
        </fieldset>
      </div>
      {plan == "upgrade" && (
        <div className="isolate mx-auto mt-6 grid max-w-full grid-cols-1 gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {filtteredSubscriptions.map((tier) => (
            <div
              key={tier.id}
              className={"ring-1 ring-gray-300 rounded-lg p-8 xl:p-10"}
            >
              <div className="flex flex-col items-center justify-center gap-x-4 gap">
                <h3
                  id={tier.id}
                  className={"text-gray-900 text-2xl font-semibold leading-8"}
                >
                  {tier.name}
                </h3>
                <p className=" text-sm leading-6 text-center text-gray-600">
                  {tier.numberOfMonths}Months
                </p>
              </div>
              <p className="mt-4 text-xl leading-6 text-center text-gray-600 line-through">
                ₹{tier.price.basePrice}
              </p>
              <p className="mt-4 text-4xl flex font-bold leading-6 items-center justify-center text-gray-900">
                <span>₹{tier.price.finalPrice}</span>
                <span className="text-xl font-semibold text-gray-500 pl-2">
                  {" "}
                  +
                </span>
                <span className="text-xl font-semibold text-gray-500">
                  {tier.gst}
                </span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={
                  "mt-6 block rounded-md px-3 pb-2 text-orange-600 text-center text-lg font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                }
              >
                Plan Details
              </a>

              <ul
                role="list"
                className="overflow-y-auto h-32 mt-8 space-y-3 text-md leading-6 text-gray-600 xl:mt-10"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3 h-3">
                    <CheckIcon
                      aria-hidden="true"
                      className="h-6 w-5 flex-none text-indigo-600"
                    />
                    {feature.name}
                  </li>
                ))}
              </ul>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={
                  " bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                }
                onClick={() => handlePlans(tier.id)}
              >
                Get Started
              </a>
            </div>
          ))}
        </div>
      )}
      {plan == "currentPlan" && (
        <div
          className={`grid gap-6 place-items-center mx-auto mt-6 max-w-screen-lg grid-cols-1`}
        >
          <div
            key={individualSub.id}
            className="w-full max-w-sm ring-1 ring-gray-300 rounded-lg p-8 xl:p-10"
          >
            <div className="flex flex-col items-center justify-center gap-x-4 gap">
              <h3
                id={individualSub.id}
                className={"text-gray-900 text-2xl font-semibold leading-8"}
              >
                {individualSub.planName}
              </h3>
              <p className=" text-sm leading-6 text-center text-gray-600">{individualSub.numberOfMonths} { freeTrial?"Days" :"Months" }</p>
            </div>
            { !freeTrial && <><p className="mt-4 text-xl leading-6 text-center text-gray-600 line-through">₹{individualSub.basePrice}</p> <p className="mt-4 text-4xl flex font-bold leading-6 items-center justify-center text-gray-900">
                                <span>₹{individualSub.finalPrice}</span>
                                <span className='text-xl font-semibold text-gray-500 pl-2'> +</span>
                                <span className='text-xl font-semibold text-gray-500'>{individualSub.gst}</span>
                            </p></>}
            <a
              href={individualSub.href}
              aria-describedby={individualSub.id}
              className="mt-6 block rounded-md px-3 py-4 text-orange-600 text-center text-lg font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Plan Details
            </a>
            <ul
              role="list"
              className="mt-8 space-y-3 text-md leading-6 text-gray-600 xl:mt-10"
            >
              {individualSub.features.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    aria-hidden="true"
                    className="h-6 w-5 flex-none text-indigo-600"
                  />
                  {feature.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}