export const VALIDATION_ERROR_MESSAGES = {
    MOBILE_NO_EXIST: 'Mobile No. already exists!',
    CASH_AMOUNT_REQUIRED: '*Cash amount is required',
    CASH_AMOUNT_VALIDATATION: '*Cash Amount should be a valid number less than or equal to Grand Total',
    CASH_AMOUNT_SHOULD_BE_NUMBER: '*Cash Amount should only contain numbers',
    EXISTING_MEMBERSHIP_ERROR: '*Already membership is existing, select the startDate greater than previous membership endDate:',
    DISCOUNT_VALIDATION: '*Total amount after discount must be between',
    BILLING_DATE_REQUIRED: '*Billing date is required.',
    DUE_DATE_REQUIRED: '*Due date is required',
    PAYMENT_TYPE_REQUIRED: '*Please select the payment method',
    START_DATE_REQUIRED: '*Start date is required',
    START_DATE_AFTER_BILLING_DATE: '*Start date of membership should be after billing date.',
    DISCOUNT_ERROR: '*Discount should be the minimum and maximum cost of package.',
    CASH_AMOUNT_VALIDATATION_WITH_BALANCE: '*Cash Amount should be a valid number less than or equal to balance amount.',
    INVALID_DUE_DATE: '*Due date cannot be in past.',
    INVALID_PAYMENT_DATE: '*Payment date must be on or between billing date and current date.',
    INVALID_BILLING_DATE: '*Billing date cannot be in future.',
    VALIDATE_OFFER_DATES: 'Offer activation failed. The current date falls outside the valid offer period.',
    OFFER_DISCOUNT_VALIDATION: "Discount cannot be greater than maximum cost",
    FILE_UPLOAD_SUCCESS_MESSAGE: "File uploaded successfully. Ready to submit."
}