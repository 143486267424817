import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusIcon } from "@heroicons/react/24/outline";

import GenericTable from "../superAdminComponents/genericComponents/GenericTable";
import PageHeader from "../superAdminComponents/genericComponents/pageHeader";
import Button from "../superAdminComponents/genericComponents/Button";
import { HELPER_MODE_LABELS, UI_TEXTS } from "../utils/constants/keywords";
import CustomLoader from "./CustomLoader";
import CreatePromoCode from "../superAdminComponents/promo/CreatePromoCode";


const promos = [
    {
        "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "promoCode": "string",
        "promoName": "string",
        "startDate": "2024-11-26",
        "endDate": "2024-11-26",
        "discountType": "string",
        "discountValue": 0,
        "isActive": true,
        "createdAt": "2024-11-26",
        "updatedAt": "2024-11-26"
    }
]
// Define columns configuration
const columns = [
    { key: "promoName", header: "PROMO CODE" },
    { key: "createdAt", header: "DATE CREATED" },
    { key: "startDate", header: "VALIDITY" },
    { key: "endDate", header: "EXPIRY DATE" },
    { key: "isActive", header: "STATUS", type: "Toggle" },
    { key: "action", header: "ACTION", type: "delete" },
    { key: "view", header: "", type: "view" },
];

export default function Subscriptions() {
    const [mode, setMode] = useState(HELPER_MODE_LABELS.LIST_MODE);
    const [selectedPromo, setSelectedPromo] = useState(null);

    const dispatch = useDispatch()

    const handleToggleChange = (data) => {
        console.log("update status");
        
    };

    const handlePromoCode = () => {
        setSelectedPromo(null);
        setMode(HELPER_MODE_LABELS.CREATE_MODE);
    };

    const handlePromoCodeClick = (promo) => {
        setSelectedPromo(promo);
        setMode(HELPER_MODE_LABELS.EDIT_MODE);
    };

    const handleBackToList = () => {
        setMode(HELPER_MODE_LABELS.LIST_MODE);
        setSelectedPromo(null);
        // dispatch(setExistingPromoName()) // dispatch setExistingPromoName 
    };

    useEffect(() => {
        console.log("call get all promos api");  
    }, [dispatch])

    const createButton = (
        <Button buttonText={UI_TEXTS.CREATE_PROMO} onClick={handlePromoCode} type='button' disabled={false} className={'flex items-center gap-2.5 rounded-md bg-blue-700 h-12 px-8 py-2.5 text-center font-inter text-sm leading-[16.94px] text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'} icon={<PlusIcon className="h-4 w-4" />} />
    );

    if (mode === HELPER_MODE_LABELS.LIST_MODE) {
        return (
            <div className="m-2 sm:m-0">
                <PageHeader title={UI_TEXTS.PROMO_CODES} rightContent={createButton} />
                <GenericTable
                    columns={columns}
                    data={promos}
                    onToggleChange={handleToggleChange}
                    onRowClick={handlePromoCodeClick}
                />
            </div>
        );
    }

    return (
        <CreatePromoCode
            mode={mode}
            initialData={selectedPromo}
            onBack={handleBackToList}
        />
    );
}