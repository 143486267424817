import * as yup from 'yup';

import { getFormattedDate } from '../../../utils/formatter/dateFormatter';

export const freezeMembershipSchema = (minFreezeDuration, maxFreezeDuration, minStartDate, maxStartDate) => yup.object().shape({
    startDate: yup
        .date()
        .required('Start date is required')
        .typeError('Start date required')
        .test('is-between-start-dates', `Start date must be between ${getFormattedDate(minStartDate)} and ${getFormattedDate(maxStartDate)}`, function (value) {
            if (!value) return false;

            const { path, createError } = this;
            const startDate = new Date(value).setHours(0, 0, 0, 0);
            const minDate = new Date(minStartDate).setHours(0, 0, 0, 0);
            const maxDate = new Date(maxStartDate).setHours(0, 0, 0, 0);

            if (startDate >= minDate && startDate <= maxDate) {
                return true;
            }

            return createError({ path, message: `Start date must be between ${getFormattedDate(minStartDate)} and ${getFormattedDate(maxStartDate)}` })
        }),
    noOfDays: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required('Number of days is required')
        .min(minFreezeDuration, `Minimum freeze duration is ${minFreezeDuration} days`)
        .max(maxFreezeDuration, `Maximum freeze duration is ${maxFreezeDuration} days`)
        .typeError('Number of days must be a valid number')
        .test('is-between-duration', `Number of days to be frozen must be between ${minFreezeDuration} and ${maxFreezeDuration}`, value => {
            return value >= minFreezeDuration && value <= maxFreezeDuration;
        }),
    reason: yup
        .string()
});
