// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"white\" viewBox=\"0 0 24 24\"><path d=\"M19 4h-1V2h-2v2H8V2H6v2H5c-1.1 0-1.99.9-1.99 2L3 20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM7 12h5v5H7z\"/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-date-picker {
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  padding: 10px 40px 10px 10px;
  color: #fff;
  border-radius: 5px;
}

.custom-date-picker::-webkit-calendar-picker-indicator {
  opacity: 0;
  display: block;
  position: absolute;
  right: 10px;
  width: 20px;
  height: 100%;
  cursor: pointer;
}

.custom-date-picker:before {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  right: 10px;
  top: 55%;
  transform: translateY(-50%);
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,kBAAkB;EAClB,4BAA4B;EAC5B,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gDAA4R;EAC5R,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;EAC3B,oBAAoB;AACtB","sourcesContent":[".custom-date-picker {\n  appearance: none;\n  -webkit-appearance: none;\n  position: relative;\n  padding: 10px 40px 10px 10px;\n  color: #fff;\n  border-radius: 5px;\n}\n\n.custom-date-picker::-webkit-calendar-picker-indicator {\n  opacity: 0;\n  display: block;\n  position: absolute;\n  right: 10px;\n  width: 20px;\n  height: 100%;\n  cursor: pointer;\n}\n\n.custom-date-picker:before {\n  content: url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"white\" viewBox=\"0 0 24 24\"><path d=\"M19 4h-1V2h-2v2H8V2H6v2H5c-1.1 0-1.99.9-1.99 2L3 20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM7 12h5v5H7z\"/></svg>');\n  position: absolute;\n  right: 10px;\n  top: 55%;\n  transform: translateY(-50%);\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
