import { MISCELLANEOUS_LABELS } from "../../utils/constants/keywords";
import DetailSection from "../People/shared/DetailSection";

const EMPTY_FEILD = "NO DATA";

const EnquiryTable = ({ Title, Description, JSON, LabelMapper }) => {
  const transformedData = Object.keys(JSON).map(key => ({
    label: LabelMapper[key],
    value: JSON[key] || MISCELLANEOUS_LABELS.NOT_AVAILABLE
  }));
  return (
    <DetailSection title={Title} fields={transformedData} />
  );
};

export default EnquiryTable;
