import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {} from "../../utils/browserStorage/sessionStorage";
import { SUBSCRIPTION_MODULE } from "../../utils/axios/pathUrls";

import {
  getAllSubscriptionsThunk,
  getSubscriptionByIdThunk,
  buySubscriptionThunk,
  getCurrentSubscriptionByAdminIdThunk,
  getLatestSubscriptionThunk,
  getFreeTrialDetailsThunk,
} from "./subscriptionThunk";

const initialState = {
  isLoading: false,
  subscriptions: [],
  individualSub: { features: [] },
  subscriptionOrder: {},
  activeSubscriptions: [],
  latestEndDate: null,
};

export const getAllSubscriptions = createAsyncThunk(
  "subscription/getAllSubscriptions",
  async (thunkAPI) => {
    return getAllSubscriptionsThunk(
      SUBSCRIPTION_MODULE.GET_ALL_SUBSCRIPTIONS,
      thunkAPI
    );
  }
);
export const getSubscriptionById = createAsyncThunk(
  "subscription/getSubscriptionById",
  async (id, thunkAPI) => {
    return getSubscriptionByIdThunk(
      `${SUBSCRIPTION_MODULE.GET_SUBSCRIPTION_BY_ID}${id}`,
      thunkAPI
    );
  }
);

export const buySubscription = createAsyncThunk(
  "subscription/buySubscription",
  async ({ id, payload }, thunkAPI) => {
    return buySubscriptionThunk(
      `${SUBSCRIPTION_MODULE.BUY_SUBSCRIPTION}${id}/subscription-managers`,
      payload,
      thunkAPI
    );
  }
);

export const getCurrentSubscriptionByAdminId = createAsyncThunk(
  "subscription/getCurrentSubscriptionByAdminId",
  async (id, thunkAPI) => {
    return getCurrentSubscriptionByAdminIdThunk(
      `${SUBSCRIPTION_MODULE.GET_CURRENT_SUBSCRIPTION}${id}`,
      thunkAPI
    );
  }
);
export const getLatestSubscription = createAsyncThunk(
  "subscription/getLatestSubscription",
  async (id, thunkAPI) => {
    return getLatestSubscriptionThunk(
      `${SUBSCRIPTION_MODULE.GET_LATEST_SUBSCRIPTION}${id}`,
      thunkAPI
    );
  }
);

export const getFreeTrialDetails = createAsyncThunk(
  "subscription/getFreeTrialDetails",
  async (id, thunkAPI) => {
    return getFreeTrialDetailsThunk(
      `${SUBSCRIPTION_MODULE.GET_ALL_FEATURES}`,
      thunkAPI
    );
  }
);

const subscriptionslice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSubscriptions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.subscriptions = payload;
      })
      .addCase(getAllSubscriptions.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getSubscriptionById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubscriptionById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.individualSub = payload;
      })
      .addCase(getSubscriptionById.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(buySubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(buySubscription.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(buySubscription.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getCurrentSubscriptionByAdminId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getCurrentSubscriptionByAdminId.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;

          state.activeSubscriptions = payload.subscriptions;
        }
      )
      .addCase(
        getCurrentSubscriptionByAdminId.rejected,
        (state, { payload }) => {
          state.isLoading = false;
          toast.error(payload);
        }
      )
      .addCase(getLatestSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLatestSubscription.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.latestEndDate = payload.subscriptions[0].endDate;
      })
      .addCase(getLatestSubscription.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getFreeTrialDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFreeTrialDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log(payload);
        const freeTrail = {
          id: "000-000-000-000-000",
          planName: "freeTrial",
          numberOfMonths: 30,
          basePrice: 0,
          finalPrice: 0,
          gst: 0,
          features: payload.map((e) => e),
        };
        state.individualSub = freeTrail;
      })
      .addCase(getFreeTrialDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export default subscriptionslice.reducer;
