import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { getImagesOnDivisionName } from "../../../pages/DivisionImages";
import { getFormattedDate } from "../../../utils/formatter/dateFormatter";
import { processMembershipAdditionToCart, processOfferMembershipAdditionToCart, processMembershipRemovalFromCart, processOfferMembershipRemovalFromCart, setMembershipDiscount, setStartAndEndDate } from "./gymMembershipPurchaseMethods";
import { setActiveTab, setCart, setOfferCart, setOpenCartModal } from "../../../features/people/gymMembershipPurchaseFeatures/gymMembershipPurchaseSlice";
import TopNavigationTabs from "./TopNavigationTabs";
import offerImage from "../../../assets/images/Division/offer.png";
import CartContainer from "./CartContainer";
import { MEMBERSHIP_CATEGORIES } from "../../../utils/constants/keywords";
import { ALERT_MESSAGES } from "../../../utils/constants/Prompts";

const GymMembershipPurchaseContainer = ({ memberId, type, mode }) => {

  const { allActiveOffers, allActivePackages, allActivePersonalTrainings, allActiveGroupClasses, offersActive, packagesActive, personalTrainingActive, groupClassesActive } = useSelector((store) => store.getAllMemberships);
  const { activeTab, openCartModal, cart, offerCart } = useSelector((store) => store.gymMembershipPurchase)
  const { user } = useSelector((store) => store.user);
  const adminId = user.userId;
  const [existingMembershipError, setExistingMembershipError] = useState([])
  const [membershipDiscountErrors, setMembershipDiscountErrors] = useState([])
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCart([]));
    dispatch(setOfferCart([]));
  }, [])

  const handlePopUpOpen = () => {
    if (activeTab === MEMBERSHIP_CATEGORIES.OFFERS ? offerCart.length > 0 : cart.length > 0) {
      dispatch(setOpenCartModal(true))
    }
    else {
      toast.warning(ALERT_MESSAGES.CART)
      dispatch(setOpenCartModal(false))
    }
  };

  const handleTabClick = (tab) => {
    dispatch(setActiveTab(tab))
  };

  const handleModalClose = () => {
    dispatch(setOpenCartModal(false))
  };

  useEffect(() => {
    const tabMappings = {
      Offers: {
        data: allActiveOffers,
        formatter: (offer) => ({
          'id': offer.id,
          'image': offerImage,
          'Name': offer.nameOfTheOffer,
          "Start Date": getFormattedDate(offer.startDate),
          "End Date": getFormattedDate(offer.endDate),
          "Price (₹)": offer.cost,
          "Discount (₹)": offer.discount
        })
      },
      Packages: {
        data: allActivePackages,
        formatter: (pkg) => ({
          'id': pkg.id,
          'image': getImagesOnDivisionName(pkg.divisionName),
          'Division': pkg.divisionName,
          'Duration': `${pkg.durationTime} ${pkg.durationType}`,
          "Price (₹)": pkg.cost
        })
      },
      PersonalTrainings: {
        data: allActivePersonalTrainings,
        formatter: (pt) => ({
          'id': pt.id,
          'image': getImagesOnDivisionName(pt.divisionName),
          'Name': pt.ptName,
          'Division': pt.divisionName,
          'Duration': `${pt.durationTime} ${pt.durationType}`,
          "Price (₹)": pt.cost,
          'Session': pt.session
        })
      },
      GroupClasses: {
        data: allActiveGroupClasses,
        formatter: (gc) => ({
          'id': gc.id,
          'image': getImagesOnDivisionName(gc.divisionName),
          'Name': gc.groupClassName,
          'Division': gc.divisionName,
          'Duration': `${gc.classTime} ${gc.classType}`,
          "Price (₹)": gc.cost,
          'Session': gc.session
        })
      }
    };

    const { data, formatter } = tabMappings[activeTab] || { data: [], formatter: () => ({}) };
    const formattedData = Array.isArray(data) ? data.map(formatter) : [];
    setTableData(formattedData);
  }, [activeTab, allActiveOffers, allActivePackages, allActivePersonalTrainings, allActiveGroupClasses]);


  const addMembershipToCart = (id, type, index) => {
    processMembershipAdditionToCart(id, type, index, setExistingMembershipError, cart, allActivePackages, allActivePersonalTrainings, allActiveGroupClasses, packagesActive, personalTrainingActive, groupClassesActive, dispatch)
  }

  const removeMembershipFromCart = (id, type) => {
    // handleModalClose();
    processMembershipRemovalFromCart(id, type, cart, packagesActive, personalTrainingActive, groupClassesActive, dispatch)
  };

  const handleOfferCart = async (id) => {
    processOfferMembershipAdditionToCart(id, offerCart, allActiveOffers, dispatch)
  };

  const removeOfferFromCart = (id) => {
    processOfferMembershipRemovalFromCart(id, offersActive, offerCart, dispatch)
  };

  const handleStartDate = (startDate, index, noOfDays, existingMembershipEndDate) => {
    setStartAndEndDate(startDate, index, noOfDays, existingMembershipEndDate, activeTab, existingMembershipError, setExistingMembershipError, cart, offerCart, dispatch)
  }

  const handleDiscount = (discount, index, maxCost, minCost, cost) => {
    setMembershipDiscount(discount, index, maxCost, minCost, cost, activeTab, cart, offerCart, membershipDiscountErrors, setMembershipDiscountErrors, dispatch)
  }

  return (
    <>
      <div>
        <div>
          <TopNavigationTabs activeTab={activeTab} handleTabClick={handleTabClick} handlePopUpOpen={handlePopUpOpen} offerCart={offerCart} cart={cart} />
        </div>

        <div className="flex mt-14 md:mt-6 w-full h-full flex-col ">
          <div className="px-0 sm:px-4">
            <div className="mt-4 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  {tableData.length > 0 && (
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-200">
                        <tr>
                          {Object.keys(tableData[0]).map((key) => (
                            <th key={key} scope="col" className="py-3 text-left text-sm px-4 md:px-2  font-semibold text-gray-900">
                              {key === 'image' ? 'Membership' : key === 'id' ? '' : key}
                            </th>
                          ))}
                          <th scope="col" className="py-3 text-left text-sm font-semibold text-gray-900">
                            Add to cart
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {tableData.map((item, index) => (
                          <tr key={index}>
                            {Object.entries(item).map(([key, value], index) => (
                              <td key={index} className="whitespace-nowrap py-3 px-4 md:px-2 text-sm text-gray-500">
                                {key === 'image' ? (
                                  <img className="h-11 w-11 flex-shrink-0 rounded-md border border-gray-200" src={value} alt="Avatar" />
                                ) : (
                                  key === 'id' ? (
                                    <></>
                                  )
                                    :
                                    (
                                      value
                                    )
                                )}
                              </td>
                            ))}
                            <td className="whitespace-nowrap py-3 text-sm px-4 md:px-2 text-center text-gray-500">
                              <button
                                type="button"
                                className="font-medium flex text-indigo-600 hover:text-indigo-500"
                                onClick={() => activeTab === 'Offers' ? handleOfferCart(item.id) : addMembershipToCart(item.id, activeTab, index)}
                              >
                                Add
                                <PlusSmallIcon className="ml-1.5 h-5 w-5" aria-hidden="true" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
              {tableData.length == 0 && (
                <div className="ml-6 mt-4">No Data Available</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {openCartModal && (cart.length > 0 || offerCart.length > 0) && (
        <CartContainer
          isModalOpen={openCartModal}
          handleModalClose={handleModalClose}
          type={type}
          mode={mode}
          cart={cart}
          offerCart={offerCart}
          activeTab={activeTab}
          removeMembershipFromCart={removeMembershipFromCart}
          removeOfferFromCart={removeOfferFromCart}
          adminId={adminId}
          memberId={memberId}
          handleStartDate={handleStartDate}
          existingMembershipError={existingMembershipError}
          handleDiscount={handleDiscount}
          membershipDiscountErrors={membershipDiscountErrors}
          setMembershipDiscountErrors={setMembershipDiscountErrors}
        />
      )}
    </>
  );
};

export default GymMembershipPurchaseContainer;
