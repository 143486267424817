import axios from "axios";
import {
  getUserFromSessionStorage,
  removeUserFromSessionStorage,
} from "../browserStorage/sessionStorage";

const customFetch = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BIOMETRIC,
});

customFetch.interceptors.request.use((config) => {
  const user = getUserFromSessionStorage();
  if (user) {
    config.headers["Authorization"] = `Bearer ${user.accessToken}`;
  } else {
    config.headers["Content-Type"] = "application/json";
  }
  return config;
});

//token expiry:
customFetch.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorMessage = error?.response?.data?.message;

    if (errorMessage === "Access is Unauthorized") {
      removeUserFromSessionStorage();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default customFetch;
