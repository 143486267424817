import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Space, ConfigProvider } from 'antd';

const AdvanceDateRangePicker = ({ dateRange, setDateRange, setSelectedButton, externalStyle }) => {
    const dateFormat = 'YYYY-MM-DD';
    const displayFormat = 'DD-MM-YYYY';


    const [startDateSelected, setStartDateSelected] = useState(false);
    const [endDateSelected, setEndDateSelected] = useState(false);


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        setStartDate(dateRange[0] ? dayjs(dateRange[0]) : null);
        setEndDate(dateRange[1] ? dayjs(dateRange[1]) : null);
    }, [dateRange]);

    const formatDate = (date, format) => date ? date.format(format) : null;

    const handleDateChange = (date, isStartDate) => {
        if (date) {
            const newStartDate = isStartDate ? date : startDate;
            const newEndDate = isStartDate ? endDate : date;

            if (isStartDate) {
                setStartDateSelected(true);
                setStartDate(newStartDate);
            } else {
                setEndDateSelected(true);
                setEndDate(newEndDate);
            }

            setDateRange([
                formatDate(newStartDate, dateFormat),
                formatDate(newEndDate, dateFormat)
            ]);

            if (setSelectedButton) {
                setSelectedButton("");
            }
        } else {
            // Handle the case when date is cleared
            if (isStartDate) {
                setStartDateSelected(false);
                setStartDate(null);
            } else {
                setEndDateSelected(false);
                setEndDate(null);
            }

            setDateRange([
                formatDate(isStartDate ? null : startDate, dateFormat),
                formatDate(isStartDate ? endDate : null, dateFormat)
            ]);
        }
    };

    const onStartRangeChange = (date) => handleDateChange(date, true);
    const onEndRangeChange = (date) => handleDateChange(date, false);

    const disabledStartDate = (current) => {
        return current && current > dayjs().endOf('day');
    };

    const disabledEndDate = (current) => {
        return current && current > dayjs().endOf('day');
    };


    return (
        <>
            <Space direction="horizontal" size={12}>
                <DatePicker
                    disabledDate={disabledStartDate}
                    placement="bottomLeft"
                    style={{
                        width: '100%',
                        backgroundColor: "black",
                        color: "white",
                        borderColor: "#676765"
                    }}
                    popupClassName="custom-datepicker-popup"
                    className="custom-datepicker"
                    size="large"
                    value={startDateSelected ? dayjs(dateRange[0], dateFormat) : null}
                    defaultValue={startDateSelected ? dayjs(dateRange[0], dateFormat) : null}
                    format={displayFormat}
                    onChange={onStartRangeChange}
                    placeholder="DD-MM-YYYY"
                />
                <div>-</div>
                <DatePicker
                    disabledDate={disabledEndDate}
                    placement="bottomRight"
                    style={{
                        width: '100%',
                        backgroundColor: "black",
                        color: "white",
                        borderColor: "#676765"
                    }}
                    popupClassName="custom-datepicker-popup"
                    className="custom-datepicker"
                    size="large"
                    value={endDateSelected ? dayjs(dateRange[1], dateFormat) : null}
                    defaultValue={endDateSelected ? dayjs(dateRange[1], dateFormat) : null}
                    format={displayFormat}
                    onChange={onEndRangeChange}
                    placeholder="DD-MM-YYYY"
                />
            </Space>
        </>
    );
};

export default AdvanceDateRangePicker;