import * as yup from 'yup';

const dateFormatRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
const formatDate = (dateString) => {
    const date = new Date(dateString.getFullYear(), dateString.getMonth(), dateString.getDate());
    return date;
};
// Validation Schema
export const createPromoCodeSchema = yup.object({
    promoName: yup.string()
        .required("Please enter the promo name")
        .matches(/^[A-Za-z0-9\s]*$/, "Promo name can only contain letters, numbers and spaces"),
    startDate: yup.string()
        .required("Start Date is required")
        .matches(
            dateFormatRegex,
            "Start Date must be a valid date in the format YYYY-MM-DD"
        )
        .test(
            "is-future-date",
            "Start Date must be greater than or equal to present date",
            function (value) {
                const today = new Date();
                const date = new Date(value);
                return formatDate(date) >= formatDate(today);
            }
        ),
    endDate: yup.string()
        .required("End Date is required")
        .matches(
            dateFormatRegex,
            "End Date must be a valid date in the format YYYY-MM-DD"
        )
        .test(
            "is-greater-than-start",
            "End Date must be greater than Start Date",
            function (value) {
                const startDate = this.parent.startDate;
                const formattedStartDate = new Date(startDate);
                const date = new Date(value);
                return formatDate(date) > formatDate(formattedStartDate);
            }
        ),
    discountType: yup.string()
        .required('Please select a discount type'),
    discountValue: yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .typeError('Please enter a valid number')
        .required('Amount/Percentage is required')
        .when('discountType', {
            is: 'PERCENTAGE',
            then: (schema) => schema
                .min(0, 'Percentage must be between 0 and 100')
                .max(100, 'Discount percentage cannot be more than 100%'),
            otherwise: (schema) => schema
                .min(0, 'Amount must be greater than or equal to 0')
        }),
    assignTo: yup.array()
        .min(1, 'Please select at least one club')
        .required('Please select at least one club')
});

