import customFetch from "../../utils/axios/axiosAdmin";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../utils/constants/Prompts";
import { getMemberOrderDetails } from "./memberOrderDetailsSlice";

export const getPackageByDivisionThunk = async (url, thunkAPI) => {
    try {
      const response = await customFetch.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_FETCHING_ALL_ACTIVE_PACKAGES);
    }
  };
  export const getPTByDivisionThunk = async (url, thunkAPI) => {
    try {
      const response = await customFetch.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_FETCHING_ALL_ACTIVE_PT);
    }
  };
  export const postMemberUpgradeThunk = async (billingPostUrl, billingPayload, membershipPostUrl, membershipData,updatePurchasedMembershipUrl,updatedMembershipData, thunkAPI) => {
    try {
      const response1 = await customFetch.post(billingPostUrl, billingPayload)
      if (response1.status === 200 || response1.status === 201) {
          const billingData = response1.data
          // membershipData.billingId = billingData?.id
          const membershipDataWithBillingId = membershipData.map((pack) => ({
            ...pack,
            billingId: billingData.id,
          }));
          try {
              const response2 = await customFetch.post(membershipPostUrl, membershipDataWithBillingId)
              if (response2.status === 200 || response2.status === 201) {
                  try {
                      const response3 = await customFetch.put(updatePurchasedMembershipUrl, updatedMembershipData)
                      if (response3.status === 200 || response3.status === 201) {
                          thunkAPI.dispatch(getMemberOrderDetails({ adminId: billingData?.adminId, memberId: billingData?.memberId }))
                        return { billData: billingData, message: SUCCESS_MESSAGES.UPGRADE_MEMBERSHIP};
                      }
                  } catch (error) {
                      return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_UPDATING_PURCHASED_MEMBERSHIP)
                  }
              }
          } catch (error) {
              return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_POSTING_UPGRADE_MEMBERSHIP)
          }
  
      }
  } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_POSTING_BILLING_DATA)
  }
  } 