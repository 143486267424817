// import customFetch from "../../utils/axios/axios";
import customFetch from "../../utils/axios/axiosAdmin";
import customFetchAuth from "../../utils/axios/axiosAuth";

export const getAllFeaturesThunk = async (getFeaturesURL, thunkAPI) => {
  try {
    const response = await customFetch.get(`${getFeaturesURL}`);
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.log("error", error);
    return thunkAPI.rejectWithValue("Failed to fetch features");
  }
};

export const craeteFeaturesThunk = async (
  createFeaturesURL,
  data,
  thunkAPI
) => {
  try {
    const response = await customFetch.post(`${createFeaturesURL}`, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status === 409) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
    return thunkAPI.rejectWithValue("Failed to create features");
  }
};

export const editFeaturesThunk = async (editFeaturesURL, data, thunkAPI) => {
  try {
    const response = await customFetch.put(`${editFeaturesURL}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("Failed to Edit features");
  }
};

export const checkFeaturesThunk = async (checkFeaturesURL, thunkAPI) => {
  try {
    const response = await customFetch.get(`${checkFeaturesURL}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("Failed to check features");
  }
};

export const editSettingsDetailsThunk = async (
  editSettingsDetails,
  data,
  thunkAPI
) => {
  try {
    const response = await customFetchAuth.put(`${editSettingsDetails}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("Failed to editSettings Details");
  }
};
