import customFetch, {
  customInstanceRegistration,
} from "../../utils/axios/axiosAuth";

import customAdmin from "../../utils/axios/axiosAdmin";
import { ERROR_MESSAGES } from "../../utils/constants/messages";

// export const loginUserThunk = async (url, user, thunkAPI) => {
//   try {
//     const { email, password } = user;
//     const payload = `username=${email}&&password=${password}&&grant_type=password`;
//     const resp = await customFetch.post(url, payload);
//     return resp.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue(error.response.data.error_description);
//   }
// };

export const loginUserThunk = async (url, user, thunkAPI) => {
  try {
    const { email, password } = user;
    const payload = {
      username: email,
      password: password,
    };
    const resp = await customFetch.post(url, payload);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
};

export const checkForDuplicateUsernameThunk = async (url, username) => {
  try {
    const resp = await customFetch.get(`${url}/${username}`);
    const { success } = resp.data;
    if (resp.status === 200 && success) {
      return true;
    }
    return false;
  } catch (error) {
    //Unrequired
  }
};

export const registerUserDetailsThunk = async (
  url1,
  userDetails,
  url2,
  clubProfileDetails,
  reset,
  thunkAPI
) => {
  try {
    const response = await customInstanceRegistration.post(url1, userDetails);
    const { id } = response.data;
    clubProfileDetails.append("adminId", id);
    const response2 = await customInstanceRegistration.post(
      url2,
      clubProfileDetails
    );
    reset();
    return;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
};

export const getAdminLogoThunk = async (url, userId, thunkAPI) => {
  try {
    const response = await customFetch.get(`${url}/${userId}`);
    const data = response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(ERROR_MESSAGES.ADMIN_LOGO_FETCH_FAIL);
  }
};

export const generateTemporaryPasswordThunk = async (
  tempPasswordURL,
  payload,
  thunkAPI
) => {
  try {
    const response = await customFetch.post(
      `${tempPasswordURL}${payload.identifier}`
    );
    const data = response.data;
    if (!data.userId) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
};

export const forgotPasswordSubmissionThunk = async (
  submitForgotPasswordURL,
  payload,
  thunkAPI
) => {
  try {
    const response = await customInstanceRegistration.post(
      `${submitForgotPasswordURL}`,
      payload
    );
    return;
  } catch (error) {
    const errorResponse = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(errorResponse);
  }
};

export const changePasswordSubmissionThunk = async (
  changePasswordURL,
  payload,
  reset,
  thunkAPI
) => {
  try {
    const response = await customFetch.post(`${changePasswordURL}`, payload);
    return reset();
  } catch (error) {
    const errorResponse = error.response.data;
    return thunkAPI.rejectWithValue(errorResponse);
  }
};

export const getSubscriptionStatusThunk = async (url, thunkAPI) => {
  try {
    const response = await customAdmin.get(`${url}`);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    const errorResponse = error.response.data;
    return thunkAPI.rejectWithValue(errorResponse);
  }
};
