import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import PageHeader from '../genericComponents/pageHeader';
import FieldSet from '../genericComponents/FieldSet';
import Button from '../genericComponents/Button';
import { FormField } from '../genericComponents/FormField';
import { createSubscriptionPlansSchema } from './CreateSubscriptionPlansSchema';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFeatures } from '../../superAdminFeatures/settings/featuresSlice';
import { checkForExistingPlanName, createSubscriptions, updateSubscriptionOnId } from '../../superAdminFeatures/subscriptions/superAdminSubscriptionsSlice';
import { HELPER_MODE_LABELS, UI_TEXTS } from '../../utils/constants/keywords';
import { discountTypes } from '../../utils/constants/enums';

export const PLAN_STRINGS = {
    PLAN_DETAILS: "Plan Details",
    PLAN_NAME: "Plan Name",
    NUMBER_OF_MONTHS: "Number of Month",
    BASE_PRICE: "Base Price",
    FINAL_PRICE: "Final Price",
    DISCOUNT_TYPE: "Discount Type",
    AMOUNT_PERCENTAGE: "Amount/Percentage",
    CANCEL: "Cancel",
    SUBMIT: "Submit"
};

export const PLAN_OBJECT_KEYS = {
    BASE_PRICE: "basePrice",
    FINAL_PRICE: "finalPrice",
    DISCOUNT_TYPE: "discountType",
    DISCOUNT_VALUE: "discountValue",
    FEATURES: "features",
    PLAN_NAME: "planName"
}

export const FORM_FIELDS = {
    PLAN_NAME: {
        name: "planName",
        type: "text",
        required: true,
        autoComplete: "off"
    },
    NUMBER_OF_MONTHS: {
        name: "numberOfMonths",
        type: "text",
        autoComplete: "off"
    },
    BASE_PRICE: {
        name: "basePrice",
        type: "text",
        required: true,
        autoComplete: "off"
    },
    FINAL_PRICE: {
        name: "finalPrice",
        type: "text",
        required: true,
        autoComplete: "off"
    },
    DISCOUNT_TYPE: {
        name: "discountType",
        type: "select",
        autoComplete: "off"
    },
    DISCOUNT_VALUE: {
        name: "discountValue",
        type: "text",
        required: true,
        autoComplete: "off"
    }
};

const TitleComponent = ({ mode, onBack }) => (
    <div className='mt-4 mb-8 flex'>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5 m-0.5 cursor-pointer"
            onClick={onBack}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
            />
        </svg>
        <div className='ml-1'>{mode === HELPER_MODE_LABELS.CREATE_MODE ? UI_TEXTS.CREATE_SUBSCRIPTION_PLAN : UI_TEXTS.EDIT_SUBSCRIPTION_PLAN}</div>
    </div>
);

const discountCalculators = {
    PERCENTAGE: (basePrice, discountValue) => {
        const calculatedPrice = basePrice - (basePrice * (discountValue / 100));
        return calculatedPrice;
    },
    FIXED_AMOUNT: (basePrice, discountValue) => {
        const calculatedPrice = basePrice - discountValue;
        return calculatedPrice;
    }
};

const isValidCalculationInput = (basePrice, discountType, discountValue) => {
    const numericBasePrice = Number(basePrice);
    const numericDiscountAmount = Number(discountValue);

    return (
        basePrice &&
        discountType &&
        discountValue &&
        !isNaN(numericBasePrice) &&
        !isNaN(numericDiscountAmount)
    );
};

const formatPrice = (price) => {
    return Number(price).toFixed(2);
};

const getValidBasePrice = (basePrice) => {
    const numericBasePrice = Number(basePrice);
    return !isNaN(numericBasePrice) ? formatPrice(numericBasePrice) : '';
};

const calculateFinalPrice = (basePrice, discountType, discountValue) => {
    const numericBasePrice = Number(basePrice);
    const numericDiscountAmount = Number(discountValue);

    const calculator = discountCalculators[discountType];
    return calculator?.(numericBasePrice, numericDiscountAmount) ?? numericBasePrice;
};

const usePriceCalculator = (basePrice, discountType, discountValue, setValue) => {
    React.useEffect(() => {
        const setFinalPrice = (price) => setValue(PLAN_OBJECT_KEYS.FINAL_PRICE, price);

        const handleValidCalculation = () => {
            const calculatedPrice = calculateFinalPrice(basePrice, discountType, discountValue);
            const isValidCalculation = !isNaN(calculatedPrice);

            isValidCalculation && setFinalPrice(formatPrice(calculatedPrice));
        };

        const handleBasePriceOnly = () => {
            const formattedBasePrice = getValidBasePrice(basePrice);
            setFinalPrice(formattedBasePrice);
        };

        isValidCalculationInput(basePrice, discountType, discountValue)
            ? handleValidCalculation()
            : handleBasePriceOnly();

    }, [basePrice, discountType, discountValue, setValue]);
};

const CreateSubscriptionPlans = ({ mode = HELPER_MODE_LABELS.CREATE_MODE, initialData = null, onBack }) => {
    const { allFeatures } = useSelector(store => store.features)
    const { existingPlanName } = useSelector(store => store.superAdminSubscription)
    const { status, message } = existingPlanName
    const dispatch = useDispatch()

    const initialValues = {
        planName: initialData?.planName || '',
        numberOfMonths: initialData?.numberOfMonths || '',
        basePrice: initialData?.basePrice || '',
        finalPrice: initialData?.finalPrice || '',
        discountType: initialData?.discountType || '',
        discountValue: initialData?.discountValue || '',
        features: initialData?.features || []
    }

    const { register, handleSubmit, formState: { errors, isSubmitting }, watch, setValue, reset, setError, clearErrors } = useForm({
        resolver: yupResolver(createSubscriptionPlansSchema),
        defaultValues: initialValues,
        mode: 'onChange'
    });

    useEffect(() => {
        dispatch(getAllFeatures())
    }, [dispatch])

    const initializeFormValues = (initialData, setValue) => {
        if (!initialData) return;

        const setFeatureValues = (features) => {
            const featuresList = features?.map(feature => feature.id)
            setValue(PLAN_OBJECT_KEYS.FEATURES, featuresList);
        };

        const setRegularValue = (key, value) => {
            setValue(key, value);
        };

        const fieldSetters = {
            [PLAN_OBJECT_KEYS.FEATURES]: (_, value) => setFeatureValues(value),
            default: setRegularValue
        };

        Object.entries(initialData).forEach(([key, value]) => {
            const setter = fieldSetters[key] || fieldSetters.default;
            setter(key, value);
        });
    };

    useEffect(() => {
        initializeFormValues(initialData, setValue);
    }, [initialData, setValue]);

    const validatePlanName = (status, setError, clearErrors, message) => {
        if (status) {
            setError(PLAN_OBJECT_KEYS.PLAN_NAME, {
                message: message,
            });
            return false;
        }
        // clearErrors(PLAN_OBJECT_KEYS.PLAN_NAME);
        return true;
    };

    useEffect(() => {
        validatePlanName(status, setError, clearErrors, message)
    }, [status, message])

    const basePrice = watch(PLAN_OBJECT_KEYS.BASE_PRICE);
    const discountType = watch(PLAN_OBJECT_KEYS.DISCOUNT_TYPE);
    const discountValue = watch(PLAN_OBJECT_KEYS.DISCOUNT_VALUE);

    usePriceCalculator(basePrice, discountType, discountValue, setValue);

    const onSubmit = async (data) => {
        if (!validatePlanName(status, setError, clearErrors, message)) {
            return;
        }
        try {
            const { features, ...subscriptionData } = data;
            const featuresData = {
                featureIds: features
            };

            if (mode === HELPER_MODE_LABELS.EDIT_MODE && initialData) {
                await dispatch(updateSubscriptionOnId({
                    id: initialData.id,
                    subscriptionData,
                    featuresData
                }));
            } else {
                await dispatch(createSubscriptions({ subscriptionData, featuresData }));
            }
            onBack();
        } catch (error) {
            console.error(error);
        }
    };

    const handleCancel = () => {
        reset();
        onBack();
    };

    const handlePlanNameChange = (e) => {
        const planName = e.target.value;
        dispatch(checkForExistingPlanName({ planName }))
    }

    return (
        <div>
            <PageHeader
                title={<TitleComponent mode={mode} onBack={onBack} />}
                rightContent={null}
            />
            <form onSubmit={handleSubmit(onSubmit)} className="bg-zinc-900 shadow-sm ring-1 ring-gray-900/5 sm:rounded-md md:col-span-2">
                <div className="px-4 py-6 sm:p-7">
                    <div className='pb-7 text-white font-normal text-xl'>
                        <span>Plan Details</span>
                    </div>

                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <FormField
                            label={PLAN_STRINGS.PLAN_NAME}
                            field={FORM_FIELDS.PLAN_NAME}
                            register={register}
                            error={errors.planName}
                            onInput={handlePlanNameChange}
                            mode={mode}
                        />
                        <FormField
                            label={PLAN_STRINGS.NUMBER_OF_MONTHS}
                            field={FORM_FIELDS.NUMBER_OF_MONTHS}
                            register={register}
                            error={errors.numberOfMonths}
                        />
                        <FormField
                            label={PLAN_STRINGS.BASE_PRICE}
                            field={FORM_FIELDS.BASE_PRICE}
                            register={register}
                            error={errors.basePrice}
                        />
                        <FormField
                            label={PLAN_STRINGS.FINAL_PRICE}
                            field={FORM_FIELDS.FINAL_PRICE}
                            register={register}
                            error={errors.finalPrice}
                        />
                        <FormField
                            label={PLAN_STRINGS.DISCOUNT_TYPE}
                            field={{
                                ...FORM_FIELDS.DISCOUNT_TYPE,
                                options: discountTypes
                            }}
                            register={register}
                            error={errors.discountType}
                        />
                        <FormField
                            label={PLAN_STRINGS.AMOUNT_PERCENTAGE}
                            field={FORM_FIELDS.DISCOUNT_VALUE}
                            register={register}
                            error={errors.discountValue}
                        />
                    </div>

                    <div className='mt-8 py-8 border-y-2 border-zinc-800'>
                        <FieldSet register={register} error={errors.features} allFeatures={allFeatures} selectedFeatures={watch('features')} />
                    </div>
                </div>

                <div className="flex items-center justify-end gap-x-4 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                    <Button
                        buttonText={PLAN_STRINGS.CANCEL}
                        onClick={() => handleCancel()}
                        type='button'
                        disabled={false}
                        className={'text-sm w-40 h-10 font-semibold leading-6 text-gray-300'}
                        icon={null}
                    />
                    <Button
                        buttonText={PLAN_STRINGS.SUBMIT}
                        type='submit'
                        disabled={isSubmitting}
                        className={'rounded-md w-40 h-10 bg-blue-700 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-800'}
                        icon={null}
                    />
                </div>
            </form>
        </div>
    );
};

export default CreateSubscriptionPlans;
