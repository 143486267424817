import {getUserFromSessionStorage} from "../../utils/browserStorage/sessionStorage";
import {roles} from "../../utils/constants/enums";
import {routeNames, routeToIconMapper} from "./SideBarRoutesConstants";

const roleBasedPaths = {
  ROLE_ADMIN: "",
  ROLE_SUPERADMIN: "/superadmin",
};

const navigationGenerator = (routeNames, role, language) => {
  let returnArrObj = routeNames.reduce((acc, name) => {
    const rolePrefix = role ? roleBasedPaths[role] || "" : "";

    // const href =
    //   name === "E-Market"
    //     ? "https://cheerful-sawine-287edb.netlify.app/"
    //     : rolePrefix + "/" + name.trim().split(" ").join("").toLowerCase();

    const href =
      rolePrefix + "/" + name.trim().split(" ").join("").toLowerCase();
    let obj = {
      name,
      href: href.replace(/\/+/g, "/"),
      icon: routeToIconMapper[name],
    };
    acc = [...acc, obj];
    return acc;
  }, []);
  return returnArrObj;
};

// const navigation = navigationGenerator(routeNames);
const navigation = subscriptionStatus => {
  const user = getUserFromSessionStorage();
  console.log("routes", subscriptionStatus);

  const userRole = user?.roles[0]?.name;
  let routes = routeNames(userRole);
  return navigationGenerator(routes, userRole);
};

export default navigation;
