import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import Webcam from "react-webcam";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import {
  gender,
  addressType,
  bloodGroups,
  relationships,
  source,
  imageConstants,
} from "../../utils/constants/enums";
import {
  closeModal,
  setBloodGroupSelected,
  setGenderSelected,
  setRelationShipSelected,
  setSourceSelected,
  setTypeOfAddressSelected,
} from "../../features/people/peopleCommonSlice";
import {
  DropdownSelect,
  FormInput,
  MeasurementInput,
  WebcamCapture,
  ImageUpload,
  FileUploadInput,
} from "./FormRow";
import { memberModelSchema } from "./PeopleModelSchema";
import {
  checkForIsMobileNoPresent,
  editMemberData,
  postMemberData,
  setMemberDetailFormValues,
} from "../../features/people/peopleMemberSlice";
import {
  handleWebcamCapture,
  handleImageFileChange,
  handleDocumentUpload,
  getFormattedMemberData,
} from "./ManageMemberFunctions";
import {
  DEFAULT_ROUTES,
  PEOPLE_MODULE_ROUTES,
} from "../../utils/constants/routes";
import {
  HELPER_MODE_LABELS,
  MEMBERSHIP_PURCHASE_TYPES,
  MISCELLANEOUS_LABELS,
  USER_MANAGEMENT_LABELS,
} from "../../utils/constants/keywords";
import {
  ERROR_MESSAGES,
  VALIDATION_ERROR_MESSAGES,
} from "../../utils/constants/Prompts";
import { updateLeadDetails } from "../../features/profile/leadProfileSlice";

export default function MemberModal({
  isModalOpen,
  setIsModalOpen,
  title,
  mode,
  convertToMember,
  leadId,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileNoPresent, initialValues } = useSelector(
    (store) => store.members?.memberFormState
  );
  const { user } = useSelector((store) => store.user);
  const adminId = user.userId;
  const {
    genderSelected,
    typeOfAddressSelected,
    sourceSelected,
    bloodGroupSelected,
    relationShipSelected,
  } = useSelector((store) => store.peopleCommonSlice);

  const [webcamOpen, setWebcamOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(
    initialValues?.imageFileInBlob || null
  );
  const [isAddressExpanded, setAddressExpanded] = useState(false);
  const [isMedicalExpanded, setMedicalExpanded] = useState(false);
  const [isEmergencyExpanded, setEmergencyExpanded] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleIsMobileNoPresent = (e) => {
    const username = e.target.value;
    dispatch(
      checkForIsMobileNoPresent({ adminId: adminId, username: username })
    );
  };
  const debouncedOnInput = debounce(handleIsMobileNoPresent, 1000);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    getValues,
    trigger,
    getFieldState,
    watch
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(memberModelSchema),
    defaultValues: initialValues,
  });

  const imageFile = watch(imageConstants.formFields.imageFile);
  const { isDirty } = getFieldState(imageConstants.formFields.imageFile);

  useEffect(() => {
    if (isMobileNoPresent) {
      setError(USER_MANAGEMENT_LABELS.USERNAME, {
        message: VALIDATION_ERROR_MESSAGES.MOBILE_NO_EXIST,
      });
    }
    if (!isMobileNoPresent) {
      clearErrors(USER_MANAGEMENT_LABELS.USERNAME);
    }
  }, [isMobileNoPresent, setError]);

  const setField = (
    value,
    dispatchFunction,
    setValueFunction,
    errorField,
    defaultObject
  ) => {
    if (value.name === HELPER_MODE_LABELS.SELECT_MODE) {
      dispatch(dispatchFunction(defaultObject));
      setValue(setValueFunction, "");
    } else {
      dispatch(dispatchFunction(value));
      setValue(setValueFunction, value.name);
      setError(errorField, "");
    }
  };

  const validateMobileNumber = (isMobileNoPresent, setError, clearErrors) => {
    if (isMobileNoPresent) {
      setError(USER_MANAGEMENT_LABELS.USERNAME, {
        message: VALIDATION_ERROR_MESSAGES.MOBILE_NO_EXIST,
      });
      return false;
    }
    clearErrors(USER_MANAGEMENT_LABELS.USERNAME);
    return true;
  };

  // Navigation helper
  const navigateToMembership = (navigate, mode, adminId, data, leadId) => {
    navigate(
      `${DEFAULT_ROUTES.APP}${PEOPLE_MODULE_ROUTES.GYM_MEMBERSHIP_PURCHASE}/${MEMBERSHIP_PURCHASE_TYPES.NEW_MEMBERSHIP.toLowerCase()}/${adminId}`,
      {
        state: {
          memberId: '',
          type: MEMBERSHIP_PURCHASE_TYPES.NEW_MEMBERSHIP.toLowerCase(),
          mode,
          data,
          leadId,
        },
      }
    );
  };

  // Member operations
  const handleCreateOrConvert = async (dispatch, navigate, mode, adminId, data, leadId) => {
    navigateToMembership(navigate, mode, adminId, data, leadId);
    dispatch(closeModal());
  };

  const handleEdit = async (dispatch, data) => {
    await dispatch(editMemberData(getFormattedMemberData(data)));
    window.location.reload();
  };

  const handleTransfer = async (dispatch, data, setIsModalOpen) => {
    await dispatch(postMemberData(getFormattedMemberData(data)));
    setIsModalOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      if (!validateMobileNumber(isMobileNoPresent, setError, clearErrors)) {
        return;
      }

      const actions = {
        [HELPER_MODE_LABELS.CONVERT_MODE]: async () => {
          await handleCreateOrConvert(dispatch, navigate, mode, adminId, data, leadId);
        },
        [HELPER_MODE_LABELS.CREATE_MODE]: async () => {
          await handleCreateOrConvert(dispatch, navigate, mode, adminId, data, leadId);
        },
        [HELPER_MODE_LABELS.EDIT_MODE]: async () => {
          await handleEdit(dispatch, data);
        },
        [HELPER_MODE_LABELS.TRANSFER_MODE]: async () => {
          await handleTransfer(dispatch, data, setIsModalOpen);
        },
      };

      const action = actions[mode];
      if (action) {
        await action();
      }
    } catch (error) {
      // Handle error appropriately
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 "
        //   initialFocus={setIsModalOpen}
        onClose={setIsModalOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform m-2 md:m-5 lg:mx-20 overflow-hidden w-screen md:max-w-5xl rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 "
                    onClick={() => setIsModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-gray-900 text-left"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-4 ">
                  <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-6 md:grid-cols-3">
                    <form
                      className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="px-4 py-6 sm:p-8">
                        <div className="col-span-full">
                          <label
                            htmlFor="photo"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          ></label>
                          <div className="mt-2 flex items-center gap-x-3">
                            {capturedImage ? (
                              <img
                                src={capturedImage}
                                alt="Image"
                                className="h-28 w-28 rounded-full object-cover"
                              />
                            ) : (
                              <UserCircleIcon
                                className="h-28 w-28 text-gray-300"
                                aria-hidden="true"
                              />
                            )}
                            <button
                              type="button"
                              className="rounded-md bg-orange-600 px-2.5 py-1.5 text-sm font-normal text-white shadow-sm  hover:bg-orange-300"
                              onClick={() => setWebcamOpen(true)}
                            >
                              Capture Image
                            </button>
                            <ImageUpload
                              setCapturedImage={setCapturedImage}
                              handleImageFileChange={handleImageFileChange}
                              setValue={setValue}
                              trigger={trigger}
                              setIsImageUploaded={setIsImageUploaded}
                            />
                          </div>
                          {webcamOpen && (
                            <WebcamCapture
                              setCapturedImage={setCapturedImage}
                              setWebcamOpen={setWebcamOpen}
                              Webcam={Webcam}
                              setValue={setValue}
                              handleWebcamCapture={handleWebcamCapture}
                              trigger={trigger}
                              setIsImageUploaded={setIsImageUploaded}
                            />
                          )}
                          {errors.imageFile && (
                            <p className="text-sm text-red-500 mt-2">
                              {errors.imageFile.message}
                            </p>
                          )}
                          {!errors.imageFile && (isDirty || isImageUploaded) && imageFile && (
                            <p className="text-sm text-green-500 mt-2">
                              {VALIDATION_ERROR_MESSAGES.FILE_UPLOAD_SUCCESS_MESSAGE}
                            </p>
                          )}
                        </div>
                        <div className="border-t mt-6">
                          <div className="font-semibold text-lg pt-2">
                            Personal Details
                          </div>
                          <div className="grid mt-6 grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
                            <FormInput
                              label="Mobile Number *"
                              type="text"
                              name="username"
                              error={errors?.username}
                              register={register}
                              className="sm:col-span-2"
                              handleIsMobileNoPresent={debouncedOnInput}
                            />
                            <FormInput
                              label="Full Name *"
                              type="text"
                              name="mobileNo"
                              error={errors?.mobileNo}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <DropdownSelect
                              label="Gender *"
                              items={gender}
                              selected={
                                gender.find(
                                  (g) => g.name === getValues("gender")
                                ) || genderSelected
                              }
                              setSelected={(value) =>
                                setField(
                                  value,
                                  setGenderSelected,
                                  "gender",
                                  "gender",
                                  gender[0]
                                )
                              }
                              errors={errors.gender}
                              className="sm:col-span-2"
                            />
                            <FormInput
                              label="Date Of Birth"
                              type="date"
                              name="dateOfBirth"
                              error={errors?.dateOfBirth}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <FormInput
                              label="Email Address"
                              type="email"
                              name="email"
                              error={errors?.email}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <DropdownSelect
                              label="Source"
                              items={source}
                              selected={
                                source.find(
                                  (s) => s.name === getValues("leadSource")
                                ) || sourceSelected
                              }
                              setSelected={(value) =>
                                setField(
                                  value,
                                  setSourceSelected,
                                  "leadSource",
                                  "leadSource",
                                  source[0]
                                )
                              }
                              errors={errors.leadSource}
                              className="sm:col-span-2"
                            />
                            <FormInput
                              label="Goal"
                              type="text"
                              name="goal"
                              error={errors?.goal}
                              register={register}
                              className="sm:col-span-2"
                            />
                            <MeasurementInput
                              label="Height"
                              name="height"
                              error={errors?.height}
                              register={register}
                              unit="cm"
                              className="sm:col-span-1 md:col-span-1 sm:col-start-3"
                            />
                            <MeasurementInput
                              label="Weight"
                              name="weight"
                              error={errors?.weight}
                              register={register}
                              unit="Kg"
                              className="sm:col-span-1"
                            />
                            <FormInput
                              label="Occupation"
                              type="text"
                              name="occupation"
                              error={errors?.occupation}
                              register={register}
                              className="col-span-full sm:col-span-2"
                            />
                          </div>
                          <div className="mt-10">
                            <div className="font-semibold text-lg pt-2">
                              Address Details
                              <button
                                type="button"
                                className="border rounded-lg ml-12 p-0.5 text-gray-500 focus:outline-none focus-visible:ring focus-visible:border-blue-300"
                                onClick={() =>
                                  setAddressExpanded(!isAddressExpanded)
                                }
                              >
                                {isAddressExpanded ? (
                                  <ChevronDownIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronRightIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                )}
                              </button>
                            </div>
                            {isAddressExpanded && (
                              <div className="grid mt-6 grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
                                <FormInput
                                  label="Address 1"
                                  type="text"
                                  name="address1"
                                  error={errors?.address1}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                                <FormInput
                                  label="Address 2"
                                  type="text"
                                  name="address2"
                                  error={errors?.address2}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                                <DropdownSelect
                                  label="Type Of Address"
                                  items={addressType}
                                  selected={
                                    addressType.find(
                                      (a) => a.name === getValues("typeOf")
                                    ) || typeOfAddressSelected
                                  }
                                  setSelected={(value) =>
                                    setField(
                                      value,
                                      setTypeOfAddressSelected,
                                      "typeOf",
                                      "typeOf",
                                      addressType[0]
                                    )
                                  }
                                  errors={errors.typeOf}
                                  className="sm:col-span-2"
                                />
                                <FormInput
                                  label="City"
                                  type="text"
                                  name="city"
                                  error={errors?.city}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                                <FormInput
                                  label="Land Mark"
                                  type="text"
                                  name="landMark"
                                  error={errors?.landMark}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                                <FormInput
                                  label="State"
                                  type="text"
                                  name="state"
                                  error={errors?.state}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                                <FormInput
                                  label="Pincode"
                                  type="text"
                                  name="pincode"
                                  error={errors?.pincode}
                                  register={register}
                                  className="sm:col-span-2"
                                />
                                <FileUploadInput
                                  label="Upload ID Proof"
                                  name="uploadIdFile"
                                  setValue={setValue}
                                  errors={errors.uploadIdFile}
                                  handleDocumentUpload={handleDocumentUpload}
                                  className="col-span-full sm:col-span-2"
                                  selectedDocument={selectedDocument}
                                  setSelectedDocument={setSelectedDocument}
                                />
                              </div>
                            )}
                            <div className="mt-10">
                              <div className="font-semibold text-lg pt-2">
                                Medical Details
                                <button
                                  type="button"
                                  className="border rounded-lg ml-12 p-0.5 text-gray-500 focus:outline-none focus-visible:ring focus-visible:border-blue-300"
                                  onClick={() =>
                                    setMedicalExpanded(!isMedicalExpanded)
                                  }
                                >
                                  {isMedicalExpanded ? (
                                    <ChevronDownIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ChevronRightIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  )}
                                </button>
                              </div>
                              {isMedicalExpanded && (
                                <div className="grid mt-6 grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
                                  <FormInput
                                    label="Surgeries"
                                    type="text"
                                    name="surgeries"
                                    error={errors?.surgeries}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                  <FormInput
                                    label="Medication"
                                    type="text"
                                    name="medications"
                                    error={errors?.medications}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                  <DropdownSelect
                                    label="Blood Group"
                                    items={bloodGroups}
                                    selected={
                                      bloodGroups.find(
                                        (b) =>
                                          b.name === getValues("bloodGroup")
                                      ) || bloodGroupSelected
                                    }
                                    setSelected={(value) =>
                                      setField(
                                        value,
                                        setBloodGroupSelected,
                                        "bloodGroup",
                                        "bloodGroup",
                                        bloodGroups[0]
                                      )
                                    }
                                    errors={errors.bloodGroup}
                                    className="sm:col-span-2"
                                  />
                                  <FormInput
                                    label="Notes"
                                    type="text"
                                    name="notes"
                                    error={errors?.notes}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                  <FormInput
                                    label="Allergies"
                                    type="text"
                                    name="allergies"
                                    error={errors?.allergies}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="mt-10">
                              <div className="font-semibold text-lg pt-2">
                                Emergency Details
                                <button
                                  type="button"
                                  className="border rounded-lg ml-6 p-0.5 text-gray-500 focus:outline-none focus-visible:ring focus-visible:border-blue-300"
                                  onClick={() =>
                                    setEmergencyExpanded(!isEmergencyExpanded)
                                  }
                                >
                                  {isEmergencyExpanded ? (
                                    <ChevronDownIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ChevronRightIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  )}
                                </button>
                              </div>
                              {isEmergencyExpanded && (
                                <div className="grid mt-6 grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-6">
                                  <FormInput
                                    label="Contact Person Name"
                                    type="text"
                                    name="contactPerson"
                                    error={errors?.contactPerson}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                  <FormInput
                                    label="Contact Number"
                                    type="text"
                                    name="contactNumber"
                                    error={errors?.contactNumber}
                                    register={register}
                                    className="sm:col-span-2"
                                  />
                                  <DropdownSelect
                                    label="Relationship"
                                    items={relationships}
                                    selected={
                                      relationships.find(
                                        (r) =>
                                          r.name === getValues("relationship")
                                      ) || relationShipSelected
                                    }
                                    setSelected={(value) =>
                                      setField(
                                        value,
                                        setRelationShipSelected,
                                        "relationship",
                                        "relationship",
                                        relationships[0]
                                      )
                                    }
                                    errors={errors.relationship}
                                    className="sm:col-span-2"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-12 items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-12 sm:px-8">
                        <button
                          type="button"
                          className="text-sm font-semibold leading-6 text-gray-900"
                          onClick={setIsModalOpen}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? `${MISCELLANEOUS_LABELS.PROCESSING}...` : MISCELLANEOUS_LABELS.SUBMIT}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
