import React, { useEffect, useState } from "react";
import { DocumentArrowUpIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";

import {
  getPackages,
  openUpdatePackageModal,
} from "../../features/membership/packageSlice";

import {
  getImageOnDivisionName,
  MembershipCardsContainer,
  PackageModal,
} from "../../components/Membership";

function ManagePackages() {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 9;

  const { packageLists } = useSelector((store) => store.packages);
  const { isPackageModalOpen } = useSelector((store) => store.packages);

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getPackages({
        adminId: user.userId,
        pageNo: currentPage,
        pageItems: itemsPerPage,
      })
    );
  }, [currentPage]);

  const handleViewPackage = (itemId) => {
    const currentItem = packageLists.content.find((e) => e.id === itemId);
    dispatch(
      openUpdatePackageModal({ isOpen: true, packageData: currentItem, currentPage: currentPage })
    );
  };

  const itemActions = (itemId) => {
    const actions = [];
    actions.push({
      text: "Update Packages",
      action: () => handleViewPackage(itemId),
    });
    return actions;
  };

  const manageListsItems =
    packageLists.content && packageLists.content.length > 0
      ? packageLists.content.map((item) => ({
        id: item.id,
        name: item.divisionName,
        division: item.divisionName,
        status: item.packageStatus,
        duration: item.durationTime + " " + item.durationType,
        cost:
          item.cost.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
          }) + " ₹",
        imageUrl: getImageOnDivisionName(item.divisionName),
        actions: itemActions(item.id),
      }))
      : [];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <MembershipCardsContainer
        items={manageListsItems}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={packageLists.totalPages}
      />
      {isPackageModalOpen && <PackageModal />}
    </>
  );
}

export default ManagePackages;
