import React, { useEffect } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";

import SubscriptionHeader from "../../components/adminSubscriptions/SubscriptionHeader";

const Subscription = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.endsWith("subscriptions") ||
      location.pathname.endsWith("subscriptions/")
    ) {
      navigate("plans", { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleBack = () => {
    if (location.pathname === "/subscriptions/plans") {
      navigate("/dashboard", { replace: true });
    } else {
      navigate(-1);
    }
  };

  const shouldShowBackButton = location.pathname !== "/subscriptions/plans";

  return (
    <>
      <div className="max-w-7xl sm:px-2 lg:px-5">
        <SubscriptionHeader
          onBack={handleBack}
          showBackButton={shouldShowBackButton}
        />
        <Outlet />
      </div>
    </>
  );
};

export default Subscription;
