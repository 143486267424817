import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import PageHeader from "../genericComponents/pageHeader";
import Button from "../genericComponents/Button";
import { FormField } from "../genericComponents/FormField";
import { useDispatch, useSelector } from "react-redux";
import { HELPER_MODE_LABELS, UI_TEXTS } from "../../utils/constants/keywords";
import { discountTypes } from "../../utils/constants/enums";
import { createPromoCodeSchema } from "./CreatePromoCodeSchema";

export const PROMO_STRINGS = {
  PROMO_CODE: "Promo Code",
  PROMO_NAME: "Promo Name",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  DISCOUNT_TYPE: "Discount Type",
  AMOUNT_PERCENTAGE: "Amount/Percentage",
  ASSIGN_TO: "Assign To",
  CANCEL: "Cancel",
  SUBMIT: "Submit",
};

export const PROMO_OBJECT_KEYS = {
  START_DATE: "startDate",
  END_DATE: "endDate",
  DISCOUNT_TYPE: "discountType",
  DISCOUNT_VALUE: "discountValue",
  ASSIGN_TO: "assignTo",
  PROMO_NAME: "promoName",
};

export const FORM_FIELDS = {
  PROMO_NAME: {
    name: "promoCode",
    type: "text",
    required: true,
    autoComplete: "off",
  },
  START_DATE: {
    name: "startDate",
    type: "date",
    required: true,
    autoComplete: "off",
  },
  END_DATE: {
    name: "endDate",
    type: "date",
    required: true,
    autoComplete: "off",
  },
  DISCOUNT_TYPE: {
    name: "discountType",
    type: "select",
    // required: true,
    autoComplete: "off",
  },
  DISCOUNT_VALUE: {
    name: "discountValue",
    type: "text",
    required: true,
    autoComplete: "off",
  },
  ASSIGN_TO: {
    name: "assignTo",
    type: "select",
    required: true,
    autoComplete: "off",
  },
};

const TitleComponent = ({ mode, onBack }) => (
  <div className="mt-4 mb-8 flex">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-5 m-0.5 cursor-pointer"
      onClick={onBack}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5 8.25 12l7.5-7.5"
      />
    </svg>
    <div className="ml-1">
      {mode === HELPER_MODE_LABELS.CREATE_MODE
        ? UI_TEXTS.CREATE_PROMO_CODE
        : UI_TEXTS.EDIT_PROMO_CODE}
    </div>
  </div>
);
const CreatePromoCode = ({
  mode = HELPER_MODE_LABELS.CREATE_MODE,
  initialData = null,
  onBack,
}) => {
  const dispatch = useDispatch();

  const initialValues = {
    promoName: initialData?.promoName || "",
    startDate: initialData?.startDate || "",
    endDate: initialData?.endDate || "",
    discountType: initialData?.discountType || "",
    discountValue: initialData?.discountValue || "",
    assignTo: initialData?.assignTo || [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(createPromoCodeSchema),
    defaultValues: initialValues,
    mode: "onChange",
  });

  const initializeFormValues = (initialData, setValue) => {
    if (!initialData) return;

    const setRegularValue = (key, value) => {
      setValue(key, value);
    };

    const fieldSetters = {
      default: setRegularValue,
    };

    Object.entries(initialData).forEach(([key, value]) => {
      const setter = fieldSetters.default;
      setter(key, value);
    });
  };

  useEffect(() => {
    initializeFormValues(initialData, setValue);
  }, [initialData, setValue]);

  const onSubmit = async (data) => {
    console.log(data);
  };

  const handleCancel = () => {
    reset();
    onBack();
  };

  const handlePromoNameChange = (e) => {
    const promoName = e.target.value;
  };

  return (
    <div>
      <PageHeader
        title={<TitleComponent mode={mode} onBack={onBack} />}
        rightContent={null}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-zinc-900 shadow-sm ring-1 ring-gray-900/5 sm:rounded-md md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-7">
          <div className="pb-7 text-white font-normal text-xl">
            <span>Plan Details</span>
          </div>

          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <FormField
              label={PROMO_STRINGS.PROMO_NAME}
              field={FORM_FIELDS.PROMO_NAME}
              register={register}
              error={errors.promoCode}
              onInput={handlePromoNameChange}
              mode={mode}
            />
            <FormField
              label={PROMO_STRINGS.START_DATE}
              field={FORM_FIELDS.START_DATE}
              register={register}
              error={errors.startDate}
            />
            <FormField
              label={PROMO_STRINGS.END_DATE}
              field={FORM_FIELDS.END_DATE}
              register={register}
              error={errors.endDate}
            />
            <FormField
              label={PROMO_STRINGS.DISCOUNT_TYPE}
              field={{
                ...FORM_FIELDS.DISCOUNT_TYPE,
                options: discountTypes,
              }}
              register={register}
              error={errors.discountType}
            />
            <FormField
              label={PROMO_STRINGS.AMOUNT_PERCENTAGE}
              field={FORM_FIELDS.DISCOUNT_VALUE}
              register={register}
              error={errors.discountValue}
            />
            <FormField
              label={PROMO_STRINGS.ASSIGN_TO}
              field={{
                ...FORM_FIELDS.ASSIGN_TO,
                options: [{ value: "", label: "" }],
              }}
              register={register}
              error={errors.assignTo}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-4 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <Button
            buttonText={PROMO_STRINGS.CANCEL}
            onClick={() => handleCancel()}
            type="button"
            disabled={false}
            className={
              "text-sm w-40 h-10 font-semibold leading-6 text-gray-300"
            }
            icon={null}
          />
          <Button
            buttonText={PROMO_STRINGS.SUBMIT}
            type="submit"
            disabled={isSubmitting}
            className={
              "rounded-md w-40 h-10 bg-blue-700 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-800"
            }
            icon={null}
          />
        </div>
      </form>
    </div>
  );
};

export default CreatePromoCode;
