import React from "react";
import { useDispatch } from "react-redux";
import * as yup from 'yup';
import SharedLeadModal from './SharedLeadModal';
import { handleCloseLeadModal, updateLeadDetails } from "../../features/profile/leadProfileSlice";
import { USER_MANAGEMENT_LABELS } from "../../utils/constants/keywords";

const SCHEMA_VALIDATION_ERRORS = {
    NOTES_VALIDATION: 'Comment is required',
};

const CloseLeadModal = ({ isModalOpen, title, leadId }) => {
    const dispatch = useDispatch();

    const initialValues = {
        shortNotes: '',
        followUpStatus: USER_MANAGEMENT_LABELS.CLOSE_LEAD_STATUS,
    };

    const validationSchema = yup.object().shape({
        shortNotes: yup.string().required(SCHEMA_VALIDATION_ERRORS.NOTES_VALIDATION),
    });

    const onSubmit = (data) => {
        dispatch(updateLeadDetails({ leadId, updateDetails: data }));
        dispatch(handleCloseLeadModal(false));
    };

    const formFields = [
        {
            id: "shortNotes",
            label: "Comments",
            name: "shortNotes",
            type: "text",
            required: true
        }
    ];

    return (
        <SharedLeadModal
            isModalOpen={isModalOpen}
            title={title}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            handleModalClose={() => dispatch(handleCloseLeadModal(false))}
            formFields={formFields}
        />
    );
};

export default CloseLeadModal;
