import { toast } from "react-toastify";
import customFetch from "../../utils/axios/axiosAdmin";
import customInstanceForMarketing from "../../utils/axios/axiosMarketting";
import { ALERT_MESSAGES, ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../utils/constants/Prompts";

export const getMemberAttendanceDataThunk = async (url, thunkAPI) => {
    try {
        const response = await customFetch.get(url);
        if (response.status === 200 || Response.status === 201) {
            return response.data
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_FETCHING_ATTENDANCE_DATA);
    }
};


export const sendAttendanceReportThunk = async (
    SendInvoiceApi,
    formData,
    thunkAPI
) => {
    try {
        const response = await customInstanceForMarketing.post(
            SendInvoiceApi,
            formData
        )
        if (response.status == 200 && response.data?.result) {
            return SUCCESS_MESSAGES.ATTENDANCE_REPORT_SEND
        }
        if (response.status == 200 && response.data == ALERT_MESSAGES.INSUFFICIENT_WHATSAPP_CREDITS) {
            toast.warning(ERROR_MESSAGES.INSUFFICIENT_MESSAGE_BALANCE)
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(ERROR_MESSAGES.FAILED_TO_SEND_ATTENDANCE_REPORT);
    }
}
