import React, { useEffect, useState } from "react";
import downloadImage from "../../assets/images/newDownload.png";
import {
  getAttendanceData,
  getExpireData,
  getFreezeData,
  getLeadsData,
  getOverduesData,
  getSalesData,
  getTransactionData,
  getTransferData,
  getUpgradeData,
} from "../../features/reports/reportsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadAttendanceReport,
  downloadDueReport,
  downloadExpiryReport,
  downloadFreezeReport,
  downloadLeadReport,
  downloadSalesReport,
  downloadTransactionReport,
  downloadTransferReport,
  downloadUpgradeReport,
} from "../../features/reports/downloadReportSlice";
import ReportTable from "./ReportTable";
import { REPORT_TABS } from "../../utils/constants/keywords";

const ReportTabs = ({ tabs, dateRange }) => {
  const [activeTab, setActiveTab] = useState(tabs.find((tab) => tab.current));
  const dispatch = useDispatch();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const {
    sales,
    expire,
    overdues,
    transactions,
    leads,
    attendance,
    freeze,
    upgrade,
    transfer,
    isDataFound,
    totalPages,
  } = useSelector((store) => store.reports);

  const { user } = useSelector((store) => store.user);
  const adminId = user.userId;
  const {
    isSalesDataFound,
    isExpireDataFound,
    isOverduesDataFound,
    isTransactionsDataFound,
    isLeadsDataFound,
    isAttendanceDataFound,
    isFreezeDataFound,
    isUpgradeDataFound,
    isTransferDataFound, } = isDataFound

  const fetchReportsData = async () => {
    await dispatch(
      getSalesData({ adminId, fromDate: dateRange[0], toDate: dateRange[1] })
    );
    await dispatch(
      getExpireData({ adminId, fromDate: dateRange[0], toDate: dateRange[1] })
    );
    await dispatch(
      getOverduesData({ adminId, fromDate: dateRange[0], toDate: dateRange[1] })
    );
    await dispatch(
      getTransactionData({
        adminId,
        fromDate: dateRange[0],
        toDate: dateRange[1],
      })
    );
    await dispatch(
      getLeadsData({ adminId, fromDate: dateRange[0], toDate: dateRange[1] })
    );
    await dispatch(
      getAttendanceData({
        adminId,
        fromDate: dateRange[0],
        toDate: dateRange[1],
      })
    );
    await dispatch(
      getFreezeData({ adminId, fromDate: dateRange[0], toDate: dateRange[1] })
    );
    await dispatch(
      getUpgradeData({ adminId, fromDate: dateRange[0], toDate: dateRange[1] })
    );
    await dispatch(
      getTransferData({ adminId, fromDate: dateRange[0], toDate: dateRange[1] })
    );
  };

  const reportActions = {
    Sales: downloadSalesReport,
    Expiry: downloadExpiryReport,
    Dues: downloadDueReport,
    Transactions: downloadTransactionReport,
    Leads: downloadLeadReport,
    Attendance: downloadAttendanceReport,
    Freeze: downloadFreezeReport,
    Upgrade: downloadUpgradeReport,
    Transfer: downloadTransferReport,
  };

  const handleDownload = () => {
    const actionCreator = reportActions[activeTab.name];
    if (actionCreator) {
      dispatch(
        actionCreator({
          adminId,
          fromDate: dateRange[0],
          toDate: dateRange[1],
        })
      );
    }
  };

  useEffect(() => {
    fetchReportsData();
  }, [dateRange[0], dateRange[1]]);

  const handleOnClickTab = (tab) => {
    setActiveTab(tab);
  };

  const isDownloadDisabled = () => {
    const { SALES, EXPIRY, DUES, TRANSACTIONS, LEADS, ATTENDANCE, FREEZE, UPGRADE, TRANSFER } = REPORT_TABS
    switch (activeTab.name) {
      case SALES:
        return !isSalesDataFound;
      case EXPIRY:
        return !isExpireDataFound;
      case DUES:
        return !isOverduesDataFound;
      case TRANSACTIONS:
        return !isTransactionsDataFound;
      case LEADS:
        return !isLeadsDataFound;
      case ATTENDANCE:
        return !isAttendanceDataFound;
      case FREEZE:
        return !isFreezeDataFound;
      case UPGRADE:
        return !isUpgradeDataFound;
      case TRANSFER:
        return !isTransferDataFound;
      default:
        return true;
    }
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 z-10 border-gray-200">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            // defaultValue={tabs.find((tab) => tab.current).name}
            value={activeTab?.name}
            onChange={(e) => {
              const selectedTab = tabs.find(
                (tab) => tab.name === e.target.value
              )
              handleOnClickTab(selectedTab)
            }}

          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="border-gray-200 rounded bg-gray-200 z-10 flex lg:items-center sm:flex-row justify-between overflow-x-auto">
          <div className="hidden sm:block flex-grow">
            <nav className="flex space-x-4 lg:space-x-10" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={() => handleOnClickTab(tab)}
                  className={classNames(
                    tab.name === activeTab.name
                      ? "bg-indigo-100 text-indigo-700"
                      : "text-gray-500 hover:text-gray-700",
                    "rounded-md px-3 py-2 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
          <div className="mt-3 flex-shrink-0 flex md:right-0 md:top-3 md:mt-0">
            <button
              onClick={handleDownload}
              type="button"
              className={`inline-flex items-center rounded-md px-3 py-2 text-lg font-bold text-gray-1000 shadow-sm ring-1 ring-inset ring-gray-300 ${isDownloadDisabled() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
              disabled={isDownloadDisabled()}
            >
              <img className="w-6 h-6 object-contain" src={downloadImage} />
            </button>
          </div>
        </div>
      </div>
      <ReportTable
        activeTabVal={activeTab}
        expireReport={expire?.data}
        salesReport={sales}
        dueData={overdues}
        transactionData={transactions}
        leadsData={leads}
        attendanceData={attendance}
        freezeData={freeze}
        upgradeData={upgrade}
        transferData={transfer}
      />
    </>
  );
};

export default ReportTabs;
