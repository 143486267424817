import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;

const DateRangePicker = ({ dateRange, setDateRange, setSelectedButton }) => {
  const dateFormat = 'YYYY-MM-DD';

  const [startDate, setStartDate] = useState(dayjs(dateRange[0]));
  const [endDate, setEndDate] = useState(dayjs(dateRange[1]));

  useEffect(() => {
    setStartDate(dayjs(dateRange[0]));
    setEndDate(dayjs(dateRange[1]));
  }, [dateRange]);

  const formatDate = (date, format) => date.format(format);

  const handleDateChange = (date, isStartDate) => {
    if (date) {
      const newStartDate = isStartDate ? date : startDate;
      const newEndDate = isStartDate ? endDate : date;

      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setDateRange([formatDate(newStartDate, dateFormat), formatDate(newEndDate, dateFormat)]);
      if (setSelectedButton) {
        setSelectedButton("");
      }
    }
  };

  const onStartRangeChange = (date) => handleDateChange(date, true);
  const onEndRangeChange = (date) => handleDateChange(date, false);

  const disabledStartDate = (current) => {
    if (dateRange[1]) {
      return current && current > dayjs(dateRange[1], dateFormat);
   }
   return false;
  };
  const disabledEndDate = (current) => {
    if (dateRange[0]) {
      return current && current < dayjs(dateRange[0], dateFormat);
   }
   return false;
  };
 
  return (
    <Space direction="horizontal" size={12} >
       {/* <RangePicker  value={[dayjs(dateRange[0], dateFormat), dayjs(dateRange[1], dateFormat)]}  defaultValue={[dayjs(dateRange[0], dateFormat), dayjs(dateRange[1], dateFormat)]} format={"DD-MM-YYYY"} presets={rangePresets} size='large' onChange={onRangeChange}  />  */}
      <DatePicker disabledDate={disabledStartDate}   placement='bottomLeft' style={{ width: '100%' }}   size='large' value={dayjs(dateRange[0], dateFormat)} defaultValue={dayjs(dateRange[0], dateFormat)} format={"DD-MM-YYYY"} onChange={onStartRangeChange}/>
      <div >-</div>
      <DatePicker disabledDate={disabledEndDate}  placement='bottomRight' style={{ width: '100%' }}   size='large' value={dayjs(dateRange[1], dateFormat)} defaultValue={dayjs(dateRange[1], dateFormat)} format={"DD-MM-YYYY"} onChange={onEndRangeChange}/>
    </Space>
  )
};
export default DateRangePicker;