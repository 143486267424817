// import customFetch from '../../utils/axios/axios'
import customFetch from "../../utils/axios/axiosAdmin";

import {
    ERROR_MESSAGES,
    SUCCESS_MESSAGES,
} from "../../utils/constants/Prompts";
import { getMemberOrderDetails } from "./memberOrderDetailsSlice";

export const postDueBillingDetailsThunk = async (
    billingUpdateUrl,
    billingData,
    duePostUrl,
    dueData,
    dueUpdateUrl,
    dueUpdateData,
    memberId,
    adminId,
    thunkAPI
) => {
    try {
        const response1 = await customFetch.put(billingUpdateUrl, billingData);
        if (response1.status === 200 || response1.status === 201) {
            const response2 = await customFetch.post(
                duePostUrl,
                dueData
            );
            if (response2.status === 200 || response2.status === 201) {
                const response3 = await customFetch.put(
                    dueUpdateUrl,
                    dueUpdateData
                );
                if (response3.status === 200 || response3.status === 201) {
                    await thunkAPI.dispatch(getMemberOrderDetails({ adminId, memberId }))
                    return { billData: response1.data, message: SUCCESS_MESSAGES.DUE_PAYMENT };
                }
            }
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_PAYING_DUE_AMOUNT);
    }
};