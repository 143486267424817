import customFetch from "../../utils/axios/axiosAdmin"
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../utils/constants/Prompts"

export const postExistingMemberTransferThunk = async (billingPostUrl, billingPayload, membershipPostUrl, membershipData,updatePurchasedMembershipUrl,updatedMembershipData, thunkAPI) => {
    try {
      const response1 = await customFetch.post(billingPostUrl, billingPayload)
      if (response1.status === 200 || response1.status === 201) {
          const billingData = response1.data
          membershipData[0].billingId = billingData?.id
          try {
              const response2 = await customFetch.post(membershipPostUrl, membershipData)
              if (response2.status === 200 || response2.status === 201) {
                  try {
                      const response3 = await customFetch.put(updatePurchasedMembershipUrl, updatedMembershipData)
                      if (response3.status === 200 || response3.status === 201) {
                        //   await thunkAPI.dispatch(getMemberOrderDetails({ adminId: billingData?.adminId, memberId: billingData?.memberId }))
                          return { billData: billingData, message: SUCCESS_MESSAGES.TRANSFER_MEMBERSHIP};
                      }
                  } catch (error) {
                      return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_UPDATING_PURCHASED_MEMBERSHIP)
                  }
              }
          } catch (error) {
              return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_POSTING_TRANSFER_MEMBERSHIP)
          }
  
      }
  } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_POSTING_BILLING_DATA)
  }
  } 

  export const postNewMemberTransferThunk = async (billingPostUrl, billingPayload, membershipPostUrl, membershipData,updatePurchasedMembershipUrl,updatedMembershipData, thunkAPI) => {
    try {
      const response1 = await customFetch.post(billingPostUrl, billingPayload)
      if (response1.status === 200 || response1.status === 201) {
          const billingData = response1.data.billings[0]
          membershipData[0].billingId = billingData?.id
          try {
              const response2 = await customFetch.post(membershipPostUrl, membershipData)
              if (response2.status === 200 || response2.status === 201) {
                  try {
                      const response3 = await customFetch.put(updatePurchasedMembershipUrl, updatedMembershipData)
                      if (response3.status === 200 || response3.status === 201) {
                        // await thunkAPI.dispatch(getMemberOrderDetails({ adminId: billingData?.adminId, memberId: billingData?.memberId }))
                          return { billData: billingData, message: SUCCESS_MESSAGES.TRANSFER_MEMBERSHIP};
                      }
                  } catch (error) {
                      return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_UPDATING_PURCHASED_MEMBERSHIP)
                  }
              }
          } catch (error) {
              return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_POSTING_TRANSFER_MEMBERSHIP)
          }
  
      }
  } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGES.ERROR_POSTING_BILLING_DATA)
  }
  } 
  export const checkExistingMembershipForTransferThunk = async (url, thunkAPI) => {
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  };