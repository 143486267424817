import React from "react";
import { useDispatch } from "react-redux";
import * as yup from 'yup';
import SharedLeadModal from './SharedLeadModal';
import { handleContactLeadModal, updateLeadDetails } from "../../features/profile/leadProfileSlice";
import { USER_MANAGEMENT_LABELS } from "../../utils/constants/keywords";
import { formatDateToISO } from "../../utils/formatter/dateFormatter";

const SCHEMA_VALIDATION_ERRORS = {
    DATE_VALIDATION: 'Follow up date is required',
};

const ContactLeadModal = ({ isModalOpen, title, leadId }) => {
    const dispatch = useDispatch();

    const initialValues = {
        followUpDate: '',
        shortNotes: '',
        followUpStatus: USER_MANAGEMENT_LABELS.CONTACT_LEAD_STATUS,
    };

    const validationSchema = yup.object().shape({
        followUpDate: yup
            .string()
            .required(SCHEMA_VALIDATION_ERRORS.DATE_VALIDATION)
            .typeError(SCHEMA_VALIDATION_ERRORS.DATE_VALIDATION),
        shortNotes: yup.string().nullable(),
    });

    const onSubmit = (data) => {
        dispatch(updateLeadDetails({ leadId, updateDetails: data }));
        dispatch(handleContactLeadModal(false));
    };

    const formFields = [
        {
            id: "followUpDate",
            label: "Next Follow Up Date",
            name: "followUpDate",
            type: "date",
            required: true,
            additionalProps: { min: formatDateToISO(new Date()) }
        },
        {
            id: "shortNotes",
            label: "Comments",
            name: "shortNotes",
            type: "text",
            required: false
        }
    ];

    return (
        <SharedLeadModal
            isModalOpen={isModalOpen}
            title={title}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            handleModalClose={() => dispatch(handleContactLeadModal(false))}
            formFields={formFields}
        />
    );
};

export default ContactLeadModal;
