import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAttendanceData,
  getDashboardDuesData,
  getDashboardLeadsData,
  getDashboardRenewalData,
  getDashboardSalesData,
  setFollowupDate,
} from "../features/dashboard/dashboardSlice";
import DateRangePicker from "../components/Generic/DateRangePicker/DateRangePicker";
import DashboardDurationButton from "../components/Dashboard/DashboardDurationButton.jsx";
import DashboardCreateButton from "../components/Dashboard/DashboardCreateButton.jsx";
import DashboardStatsContainer1 from "../components/Dashboard/DashboardStatsContainer.jsx";
import DashboardStatsChartContainer from "../components/Dashboard/DashboardStatsChartContainer.jsx";
import DashboardFollowupContainer from "../components/Dashboard/DashboardFollowupContainer.jsx";
import DashboardPaymentTypeChartContainer from "../components/Dashboard/DashboardPaymentTypeChartContainer.jsx";
import DashboardRecentActivity from "../components/Dashboard/DashboardRecentActivity.jsx";
import { DURATION_FILTER } from "../utils/constants/keywords.js";

const SCROLL_RESTORATION = {
  MANUAL: 'manual',
  AUTO: 'auto'
};

const HISTORY_SCROLL_RESTORATION = 'scrollRestoration';

const INITIAL_SCROLL = {
  X: 0,
  Y: 0
};

const Dashboard = () => {
  const {
    salesCollectionData,
    dues,
    prevSales,
    prevDues,
    prevCollections,
    prevRenewals,
    leads,
    attendance,
    renewals,
    recentMembersJoined,
    recentLeadsJoined,
    recentPaidAmount,
    duesFollowup,
    leadsFollowup,
    renewalFollowup,
  } = useSelector((store) => store.dashboard);
  const { paymentTypeDataByMonths, paymentMethodDataByMonths } = useSelector(
    (store) => store.dashboardChart
  );
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const adminId = user.userId;
  const date = new Date();
  const defaultDate = new Date();

  useEffect(() => {
    dispatch(setFollowupDate(dateFormatApi(date)));
  }, []);

  const [selectedButton, setSelectedButton] = useState(DURATION_FILTER.TODAY);
  const compareDefaultStartDate = new Date(defaultDate);
  const compareDefaultEndDate = new Date(defaultDate);
  compareDefaultStartDate.setDate(defaultDate.getDate() - 30);
  compareDefaultEndDate.setDate(defaultDate.getDate() - 1);

  const dateFormat = (value) => {
    const data = new Date(value);
    const date = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = data.getFullYear();
    return `${year}/${month}/${date}`;
  };
  const dateFormatApi = (value) => {
    const data = new Date(value);
    const date = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = data.getFullYear();
    return `${year}-${month}-${date}`;
  };

  const [dateRange, setDateRange] = useState([
    `${dateFormatApi(defaultDate)}`,
    `${dateFormatApi(date)}`,
  ]);
  const [compareDateRange, setcompareDateRange] = useState([
    `${dateFormatApi(compareDefaultStartDate)}`,
    `${dateFormatApi(compareDefaultEndDate)}`,
  ]);

  useEffect(() => {
    let date1 = new Date(dateRange[0]);
    let date2 = new Date(dateRange[1]);
    let date3 = new Date(dateRange[0]);
    let date4 = new Date(dateRange[0]);
    let timeDifference =
      (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24);
    setcompareDateRange([
      `${dateFormatApi(date3.setDate(date1.getDate() - timeDifference - 1))}`,
      `${dateFormatApi(date4.setDate(date1.getDate() - 1))}`,
    ]);
  }, [dateRange]);

  // useEffect(() => {
  //   const updateCompareDateApi = () => {
  //     setDataApi((prevDataApi) => ({
  //       ...prevDataApi,
  //       prevSales: `${user.userId}/startDate/${compareDateRange[0]}/endDate/${compareDateRange[1]}`,
  //       prevCollections: `${user.userId}/startDate/${compareDateRange[0]}/endDate/${compareDateRange[1]}`,
  //       prevRenewals: `${user.userId}/startDate/${compareDateRange[0]}/endDate/${compareDateRange[1]}`,
  //       prevDues: `${user.userId}/startDate/${compareDateRange[0]}/endDate/${compareDateRange[1]}`,
  //     }));
  //   };
  //   updateCompareDateApi();
  // }, [compareDateRange]);

  useEffect(() => {
    window.scrollTo(INITIAL_SCROLL.X, INITIAL_SCROLL.Y);

    if (HISTORY_SCROLL_RESTORATION in window.history) {
      window.history.scrollRestoration = SCROLL_RESTORATION.MANUAL;
    }

    return () => {
      if (HISTORY_SCROLL_RESTORATION in window.history) {
        window.history.scrollRestoration = SCROLL_RESTORATION.AUTO;
      }
    };
  }, []);

  useEffect(() => {
    dispatch(
      getDashboardSalesData({
        adminId: adminId,
        fromDate: dateRange[0],
        toDate: dateRange[1],
      })
    );
    dispatch(
      getDashboardDuesData({
        adminId: adminId,
        fromDate: dateRange[0],
        toDate: dateRange[1],
      })
    );
    dispatch(
      getDashboardLeadsData({
        adminId: adminId,
        fromDate: dateRange[0],
        toDate: dateRange[1],
      })
    );
    dispatch(
      getDashboardRenewalData({
        adminId: adminId,
        fromDate: dateRange[0],
        toDate: dateRange[1],
      })
    );
    dispatch(
      getDashboardAttendanceData({
        adminId: adminId,
        attendanceDate: dateFormatApi(date),
        fromDate: dateRange[0],
        toDate: dateRange[1],
      })
    );
  }, [dateRange]);

  const week = new Date();
  const month = new Date();
  week.setDate(week.getDate() - 6);
  month.setDate(month.getDate() - 30);

  const setToday = () => {
    setDateRange([dateFormatApi(date), dateFormatApi(date)]);
    setSelectedButton(DURATION_FILTER.TODAY);
  };
  const setWeek = () => {
    setDateRange([dateFormatApi(week), dateFormatApi(date)]);
    setSelectedButton(DURATION_FILTER.WEEK);
  };
  const setMonth = () => {
    setDateRange([dateFormatApi(month), dateFormatApi(date)]);
    setSelectedButton(DURATION_FILTER.MONTH);
  };
  return (
    <div>
      <div className="flex flex-col gap-3 md:gap-0 md:flex-row justify-between mx-4">
        <div className="text-2xl font-semibold mr-6">Dashboard</div>
        <div className="flex flex-col  sm:ml-0 gap-3 sm:flex-row sm:justify-end sm:gap-6 ">
          <DashboardDurationButton
            setToday={setToday}
            setMonth={setMonth}
            setWeek={setWeek}
            selectedButton={selectedButton}
          />
          <div className="">
            <DateRangePicker
              dateRange={[dateFormat(dateRange[0]), dateFormat(dateRange[1])]}
              setDateRange={setDateRange}
              setSelectedButton={setSelectedButton}
            />
          </div>
          <DashboardCreateButton />
        </div>
      </div>
      <div className="flex flex-col md:flex-row lg:flex-row">
        <div className="md:w-[60%] lg:w-[76%]">
          <DashboardStatsContainer1
            sales={salesCollectionData}
            renewals={renewals}
            dues={dues}
            prevSales={prevSales}
            prevCollections={prevCollections}
            prevRenewals={prevRenewals}
            prevDues={prevDues}
            leads={leads}
            attendance={attendance}
          />
          <DashboardStatsChartContainer
            sales={salesCollectionData}
            dues={dues}
            dateRange={dateRange}
            paymentMethodDataByMonths={paymentMethodDataByMonths}
          />
        </div>
        <div className="lg:w-[24%] md:w-[40%] shadow rounded-lg p-5 mx-5 md:mt-6 lg:mr-6 md:ml-0">
          <DashboardFollowupContainer
            renewalFollowup={renewalFollowup}
            leadsFollowup={leadsFollowup}
            duesFollowup={duesFollowup}
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="w-[100%]  lg:w-[37%] ">
          <DashboardRecentActivity
            recentLeadsJoined={recentLeadsJoined}
            recentMembersJoined={recentMembersJoined}
            recentPaidAmount={recentPaidAmount}
          />
        </div>
        <div className="w-[100%] lg:w-[63%]">
          <DashboardPaymentTypeChartContainer
            dateRange={dateRange}
            paymentTypeDataByMonths={paymentTypeDataByMonths}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
