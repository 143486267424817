import { Subscript } from "lucide-react";

export function getFormattedDate(date) {
  const dateObject = new Date(date);

  if (isNaN(dateObject.getTime())) {
    return "Invalid Date";
  }

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const newDateFormat = `${formattedDay}-${formattedMonth}-${year}`;
  return newDateFormat;
}

export function formatDateToISO(date) {
  return date.toJSON().split("T")[0];
}

export function subscriptionEndDate(months) {
  const today = new Date();
  const addMonths = new Date(today);

  // Add months to the month
  addMonths.setMonth(today.getMonth() + Number(months));

  return formatDateToISO(addMonths);
}

export function subscriptionEndDateForTheDate(givenDate, months) {
  const addMonths = new Date(givenDate);

  // Add months to the month
  addMonths.setMonth(givenDate.getMonth() + Number(months));

  return formatDateToISO(addMonths);
}

export const dateFormatApi = (value) => {
  const data = new Date(value);
  const date = String(data.getDate()).padStart(2, "0");
  const month = String(data.getMonth() + 1).padStart(2, "0");
  const year = data.getFullYear();
  return `${year}-${month}-${date}`;
};

export const dateFormat = (value) => {
  const data = new Date(value);
  const date = String(data.getDate()).padStart(2, "0");
  const month = String(data.getMonth() + 1).padStart(2, "0");
  const year = data.getFullYear();
  return `${year}/${month}/${date}`;
};
